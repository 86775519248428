import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../../../../../../utilities/transitions';
import {
    MDBBtn,
    MDBContainer
} from 'mdb-react-ui-kit';
import ReleaseNotesModal from './ReleaseNotesModal';
import DisableModal from './DisableModal';
import RestoreModal from './RestoreModal';
import { set_server_status } from '../../../../../../redux/actions';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import Spinner from '../../../../../../components/Spinner';

class Actions extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * releaseNotesModalShown: Boolean - Whether the Release Notes modal is shown
             * restoreModalShown: Boolean - Whether the Restore modal is shown
             * disableModalShown: Boolean - Whether the Disable modal is shown
             * working: Boolean - Whether the instance is in the process of being updated
             */
            releaseNotesModalShown: false,
            restoreModalShown: false,
            disableModalShown: false,
            working: false
        }
    }

    /**
     * Notify the user when an update is queued
     */
    componentDidUpdate(prevProps){
        if (prevProps.serverStatus.status !== 'update-queued' && this.props.serverStatus.status === 'update-queued') this.props.notify(<i className="fas fa-check-circle text-success me-2" />, "Update Queued");
    }

    toggleRestoreModal = e => this.setState({
        ...this.state,
        restoreModalShown: !this.state.restoreModalShown
    });

    setRestoreModal = option => this.setState({
        ...this.state,
        restoreModalShown: option
    });

    toggleDisableModal = () => this.setState({
        ...this.state,
        disableModalShown: !this.state.disableModalShown
    });

    setDisableModal = option => this.setState({
        ...this.state,
        disableModalShown: option
    });

    toggleReleaseNotesModal = () => this.setState({
        ...this.state,
        releaseNotesModalShown: !this.state.releaseNotesModalShown
    });

    setReleaseNotesModal = option => this.setState({
        ...this.state,
        releaseNotesModalShown: option
    });

    /**
     * Triggered when the user clicks Update
     * 
     * Makes request to server
     * Updates the server status in application state
     */
    update = () => {
        if (!this.state.working) this.setState({
            ...this.state,
            working: true
        }, () => {
            if (this.props.serverStatus.status !== 'update-queued') axios.get('/jizzer/update-beta').then(res => this.props.set_server_status(res.data)).catch(err => {
                console.log('error', err);
                alert('An error occurred. Please try again later.');
            }).finally(() => this.setState({
                ...this.state,
                working: false
            }));
        });
    }

    render(){
        return (
            <motion.div transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out}>
                <MDBContainer>
                    {this.props.serverStatus.status === 'update-queued' ?
                    <motion.div transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out}>
                    
                        <h5 className="text-center display-6 mb-4">Your instance is updating</h5>
                        <LinearProgress />
                    </motion.div> : 
                    <>
                        <DisableModal
                            modalShown={this.state.disableModalShown}
                            toggleShowModal={this.toggleDisableModal}
                            setShowModal={this.setDisableModal}
                        />
                        <RestoreModal
                            modalShown={this.state.restoreModalShown}
                            toggleShowModal={this.toggleRestoreModal}
                            setShowModal={this.setRestoreModal}
                        />
                        {this.props.releaseNotes.find(r => new Date(r.timestamp) > new Date(this.props.serverStatus.latest)) ?
                        <>
                            <ReleaseNotesModal
                                modalShown={this.state.releaseNotesModalShown}
                                toggleShowModal={this.toggleReleaseNotesModal}
                                setShowModal={this.setReleaseNotesModal}
                            />
                            
                            <h5 className="text-center display-6 mb-4 d-flex align-items-start mx-auto justify-content-center">
                                An Update is Available
                                <MDBBtn 
                                    size="sm"
                                    color="link"
                                    rippleColor="primary"
                                    onClick={this.toggleReleaseNotesModal}
                                >
                                    <i className="fas fa-question-circle" />
                                </MDBBtn>
                            </h5>
                            {this.state.working ?
                            <MDBBtn 
                                color="primary"
                                size="lg"
                                className="d-block mx-auto"
                                disabled
                            >
                                <Spinner size="sm" className="me-2" />
                                Requesting Update
                            </MDBBtn> :
                            <MDBBtn 
                                color="primary"
                                size="lg"
                                className="d-block mx-auto"
                                onClick={this.update}
                            >
                                <i className="fas fa-arrow-alt-circle-up me-2" />
                                Update Now
                            </MDBBtn>}
                            <hr />
                        </> : 
                        <>
                            <h5 className="text-center display-6 mb-4">Your instance is up-to-date</h5>
                            <hr />
                        </>}
                        {this.props.serverStatus.disabled ?
                        <>
                            <h5 className="text-center display-6 mb-4">Your instance is Disabled</h5>
                            <MDBBtn 
                                color="success"
                                size="lg"
                                className="d-block mx-auto"
                                onClick={this.toggleRestoreModal}
                            >
                                <i className="fas fa-check-circle me-2" />
                                Restore Instance
                            </MDBBtn>
                        </> :
                        <>
                            <h5 className="text-center display-6 mb-4">Your instance is Live</h5>
                            <MDBBtn 
                                color="danger"
                                size="lg"
                                className="d-block mx-auto"
                                onClick={this.toggleDisableModal}
                            >
                                <i className="fas fa-times-circle me-2" />
                                Disable Instance
                            </MDBBtn>
                        </>}
                    </>}
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { set_server_status })(Actions);