import React from 'react';
import {
    MDBPopover,
    MDBPopoverBody,
    MDBTooltip
} from 'mdb-react-ui-kit';
import EmojiPicker from '../../components/EmojiPicker';
import LinkInsertForm from './LinkInsertForm';

const maxChars = Number(process.env.REACT_APP_MAX_EMISSION_CHARS);
let Quill;
let spaces = 0;

class BioField extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * input: String - HTML of bio
             * cursorLocation: Number - Index of cursor in bio input
             * editor: false | Quill Object - The Quill editor
             * working: Boolean - Whether a new account is being sent to or processed by the server
             * popoverOpen: Boolean - Whether the Link Insert popover is open
             * files: Array - List of files that the user has selected
             * processingFiles: Boolean - Whether files are being processed and md5s generated
             * mediaPlaying: String - id of audio/video file that is currently being played, if any
             * reset: Boolean - When flipped, Quill editor will clear
             */
            input: '',
            cursorLocation: 0,
            editor: '',
            working: false,
            popoverOpen: false,
            files: [],
            processingFiles: false,
            mediaPlaying: '',
            reset: false
        }
        /**
         * this.insertSpace - When user presses the space button after inserting a link, inserts a space (fixes bug with hashtag/mention parser)
         * this.forceParse - Allows the parent to call the forceParse function
         */
        this.insertSpace = false;
        this.forceParse = this.forceParse.bind(this);
    }

    /**
     * props.setForceParse - Allows the parent to call this.forceParse
     * Initialize Quill editor
     */
    componentDidMount(){
        this.props.setForceParse(this.forceParse);
        Quill = window.Quill;
        this.setEditor();
    }
    
    /**
     * Removes all event listeners
     * Initializes new Quill editor
     * Adds event listeners for keypress and selection change events
     */
    setEditor = () => {
        if (this.state.editor){
            this.state.editor.off('selection-change');
            this.state.editor.off('text-change');
            const keyNode = document.getElementById('input-bio').childNodes[0];
            if (keyNode){
                keyNode.removeEventListener('keydown', this.keyDown);
                keyNode.removeEventListener('keyup', this.keyUp);
                keyNode.removeEventListener('keypress', this.keyPress);
            } 
            spaces = 0;
        }
        this.setState({
            ...this.state,
            editor: new Quill('#input-bio', {
                modules: {
                    autoformat: {
                        hashtag: {
                          trigger: /[\s.,;:!?\\]/,
                          find: /(?:^|\s)#[^\s.,;:!?]+/i,
                          extract: /#([^\s.,;:!?]+)/i,
                          transform: '$1',
                          insert: 'hashtag'
                        },
                        mention: {
                          trigger: /[\s.,;:!?\\]/,
                          find: /(?:^|\s)@[^\s.,;:!?]+/i,
                          extract: /@([^\s.,;:!?]+)/i,
                          transform: '$1',
                          insert: 'mention'
                        },
                        link: {
                          trigger: /[\s\\]/,
                          find: /https?:\/\/[\S]+|(www\.[\S]+)/gi,
                          transform: function (value, noProtocol) { 
                            return noProtocol ? "https://" + value : value;
                          },
                          format: 'link'
                        }
                    }
                }
              })
        }, () => {
            this.state.editor.on('selection-change', this.selectionChange);
            this.state.editor.on('text-change', this.textChange);
            Array.from(document.getElementById('input-bio').childNodes).forEach(node => {
                if (node.getAttribute('contenteditable')){
                    node.addEventListener('keydown', this.keyDown);
                    node.addEventListener('keyup', this.keyUp);
                    node.addEventListener('keypress', this.keyPress);
                }
            });
            this.blur();
        });
    }

    // Closes the link insert popover and removes the mousedown event listener
    closePopover = e => {
        if (!(e && e.target && e.target.classList.contains('page-popover-triggers'))) this.setState({
            ...this.state,
            popoverOpen: false
        }, () => document.body.removeEventListener('mousedown', this.closePopover));
    } 

    // Opens the link insert popover and adds an event listener which closes the form if the user clicks outside it
    showPopover = () => this.setState({
        ...this.state,
        popoverOpen: true
    }, () => setTimeout(() => document.body.addEventListener('mousedown', this.closePopover), 500));

    /**
     * 
     * @param {Event} e - Javascript keypress event
     * 
     * Updates the number of spaces in the editor.
     */
    keyDown = e => {
        spaces = e.target.textContent.split(' ').length;
    }

    /**
     * 
     * @param {Event} e - Javascript keypress event
     * 
     * Detects when the user presses the space bar
     * If spacebar is pressed, preventDefault and manually insert a space into the editor
     * Prevents bug that occurs with mention/link/hashtag parser that leaves the cursor stuck at the end of links
     * 
     * Does not work on android as android does not pass keyCodes
     * 
     */
    keyPress = e => {
        if (e.key === ' ' && !(/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor || window.opera) && !window.MSStream)){
            e.preventDefault();
            spaces++;
            this.state.editor.insertText(this.state.editor.getSelection() ? this.state.editor.getSelection().index : this.state.cursorLocation, ' ', 'user');
            this.insertSpace = true;
            setTimeout(() => this.state.editor.setSelection((this.state.editor.getSelection() ? this.state.editor.getSelection().index : this.state.cursorLocation) + 1), 0);
        }
    }

    /**
     * 
     * @param {Event} e - Javascript keypress event
     * 
     * Workaround for the above keyPress function as android does not pass keyCodes
     */
    keyUp = e => {
        if (e.target.textContent.split(' ').length > spaces && /android/i.test(navigator.userAgent || navigator.vendor || window.opera)){
            e.preventDefault();
            const location = (this.state.editor.getSelection() ? this.state.editor.getSelection().index : this.state.cursorLocation);
            this.insertSpace = true;
            setTimeout(() => this.state.editor.setSelection(location), 0);
        }
    }

    /**
     * Triggered whenever the text in the editor changes after parsers have run
     * Adds text color classes to parsed mentions and hashtags
     */
    textChange = () => {
        this.setState({
            ...this.state,
            input: document.getElementById('input-bio').textContent
        }, () => {
            Array.from(document.getElementsByClassName('ql-mention')).forEach(mention => {
                if (!mention.classList.contains('text-success')) mention.classList.add('text-success');
            });
            Array.from(document.getElementsByClassName('ql-hashtag')).forEach(hashtag => {
                if (!hashtag.classList.contains('text-secondary')) hashtag.classList.add('text-secondary'); 
                if (Array.from(hashtag.childNodes).find(node => node.tagName === 'SPAN')) hashtag.setAttribute('href', `/tag/${Array.from(hashtag.childNodes).find(node => node.tagName === 'SPAN').textContent.split('#')[1]}`);
            });
        });
    }

    /**
     * 
     * @param {Object} range - Quill range object
     * 
     * Set cursor location into state
     */
    selectionChange = (range) => {
        if (range) this.setState({
            ...this.state,
            cursorLocation: range.index
        });   
    }

    /**
     * Triggered when the text input is put into focus
     * Adds appropriate styles and classes
     */
    focus = () => {
        document.getElementById('label-bio').style.top = '-0.5rem';
        document.getElementById('label-bio').classList.add('text-primary');
        document.getElementById('label-bio').style.fontSize = '0.75rem';
        document.getElementById('label-bio').style.padding = '0px 5px';
        document.getElementById('input-wrapper-bio').style.border = '1px solid rgba(18, 102, 241, 1)';
        document.getElementById('input-bio').classList.add('emission-focus');
    }

    /**
     * Triggered when the text input is put into focus
     * Removes styles and classes which were added on focus
     */
    blur = () => {
        document.getElementById('label-bio').classList.remove('text-primary');
        document.getElementById('input-wrapper-bio').style.border = '1px solid rgba(18, 102, 241, 0)';
        document.getElementById('input-bio').classList.remove('emission-focus');
        if (!document.getElementById('input-bio').textContent && !this.state.linkShown && !this.state.cursorLocation){
            document.getElementById('label-bio').style.top = '1rem';
            document.getElementById('label-bio').style.fontSize = 'var(--mdb-body-font-size)';
            document.getElementById('label-bio').style.padding = '0px';
        }
    }

    /**
     * 
     * @param {Object} e - Emoji picker object
     * 
     * Inserts emoji into editor at current cursor location
     */
    selectEmoji = e => {
        if (!this.props.working){
            this.state.editor.focus();
            this.state.editor.insertText(this.state.editor.getSelection() ? this.state.editor.getSelection().index : this.state.cursorLocation, e.char);
        }
    }
    
    /**
     * 
     * @returns Character count of text only in the input
     */
    getCharCount = () => {
        if (typeof document === 'undefined') return 0;
        const input = document.getElementById('input-bio');
        if (!input) return 0;
        const length = String(input.textContent).split('').filter(c => {
            const checkWhiteSpace = c.match(/[\s]/);
            if (!checkWhiteSpace) return true;
            else {
                return [' ', '\n'].indexOf(c) > -1;
            }
        }).length;
        return (length > maxChars) ? <span className="text-danger">{length}</span> : length;
    }    

    /**
     * 
     * @param {String} link - URL of link
     * @param {String} text - Text of anchor tag
     * 
     * Close the link insert popover
     * Insert the link
     */
    insertLink = (link, text) => {
        this.closePopover();
        setTimeout(() => {
            this.state.editor.focus();
            this.state.editor.insertText(this.state.editor.getSelection() ? this.state.editor.getSelection().index : this.state.cursorLocation, text, 'link', link);
        }, 100);
    }
    
    // Hides all tooltips
    hideToolTips = () => Array.from(document.getElementsByClassName('tooltip')).forEach(e => e.classList.remove('show'));

    /**
     * Triggered when the form is submitted
     * Inserts then immediately removes a back slash
     * Forces the parsers to run
     */
    forceParse = () => {
        try {
            this.state.editor.insertText(this.state.editor.getSelection() ? this.state.editor.getSelection().index : this.state.cursorLocation, '\\', 'user');
            document.getElementById('input-bio').innerHTML = document.getElementById('input-bio').innerHTML.split('\\').join('');
        } catch(err){
            console.log('force parse error', err)
        }
    }

    render(){
        return (
            <div className="position-relative">
                <label 
                    className="position-absolute quill-labels" 
                    for="input-bio" 
                    id="label-bio"
                    style={{
                        transition: '0.33s',
                        top: '1rem',
                        left: '1rem',
                        cursor: 'text'
                    }}
                    onClick={() => this.state.editor.focus()}
                >Bio</label>
                <div 
                    style={{
                        transition: '0.33s', 
                        border: '1px solid rgba(18, 102, 241, 0)'
                    }} 
                    className="rounded" 
                    id="input-wrapper-bio"
                >
                    <div 
                        id="input-bio"
                        className="p-2 rounded quill-inputs"
                        style={{
                            minHeight: '4.5rem',
                            transition: 'border-width 0.33s ease-in-out',
                            whiteSpace: 'pre-wrap'
                        }}
                        onFocus={this.focus}
                        onBlur={this.blur}
                        key={"input-bio" + this.state.reset}
                    ></div>
                </div>
                <div className="d-flex justify-content-end align-items-center my-2">
                    <MDBPopover
                        color="link"
                        placement="bottom"
                        btnChildren={
                            <i style={{fontSize: '1.75em'}} className="fas fa-link fa-lg page-popover-triggers"></i>
                        }
                        rippleColor="primary"
                        disabled={this.props.working}
                        onShow={this.showPopover}
                        isOpen={this.state.popoverOpen}
                        className="page-popover-triggers"
                        type="button"
                    >
                        <MDBPopoverBody className="page-popover-triggers">
                            <LinkInsertForm insertLink={this.insertLink} />
                        </MDBPopoverBody>
                    </MDBPopover>
                    <EmojiPicker 
                        emojiID={"bio"} 
                        className="mx-2"
                        trigger={
                            <MDBTooltip className="mb-2" tag="span" wrapperProps={{ className: 'emoji-triggers-bio', disabled: this.props.working }} title='Emoji'>
                                <i style={{fontSize: '1.75em'}} className="fas fa-smile fa-lg"></i>
                            </MDBTooltip>
                        }
                        onEmojiSelect={this.selectEmoji}
                        disabled={this.props.working}
                    />
                </div>
            </div>
        );
    }
}

export default BioField;