import React from 'react';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';
import h from '../../../../../utilities/helpers';
import t from '../../../../../utilities/transitions';
import {
    MDBBtn,
    MDBContainer,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBInput,
    MDBValidation,
    MDBValidationItem,
    MDBInputGroup
} from 'mdb-react-ui-kit';
import RegisterModal from './RegisterModal';
import RecordsModal from './RecordsModal';
import Spinner from '../../../../../components/Spinner';
import axios from 'axios';
import { set_server_status } from '../../../../../redux/actions';

class Start extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * registerModalShown: Boolean - Whether the Register modal is shown
             * recordsModalShown: Boolean - Whether the Records modal is shown
             * loaded: Boolean - Whether the initial data load has completed
             * domain: Object - Details of the Domain input
             * working:  Boolean - Whether the domain has been submitted and is currently being processed
             */
            registerModalShown: false,
            recordsModalShown: false,
            loaded: false,
            domain: {
                error: 'Please enter your domain',
                invalid: true,
                value: ''
            },
            working: false
        }
    }

    componentDidMount(){
        document.getElementById('input-domain').setCustomValidity('Please enter your domain');
    }

    setRegisterModal = option => this.setState({
        ...this.state,
        registerModalShown: option
    });

    toggleRegisterModal = () => this.setState({
        ...this.state,
        registerModalShown: !this.state.registerModalShown
    });

    setRecordsModal = option => this.setState({
        ...this.state,
        recordsModalShown: option
    });

    toggleRecordsModal = () => this.setState({
        ...this.state,
        recordsModalShown: !this.state.recordsModalShown
    });

    /**
     * Sets domain input value into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => this.setState({
        ...this.state,
        domain: {
            ...this.state.domain,
            value: e.target.value
        }
    }, () => {
        if (h.checkURL(e.target.value)) this.setState({
            ...this.state,
            domain: {
                ...this.state.domain,
                invalid: false,
                error: ''
            }
        }, () => document.getElementById('input-domain').setCustomValidity(''));
        else this.setState({
            ...this.state,
            domain: {
                ...this.state.domain,
                invalid: true,
                error: 'Please enter a valid domain'
            }
        }, () => document.getElementById('input-domain').setCustomValidity('Please enter a valid domain'));
    });

    /**
     * Triggered when the user clicks the Submit button
     * 
     * Sets the domain
     */
    submit = () => {
        document.getElementById('form_domain').classList.add('was-validated');
        if (!this.state.working && !this.state.domain.invalid) this.setState({
            ...this.state,
            working: true
        }, () => axios.post('/jizzer/beta-domain', {
            domain: this.state.domain.value
        }).then(res => this.props.set_server_status(res.data.serverInfo)).catch(err => this.setState({
            ...this.state,
            working: false
        }, () => {
            if (err.response && err.response.status === 409) alert('That domain is already in use on another app.');
            else alert('An error occurred. Please try again later.');
        })));
    }

    render(){
        return (
            <motion.div transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out}>
                <RegisterModal 
                    modalShown={this.state.registerModalShown}
                    setShowModal={this.setRegisterModal}
                    toggleShowModal={this.toggleRegisterModal}
                />
                <RecordsModal   
                    modalShown={this.state.recordsModalShown}
                    setShowModal={this.setRecordsModal}
                    toggleShowModal={this.toggleRecordsModal}
                />
                <h5 className="display-6 d-flex mt-4">
                    <span>Step 1: Register Your Domain</span>
                    <MDBBtn onClick={this.toggleRegisterModal} style={{height: '20px'}} className="p-1" size="sm" color="link" rippleColor="primary">
                        <i className="fas fa-question-circle me-2"/>
                        Need Help?
                    </MDBBtn>
                </h5>
                <hr/>
                <h5 className="display-6 d-flex mt-4">
                    <span>Step 2: Create the Following DNS Records:</span>
                    <MDBBtn onClick={this.toggleRecordsModal} style={{height: '20px'}} className="p-1" size="sm" color="link" rippleColor="primary">
                        <i className="fas fa-question-circle me-2"/>
                        Need Help?
                    </MDBBtn>
                </h5>
                <MDBTable align="middle" className="mx-auto">
                    <MDBTableHead>
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Name</th>
                            <th scope="col">Content</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        <tr>
                            <th scope="row">A</th>
                            <td className="text-muted fst-italic">Leave blank or set as root</td>
                            <td>{this.props.serverStatus.ip}</td>
                        </tr>
                        <tr>
                            <th scope="row">A</th>
                            <td>www</td>
                            <td>{this.props.serverStatus.ip}</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
                <hr></hr>
                <h5 className="display-6 mt-4">Step 3: Enter your Domain Name Here and Submit</h5>
                <div className="d-flex align-items-center mt-4">
                    <MDBValidation onSubmit={this.submit} className="mx-0" id="form_domain" name="form_domain" method="dialog">
                        <MDBValidationItem feedback={this.state.domain.error} invalid={true}>
                            <MDBInputGroup>
                                <input
                                    type="text"
                                    placeholder="example.com"
                                    onChange={this.changeHandler}
                                    id="input-domain"
                                    className="form-control"
                                />
                            </MDBInputGroup>
                        </MDBValidationItem>
                    </MDBValidation>
                    <MDBBtn 
                        color="success"
                        onClick={this.submit}
                        disabled={this.state.working}
                        className="ms-2"
                    >
                        {this.state.working ?
                        <>
                            <Spinner className="me-2" size="sm" />
                            Submitting
                        </> :
                        <>
                            <i className="fas fa-paper-plane me-2" />
                            Submit
                        </>
                        }
                    </MDBBtn>
                </div>
                
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { set_server_status })(Start);