import React from 'react';
import History from './History';
import Messages from './Messages';
import Preferences from './Preferences';
import Actions from './Actions';

class LiveView extends React.Component{
    constructor(props){
        super();
        this.view = props.view;
    }

    render(){
        switch(this.view){
            case 'history':
                return (
                    <History 
                    
                    />
                );
            case 'messages':
                return (
                    <Messages 
                    
                    />
                );
            case 'preferences':
                return (
                    <Preferences 
                        notify={this.props.notify}
                    />
                );
            case 'actions':
                return (
                    <Actions 
                        notify={this.props.notify}
                    />
                );
            default:
                console.log('oob view', this.view);
                return <></>
        }
    }
}

export default LiveView;