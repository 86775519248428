import React from 'react';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBTooltip } from 'mdb-react-ui-kit';
import Spinner from '../Spinner';
import { motion } from 'framer-motion';
import t from '../../utilities/transitions';


/**
 * 
 * @param {String} theme - The current CSS theme - "default" | "dark"
 * @returns 
 */
const getColor = theme => {
    switch(theme){
        case 'default':
            return "rgb(201, 191, 177, var(--mdb-bg-opacity))";
        case 'dark':
            return "rgb(171, 168, 168, var(--mdb-bg-opacity))";
        default:
            return "rgb(201, 191, 177, var(--mdb-bg-opacity))";
    }
}

/**
 * 
 * @param {Function} nav - The navigation function 
 * @param {Function} logout - The logout function 
 * @param {Object} userInfo - Users document 
 * @param {Boolean} loggingout - Whether the user is in the process of logging out 
 * @returns The dropdown menu on desktop screens (Tooltip with avatar - User clicks to open additional navigation and options)
 */
const DropdownMenuDesktop = ({ nav, logout, userInfo, loggingOut }) => (
    <motion.span transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
        <MDBDropdown className="nav-item float-end me-4 navbar-user-desktop">
            <MDBDropdownToggle 
                style={{boxShadow: 'none'}} 
                color={(userInfo.userSettings && userInfo.userSettings.theme) ? getColor(userInfo.userSettings.theme) : "rgb(201, 191, 177, var(--mdb-bg-opacity))"} className={`nav-link links-generic rounded-6`} id="navbarDropdownMenuLink"
                rippleColor="dark"
            >
                <MDBTooltip 
                    className="me-1" 
                    wrapperClass="p-0" 
                    wrapperProps={{ color: (userInfo.userSettings && userInfo.userSettings.theme) ? getColor(userInfo.userSettings.theme) : "rgb(201, 191, 177, var(--mdb-bg-opacity))", noRipple: true}} 
                    placement='left' 
                    title={userInfo.username}
                >
                    <img className="nav-avatar" src={`${process.env.REACT_APP_BUCKET_HOST}/thumbnails/${userInfo.avatar.thumbnail}`}></img> 
                </MDBTooltip>
            </MDBDropdownToggle>
            <MDBDropdownMenu>
                <MDBDropdownItem link>
                    <p onClick={e => nav(e, '/dashboard')} className="dropdown-items m-0">Dashboard</p>
                </MDBDropdownItem>
                <MDBDropdownItem onClick={() => logout(true)} link>
                    <p className="text-danger dropdown-items m-0">
                        {loggingOut ?
                        <span style={{opacity: 0.5}}>
                            <Spinner className="me-2" size="sm" color="danger" />
                            Logging Out
                        </span> :
                        "Logout"}
                    </p>
                </MDBDropdownItem>
            </MDBDropdownMenu>
        </MDBDropdown>
    </motion.span>
    
);

export default DropdownMenuDesktop;