import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import h from '../../../../../../utilities/helpers';
import axios from 'axios';
import { set_message_read } from '../../../../../../redux/actions';

class MessageModal extends React.Component{
    constructor(){
        super();
        this.state = {
        }
    }

    // Mark message as read when the user clicks on it
    componentDidUpdate(prevProps){
        if (!prevProps.modalShown && this.props.modalShown && !this.props.message.read){
            this.props.set_message_read(this.props.message._id);
            axios.get(`/dashboard/read-message/${this.props.message._id}`);
        }
    }

    render(){
        const message = this.props.message;
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="xl">
                        {message ?
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Contact Form Message - Jizzer Beta</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h5 className="m-0">{message.name}</h5>
                                        <p>{message.email}</p>
                                        <p className="m-0"><span className="fw-bold">Subject: </span>{message.subject}</p>
                                    </div>
                                    <div>
                                        <p className="text-end m-0 text-blusteel">{h.makeDateHR(new Date(message.timestamp))}</p>
                                        <p className="text-end m-0 text-blusteel">{h.getTimeHR(new Date(message.timestamp))}</p>
                                    </div>
                                </div>
                                <hr />
                                <p className="m-0">{message.message}</p>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent> : <></>}
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_message_read })(MessageModal);