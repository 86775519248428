import { store } from '../';

export const SET_HISTORY = 'SET_HISTORY';
export const set_history = (location, history) => ({
    type: SET_HISTORY,
    data: {
        location: location,
        history: history
    }
});

export const SET_USER = 'SET_USER';
export const set_user = (userInfo) => {
    const socket = store.getState().socket;
    if (socket && socket.disconnect) socket.disconnect();
    return {
        type: SET_USER,
        data: {
            userInfo: userInfo
        }
    }
}

export const TERMINATE_DASHBOARD = 'TERMINATE_DASHBOARD';
export const terminate_dashboard = () => ({
    type: TERMINATE_DASHBOARD
});

export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const set_dashboard_data = data => ({
    type: SET_DASHBOARD_DATA,
    data: data
});

export const LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES';
export const load_more_messages = data => ({
    type: LOAD_MORE_MESSAGES,
    data: data
});

export const UPDATE_DASHBOARD_DATA = 'UPDATE_DASHBOARD_DATA';
export const update_dashboard_data = data => ({
    type: UPDATE_DASHBOARD_DATA,
    data: data
});

export const SET_VERIFICATION_DETAILS = 'SET_VERIFICATION_DETAILS';
export const set_verification_details = details => ({
    type: SET_VERIFICATION_DETAILS,
    data: details
});

export const SET_MESSAGE_READ = 'SET_MESSAGE_READ';
export const set_message_read = messageID => ({
    type: SET_MESSAGE_READ,
    data: messageID
});

export const CHANGE_USER_DETAILS = 'CHANGE_USER_DETAILS';
export const change_user_details = userInfo => ({
    type: CHANGE_USER_DETAILS,
    data: userInfo
});

export const SET_TEMP_ACTION = 'SET_TEMP_ACTION';
export const set_temp_action = action => ({
    type: SET_TEMP_ACTION,
    data: action
});

export const CLEAR_TEMP_ACTION = 'CLEAR_TEMP_ACTION';
export const clear_temp_action = action => ({
    type: CLEAR_TEMP_ACTION
});

export const RECONNECT_SOCKET = 'RECONNECT_SOCKET';
export const reconnect_socket = () => ({
    type: RECONNECT_SOCKET
});

export const SET_PROFILE = 'SET_PROFILE';
export const set_profile = data => ({
    type: SET_PROFILE,
    data: data
});

export const SET_CAPTCHA_READY = 'SET_CAPTCHA_READY';
export const set_captcha_ready = () => ({
    type: SET_CAPTCHA_READY
});

export const VIEWS = 'VIEWS';
export const views = emissionIDs => ({
    type: VIEWS,
    data: emissionIDs
});

export const SEARCH_CHANGE = 'SEARCH_CHANGE';
export const search_change = (e, source) => ({
    type: SEARCH_CHANGE,
    data: {
        text: e.target.value,
        source: source
    }
});

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const set_search_term = () => ({
    type: SET_SEARCH_TERM
});

export const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';
export const set_unread_messages = unreadMessages => ({
    type: SET_UNREAD_MESSAGES,
    data: unreadMessages
});

export const SET_UNREAD_MOD_LOGS = 'SET_UNREAD_MOD_LOGS';
export const set_unread_mod_logs = count => ({
    type: SET_UNREAD_MOD_LOGS,
    data: count
});

export const SET_OPEN_REPORTS = 'SET_OPEN_REPORTS';
export const set_open_reports = openReports => ({
    type: SET_OPEN_REPORTS,
    data: openReports
});

export const SET_PROFILE_REPORTED = 'SET_PROFILE_REPORTED';
export const set_profile_reported = () => ({
    type: SET_PROFILE_REPORTED
});

export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT'
export const set_notification_count = count => ({
    type: SET_NOTIFICATION_COUNT,
    data: count
});

export const SET_REPORT_COUNT = 'SET_REPORT_COUNT';
export const set_report_count = count => ({
    type: SET_REPORT_COUNT,
    data: count
});

export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const clear_profile = () => ({
    type: CLEAR_PROFILE
});

export const SELECT_DASHBOARD_ITEM = 'SELECT_DASHBOARD_ITEM';
export const select_dashboard_item = item => ({
    type: SELECT_DASHBOARD_ITEM,
    data: item
});

export const PURGE_USER = 'PURGE_USER';
export const purge_user = () => ({
    type: PURGE_USER
});

export const DASHBOARD_NAV = 'DASHBOARD_NAV';
export const dashboard_nav = item => ({
    type: DASHBOARD_NAV,
    data: item
});

export const SELECT_INSTANCE = 'SELECT_INSTANCE';
export const select_instance = instance => ({
    type: SELECT_INSTANCE,
    data: instance
});

export const SET_SERVER_STATUS = 'SET_SERVER_STATUS';
export const set_server_status = status => ({
    type: SET_SERVER_STATUS,
    data: status
});

export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';
export const update_user_list = userInfo => {
    const state = store.getState();
    if (state.userInfo._id === userInfo._id){
        if (state.socket && state.socket.disconnect) state.socket.disconnect();
    }
    
    return {
        type: UPDATE_USER_LIST,
        data: userInfo
    }
};

export const ROUTE = 'ROUTE'
export const route = (destination, hash) => {
    /**
     * Get state from reducer
     * Get current route
     * If user logged in and going to login, redirect to dashboard and vice-versa
     * Route user, then add most recent route to history stack in the reducer
     */

    let state = store.getState();
    const prevRoute = state.history.location.pathname;
    if (state.userInfo._id && ['login', 'create-account', 'forgot-password'].indexOf(destination.split('/')[1]) > -1) destination = `/${state.userInfo.username}`;

    return {
        type: 'ROUTE',
        data: {
            prevRoute: prevRoute,
            destination: destination,
            hash: hash
        }
    }
}

export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';
export const toggle_dark_mode = () => ({
    type: TOGGLE_DARK_MODE
});

export const SET_PREFERENCE_STATUS = 'SET_PREFERENCE_STATUS';
export const set_preference_status = status => ({
    type: SET_PREFERENCE_STATUS,
    data: status
});

export const RESTORE_USER = 'RESTORE_USER';
export const restore_user = (userInfo, restoredEmissions) => ({
    type: RESTORE_USER,
    data: {
        userInfo: userInfo,
        emissions: restoredEmissions
    }
});