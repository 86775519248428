import React from 'react';
import Start from './Start';
import Preferences from './Preferences';
import Live from './Live';

class Status extends React.Component{
    constructor(props){
        super();
        this.status = props.status;
    }

    render(){
        switch(this.status){
            case 'preregister':
                return (
                    <Start
                        changeStatus={this.props.changeStatus}
                        notify={this.props.notify}
                    />
                );
            case 'validating-domain': case 'generating-ssl': case 'creating-database': case 'awaiting-preferences': case 'building-app':
                return (
                    <Preferences
                        changeStatus={this.props.changeStatus}
                        notify={this.props.notify}
                    />
                );
            case 'live': case 'update-queued':
                return (
                    <Live 
                        changeStatus={this.props.changeStatus}
                        notify={this.props.notify}
                    />
                );
            default:
                console.log('oob status', this.status);
                return <></>
        }
    }
}

export default Status;