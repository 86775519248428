import React from 'react';
import Request from './Request';
import BugReport from './BugReport';
import General from './General';
import ReportAbuse from './ReportAbuse';

class FeedbackForm extends React.Component{
    constructor(props){
        super();
        this.form = props.form;
    }

    render(){
        switch(this.form){
            case 'general':
                return (
                    <General
                        form={this.props.form}
                        working={this.props.working}
                        options={this.props.options}
                        fields={this.props.fields}
                        inputs={this.props.inputs}
                        changeHandler={this.props.changeHandler}
                        submit={this.props.submit}
                    />
                );
            case 'bug':
                return (
                    <BugReport 
                        form={this.props.form}
                        working={this.props.working}
                        options={this.props.options}
                        fields={this.props.fields}
                        inputs={this.props.inputs}
                        changeHandler={this.props.changeHandler}
                        submit={this.props.submit}
                    />
                );
            case 'request':
                return (
                    <Request 
                        form={this.props.form}
                        working={this.props.working}
                        options={this.props.options}
                        fields={this.props.fields}
                        inputs={this.props.inputs}
                        changeHandler={this.props.changeHandler}
                        submit={this.props.submit}
                    />
                );
            case 'report':
                return (
                    <ReportAbuse 
                        form={this.props.form}
                        working={this.props.working}
                        options={this.props.options}
                        fields={this.props.fields}
                        inputs={this.props.inputs}
                        changeHandler={this.props.changeHandler}
                        submit={this.props.submit}
                    />
                );
            default:
                console.log('oob form');
                return <></>
        }
    }
}

export default FeedbackForm;