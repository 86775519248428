import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../../../../utilities/transitions';
import {
    MDBContainer
} from 'mdb-react-ui-kit';
import JizzerBetaCard from './list/JizzerBetaCard';

class List extends React.Component{
    constructor(props){
        super();
        this.state = {
            exit: props.loaded ? t.fade_out_left : t.fade_out
        }
    }

    // Set the appropriate exit animation after mounting
    componentDidMount(){
        this.setState({
            ...this.state,
            exit: t.fade_out_left
        });
    }

    render(){
        return (
            <motion.div className="pt-4" transition={t.transition} initial={this.state.exit} animate={t.normalize} exit={this.state.exit}>
                <MDBContainer fluid>
                    <div className="row">
                        <div className="col-12 col-lg-6 col-xxl-4 mt-4">
                            {this.props.userInfo.instances && this.props.userInfo.instances.find(i => i.id === 'jizzer-beta') ?
                            <JizzerBetaCard 
                                setView={this.props.setView}
                            /> : <></>}
                        </div>
                    </div>
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(List);