import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import Spinner from '../../../../../../components/Spinner';
import axios from 'axios';
import { set_server_status } from '../../../../../../redux/actions';

class RestoreModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean - Whether a request to restore the instance is in the process of being made
             */
            working: false
        }
    }

    /**
     * Triggered when the user clicks Confirm
     * 
     * Requests to have the instance restored
     * Updates the server status
     * Hides the modal
     */
    confirm = () => {
        if (!this.state.working) this.setState({
            ...this.state,
            working: true
        }, () => axios.get('/jizzer/beta-restore').then(res => {
            this.props.set_server_status(res.data);
            this.setState({
                ...this.state,
                working: false
            }, this.props.toggleShowModal);
        }).catch(err => this.setState({
            ...this.state,
            working: false
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again');
        })));
    }

    render(){
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="xl">
                    <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Are You Sure?</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <h5 className="text-center display-6 my-4">You are about to restore your Jizzer Beta instance</h5>
                                <p className="text-center">Are you sure you want to do this?</p>
                            </MDBModalBody>
                            <MDBModalFooter>
                                {this.state.working ?
                                <MDBBtn
                                    disabled
                                    color="success"
                                >
                                    <Spinner size="sm" className="me-2" />
                                    Restore
                                </MDBBtn> :
                                <MDBBtn
                                    color="success"
                                    onClick={this.confirm}
                                >
                                    <i className="fas fa-check-circle me-2" />
                                    Confirm
                                </MDBBtn>}
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_server_status })(RestoreModal);