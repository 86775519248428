import React from 'react';
import { motion } from 'framer-motion';
import t from '../../../../../utilities/transitions';
import { connect } from 'react-redux';
import {
    MDBDatatable,
    MDBBtn
} from 'mdb-react-ui-kit';
import h from '../../../../../utilities/helpers';
import DetailModal from './DetailModal';
import RestoreModal from './RestoreModal';

class Disabled extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * detailModalShown: Boolean - Whether the Instance Details modal is shown
             * restoreModalShown: Boolean - Whether the Restore Instance modal is shown
             * instanceSelected: false | Object - Details of the instance currently selected
             */
            detailModalShown: false,
            restoreModalShown: false,
            instanceSelected: false
        }
    }

    toggleDetailModal = () => this.setState({
        ...this.state,
        detailModalShown: !this.state.detailModalShown
    });

    setDetailModal = option => this.setState({
        ...this.state,
        detailModalShown: option
    });

    toggleRestoreModal = () => this.setState({
        ...this.state,
        restoreModalShown: !this.state.restoreModalShown
    });

    setRestoreModal = option => this.setState({
        ...this.state,
        restoreModalShown: option
    });

    /**
     * 
     * @param {Object} instance - ref Users.instances.[x]
     * 
     * Triggered when ther user clicks on an instance
     * Selects that instance and opens the Instance Details modal
     */
    viewDetails = instance => this.setState({
        ...this.state,
        instanceSelected: instance
    }, this.toggleDetailModal);

    /**
     * 
     * @param {Object} instance - ref Users.instances.[x]
     * 
     * Triggered when ther user clicks on an instance
     * Selects that instance and opens the Restore Instance modal
     */
    restore = instance => this.setState({
        ...this.state,
        instanceSelected: instance
    }, this.toggleRestoreModal);

    render(){
        const userList = this.props.userList.filter(u => {
            const instance = u.instances.find(i => i.id === 'jizzer-beta' && ['disabled'].indexOf(i.status) > -1);
            return instance;
        });
        return (
            <motion.div className="fg-1 dashboard-containers" transition={t.transition} initial={this.props.enter} animate={t.normalize} exit={this.props.exit}>
                <DetailModal    
                    modalShown={this.state.detailModalShown}
                    setShowModal={this.setDetailModal}
                    toggleShowModal={this.toggleDetailModal}
                    instance={this.state.instanceSelected}
                />
                <RestoreModal
                    modalShown={this.state.restoreModalShown}
                    setDisableModal={this.setRestoreModal}
                    toggleShowModal={this.toggleRestoreModal}
                    instance={this.state.instanceSelected}
                />
                {userList.length ?
                <MDBDatatable
                    className="h-100 d-flex flex-column table-vertical-center"
                    data={{
                        columns: [
                            {label: 'Instance', field: 'instance'},
                            {label: 'Disabled', field: 'date'},
                            {label: 'URL', field: 'url'},
                            {label: 'Actions', field: 'actions'}
                        ],
                        rows: userList.map(user => {
                            const instance = user.instances.find(i => i.id === 'jizzer-beta');
                            const server = this.props.servers.find(s => s.for === 'jizzer-beta').users.find(s => s.userID === user._id);
                            return {
                                instance: (
                                    <div className="p-1 rounded-4 d-flex align-items-center cursor-pointer">
                                        <div className="d-flex justify-content-center align-items-center square-3 me-2">
                                            <div className="fit-images fit-round" style={{backgroundImage: `url("${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/icons/favicon-96x96.png")`, borderRadius: '50%'}}></div>
                                        </div>
                                        <h5 className="m-0 display-6 fs-5">{instance.preferences.app_name}</h5>
                                    </div>
                                ),
                                date: h.makeDateHR(new Date(instance.actions.find(a => a.action === 'launched').timestamp)),
                                url: <a className="links-generic" href={'https://' + server.domain}>{server.domain}</a>,
                                actions: (
                                    <>
                                        <MDBBtn
                                            color="dark"
                                            className="d-block"
                                            onClick={() => this.viewDetails({
                                                ...instance,
                                                server: server,
                                                user: user
                                            })}
                                        >
                                            <i className="fas fa-eye me-2" />
                                            View Details
                                        </MDBBtn>
                                        <MDBBtn 
                                            color="success"
                                            className="mt-2 d-block"
                                            onClick={() => this.restore({
                                                ...instance,
                                                server: server,
                                                user: user
                                            })}
                                        >
                                            <i className="fas fa-check-circle me-2" />
                                            Restore Instance
                                        </MDBBtn>
                                    </>
                                )
                            }
                        })
                    }}
                /> : 
                <>
                    <hr/>
                    <h5 className="text-center">No disabled instances found</h5>
                </>}
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(Disabled);