import React from 'react';
import NameIcons from './NameIcons';
import Verbiage from './Verbiage';
import Colors from './Colors';
import ContentSecurity from './ContentSecurity';
import CustomCSS from './CustomCSS';
import Rules from './Rules';

/**
 * name-icons
 * verbiage
 * colors
 * content-security
 */


class PreferenceForm extends React.Component{
    constructor(props){
        super();
        this.formSelected = props.formSelected;
    }

    /**
     * There are 2 tabs that have rich text editors: "Rules and "Content and Security"
     * Each one must set the forceParse function run once the component mounts
     */
    componentDidMount(){
        if (this.props.formSelected === 'content-security' && !this.props.update) this.props.setForceParse(this.forceParse);
    }

    forceParse_rules = f => {
        this.forceParse = f;
        this.props.setForceParse(this.forceParse);
    }

    render(){
        switch(this.formSelected){
            case 'name-icons':
                return (
                    <NameIcons
                        selectForm={this.props.selectForm}
                        loaded={this.props.loaded}
                        tabExit={this.props.tabExit}
                        tabEnter={this.props.tabEnter}
                        fields={this.props.fields}
                        inputs={this.props.inputs}
                        files={this.props.files}
                        colors={this.props.colors}
                        changeHandler={this.props.changeHandler}
                        submit={this.props.submit}
                        submitted={this.props.submitted}
                        tempSubmitted={this.props.tempSubmitted}
                        selectFile={this.props.selectFile}
                        next={this.props.next}
                        pressTab={this.props.pressTab}
                    />
                );
            case 'verbiage':
                return (
                    <Verbiage
                        selectForm={this.props.selectForm}    
                        loaded={this.props.loaded}
                        tabExit={this.props.tabExit}
                        tabEnter={this.props.tabEnter}
                        fields={this.props.fields}
                        inputs={this.props.inputs}
                        files={this.props.files}
                        colors={this.props.colors}
                        changeHandler={this.props.changeHandler}
                        submit={this.props.submit}
                        submitted={this.props.submitted}
                        tempSubmitted={this.props.tempSubmitted}
                        selectFile={this.props.selectFile}
                        next={this.props.next}
                        pressTab={this.props.pressTab}
                    />
                );
            case 'colors':
                return (
                    <Colors
                        selectForm={this.props.selectForm}
                        loaded={this.props.loaded}
                        tabExit={this.props.tabExit}
                        tabEnter={this.props.tabEnter}
                        fields={this.props.fields}
                        inputs={this.props.inputs}
                        files={this.props.files}
                        colors={this.props.colors}
                        changeHandler={this.props.changeHandler}
                        submit={this.props.submit}
                        submitted={this.props.submitted}
                        tempSubmitted={this.props.tempSubmitted}
                        selectFile={this.props.selectFile}
                        next={this.props.next}
                        pressTab={this.props.pressTab}
                        colorChange={this.props.colorChange}
                    />
                );
            case 'content-security':
                return (
                    <ContentSecurity
                        selectForm={this.props.selectForm}
                        loaded={this.props.loaded}
                        tabExit={this.props.tabExit}
                        tabEnter={this.props.tabEnter}
                        fields={this.props.fields}
                        inputs={this.props.inputs}
                        files={this.props.files}
                        colors={this.props.colors}
                        changeHandler={this.props.changeHandler}
                        submit={this.props.submit}
                        submitted={this.props.submitted}
                        tempSubmitted={this.props.tempSubmitted}
                        selectFile={this.props.selectFile}
                        next={this.props.next}
                        pressTab={this.props.pressTab}
                        accountApprovalRequired={this.props.accountApprovalRequired}
                        emailVerificationRequired={this.props.emailVerificationRequired}
                        switchChange={this.props.switchChange}
                        submitting={this.props.submitting}
                        setForceParse={f => this.forceParse = f}
                        liveStreamingAllowed={this.props.liveStreamingAllowed}
                        imagesAllowed={this.props.imagesAllowed}
                        audioAllowed={this.props.audioAllowed}
                        videoAllowed={this.props.videoAllowed}
                        bio={this.props.bio}
                        update={this.props.update}
                    />
                );
            case 'custom-css':
                return (
                    <CustomCSS
                        selectForm={this.props.selectForm}
                        loaded={this.props.loaded}
                        tabExit={this.props.tabExit}
                        tabEnter={this.props.tabEnter}
                        fields={this.props.fields}
                        inputs={this.props.inputs}
                        files={this.props.files}
                        colors={this.props.colors}
                        changeHandler={this.props.changeHandler}
                        submit={this.props.submit}
                        submitted={this.props.submitted}
                        tempSubmitted={this.props.tempSubmitted}
                        selectFile={this.props.selectFile}
                        next={this.props.next}
                        pressTab={this.props.pressTab}
                        submitting={this.props.submitting}
                        update={this.props.update}
                    />
                );
            case 'rules':
                return (
                    <Rules
                        selectForm={this.props.selectForm}
                        loaded={this.props.loaded}
                        tabExit={this.props.tabExit}
                        tabEnter={this.props.tabEnter}
                        fields={this.props.fields}
                        inputs={this.props.inputs}
                        files={this.props.files}
                        colors={this.props.colors}
                        changeHandler={this.props.changeHandler}
                        submit={this.props.submit}
                        submitted={this.props.submitted}
                        setForceParse={this.forceParse_rules}
                        tempSubmitted={this.props.tempSubmitted}
                        selectFile={this.props.selectFile}
                        next={this.props.next}
                        pressTab={this.props.pressTab}
                        submitting={this.props.submitting}
                        rules={this.props.rules}
                        addRule={this.props.addRule}
                        ruleChangeHandler={this.props.ruleChangeHandler}
                        selectRule={this.props.selectRule}
                        ruleSelected={this.props.ruleSelected}
                        saveRule={this.props.saveRule}
                        removeRule={this.props.removeRule}
                    />
                );
            default:
                console.log('oob select form', this.formSelected);
                return <></>
        }
    }
}

export default PreferenceForm;