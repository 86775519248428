import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { StaticRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PreferenceNav from './PreferenceNav';
import t from '../../../../../utilities/transitions';
import PreferenceForm from './PreferenceForm';
import { preference_schema } from '../../../../../utilities/validations';
import { parse as parseHTML } from 'node-html-parser';
import axios from 'axios';
import { set_preference_status, change_user_details, set_server_status } from '../../../../../redux/actions';
import { v4 as uuid } from 'uuid';

/**
 * name-icons
 * verbiage
 * colors
 * content-security
 */

const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml'];

const tabs = ['name-icons', 'verbiage', 'colors', 'content-security', 'rules', 'custom-css'];

const fields = [
    {
        id: 'app_name',
        text: 'Name of App',
        tab: 'name-icons',
        defaultValue: 'Pigger'
    },
    {
        id: 'description',
        text: 'App Description (Short)',
        tab: 'name-icons',
        defaultValue: "The hottest new app exploding onto the internet!"
    },
    {
        id: 'max_individual_file_size',
        text: 'Max Single File Size (MB)',
        tab: 'content-security',
        defaultValue: '2'
    },
    {
        id: 'max_total_file_size',
        text: 'Max Total File Size (MB)',
        tab: 'content-security',
        defaultValue: '20'
    },
    {
        id: 'max_file_count',
        text: 'Max Files Per Post',
        tab: 'content-security',
        defaultValue: '4'
    },
    {
        id: 'max_emission_chars',
        text: 'Max Oink Characters',
        tab: 'content-security',
        defaultValue: '300'
    },
    {
        id: 'emission_name',
        text: 'Oink Name',
        tab: 'verbiage',
        defaultValue: 'Oink'
    },
    {
        id: 'emission_plural',
        text: 'Oink - Plural',
        tab: 'verbiage',
        defaultValue: 'Oinks'
    },
    {
        id: 'signalboost_name',
        text: 'Signalboost Name',
        tab: 'verbiage',
        defaultValue: 'Signal Boost'
    },
    {
        id: 'signalboost_plural',
        text: 'Signalboost - Plural',
        tab: 'verbiage',
        defaultValue: 'Signal Boosts'
    },
    {
        id: 'signalboost_past',
        text: 'Signalboost - Past',
        tab: 'verbiage',
        defaultValue: 'Signal Boosted'
    },
    {
        id: 'signalboost_verb',
        text: 'Signalboost - Verb',
        tab: 'verbiage',
        defaultValue: 'Boost'
    },
    {
        id: 'signalboost_current',
        text: 'Signalboost - Current',
        tab: 'verbiage',
        defaultValue: 'Boosting'
    },
    {
        id: 'follow_name',
        text: 'Follower Name',
        tab: 'verbiage',
        defaultValue: 'Follower'
    },
    {
        id: 'follow_plural',
        text: 'Follower - Plural',
        tab: 'verbiage',
        defaultValue: 'Followers'
    },
    {
        id: 'follow_verb',
        text: 'Follow - Verb',
        tab: 'verbiage',
        defaultValue: 'Follow'
    },
    {
        id: 'follow_current',
        text: 'Follow - Current',
        tab: 'verbiage',
        defaultValue: 'Following'
    },
    {
        id: 'unfollow_verb',
        text: 'Unfollow - Verb',
        tab: 'verbiage',
        defaultValue: 'Unfollow'
    },
    {
        id: 'unfollow_current',
        text: 'Unfollow - Current',
        tab: 'verbiage',
        defaultValue: 'Unfollowing'
    },
    {
        id: 'admin_name',
        text: 'Admin Name',
        tab: 'verbiage',
        defaultValue: 'Chadmin'
    },
    {
        id: 'admin_plural',
        text: 'Admin - Plural',
        tab: 'verbiage',
        defaultValue: 'Chadmins'
    },
    {
        id: 'mod_name',
        text: 'Moderator Name',
        tab: 'verbiage',
        defaultValue: 'Janny'
    },
    {
        id: 'mod_plural',
        text: 'Moderator - Plural',
        tab: 'verbiage',
        defaultValue: 'Jannies'
    },
    {
        id: 'child_name',
        text: 'Regular User Name',
        tab: 'verbiage',
        defaultValue: 'Child'
    },
    {
        id: 'child_plural',
        text: 'Regular User - Plural',
        tab: 'verbiage',
        defaultValue: 'Children'
    },
    {
        text: 'Username',
        id: 'gigachad_username',
        tab: 'content-security',
        gigachad: true
    },
    {
        text: 'Email Address',
        id: 'gigachad_email',
        tab: 'content-security',
        gigachad: true
    },
    {
        text: 'Password',
        id: 'gigachad_password1',
        tab: 'content-security',
        defaultValue: ''
    },
    {
        text: 'Re-Enter Password',
        id: 'gigachad_password2',
        tab: 'content-security',
        defaultValue: ''
    },
    {
        text: 'Display Name',
        id: 'gigachad_displayName',
        tab: 'content-security',
        gigachad: true
    },
    {
        text: 'Location (Optional)',
        id: 'gigachad_location',
        tab: 'content-security',
        gigachad: true
    },
    {
        text: 'Website (Optional)',
        id: 'gigachad_website',
        tab: 'content-security',
        gigachad: true
    },
    {
        text: 'All Themes',
        id: 'custom_css',
        tab: 'custom-css',
        defaultValue: ''
    },
    {
        text: 'Default Theme',
        id: 'custom_css_default',
        tab: 'custom-css',
        defaultValue: ''
    },
    {
        text: 'Dark Theme',
        id: 'custom_css_dark',
        tab: 'custom-css',
        defaultValue: ''
    }
];

class PreferenceForms extends React.Component{
    constructor(props){
        super();
        const instance = props.userInfo.instances.find(i => i.id === 'jizzer-beta');
        this.state = {
            /**
             * tabExit: Object - framer-motion exit animation for outgoing tab
             * tabEnter: Object - framer-motion entrance animation for incoming tab
             * formSelected: String - The form (tab) that the user is currently on
             * loaded: Boolean - Whether the initial data has loaded
             * inputs: Array - The input data (values, errors, etc)
             * submitted: Boolean - Whether the user has attempted to submit the forms
             * tempSubmitted: Boolean - Whether the individual form on the current tab has been validated
             * colors: Array - List of CSS theme colors
             * files: Object - The instance's default files (logo, default avatar, badges, etc)
             * accountApprovalRequired: Boolean - Whether manual account approval by moderators is required on the instance
             * emailVerificationRequired: Boolean - Whether email verification is required on the instance
             * imagesAllowed: Boolean - Whether image uploads are allowed on the instance
             * audioAllowed: Boolean - Whether audio uploads are allowed on the instance
             * videoAllowed: Boolean - Whether video uploads are allowed on the instance
             * liveStreamingAllowed: Boolean - Whether livestreaming is allowed on the instance
             * submitting: Boolean - Whether an updated set of preferences has been submitted and is currently being processed
             * rules: Array - List of custom rules that the user has added for their instance
             * ruleSelected: false | Object - Rule that the user has selected
             */
            tabExit: t.fade_out,
            tabEnter: t.fade_out,
            formSelected: 'name-icons',
            loaded: false,
            inputs: fields.map(field => ({
                id: field.id,
                error: '',
                invalid: (!(field.defaultValue || field.gigachad)),
                value: field.id.split('password').length > 1 ? '' : (!instance.preferences ? (field.gigachad ? props.userInfo[field.id.split('gigachad_')[1]] : field.defaultValue) : (field.gigachad ? String(instance.preferences.gigachad[field.id.split('gigachad_')[1]]) : String(instance.preferences[field.id]))),
                tab: field.tab,
                label: field.text
            })),
            submitted: false,
            tempSubmitted: false,
            colors: [
                {
                    id: 'body_text_color_default',
                    label: 'Default Text Color',
                    value: !instance.preferences ? '#4f4f4f' : instance.preferences.body_text_color_default,
                    default: true
                },
                {
                    id: 'body_background_color_default',
                    label: 'Body Background Color',
                    value: !instance.preferences ? '#ffffff' : instance.preferences.body_background_color_default,
                    default: true
                },
                {
                    id: 'primary_default',
                    label: 'Primary',
                    value: !instance.preferences ? '#1266f1' : instance.preferences.primary_default,
                    default: true
                },
                {
                    id: 'secondary_default',
                    label: 'Secondary',
                    value: !instance.preferences ? '#b23cfd' : instance.preferences.secondary_default,
                    default: true
                },
                {
                    id: 'success_default',
                    label: 'Success',
                    value: !instance.preferences ? '#00b74a' : instance.preferences.success_default,
                    default: true
                },
                {
                    id: 'info_default',
                    label: 'Info',
                    value: !instance.preferences ? '#39c0ed' : instance.preferences.info_default,
                    default: true
                },
                {
                    id: 'warning_default',
                    label: 'Warning',
                    value: !instance.preferences ? '#ffa900' : instance.preferences.warning_default,
                    default: true
                },
                {
                    id: 'danger_default',
                    label: 'Danger',
                    value: !instance.preferences ? '#f93154' : instance.preferences.danger_default,
                    default: true
                },
                {
                    id: 'light_default',
                    label: 'Light',
                    value: !instance.preferences ? '#f9f9f9' : instance.preferences.light_default,
                    default: true
                },
                {
                    id: 'dark_default',
                    label: 'Dark',
                    value: !instance.preferences ? '#262626' : instance.preferences.dark_default,
                    default: true
                },
                {
                    id: 'body_text_color_dark',
                    label: 'Default Text Color',
                    value: !instance.preferences ? '#ffffff' : instance.preferences.body_text_color_dark
                },
                {
                    id: 'body_background_color_dark',
                    label: 'Body Background Color',
                    value: !instance.preferences ? '#303030' : instance.preferences.body_background_color_dark
                },
                {
                    id: 'body_background_color_contrast_dark',
                    label: 'Background Color Contrast',
                    value: !instance.preferences ? '#424242' : instance.preferences.body_background_color_contrast_dark
                },
                {
                    id: 'primary_dark',
                    label: 'Primary',
                    value: !instance.preferences ? '#1266f1' : instance.preferences.primary_dark
                },
                {
                    id: 'secondary_dark',
                    label: 'Secondary',
                    value: !instance.preferences ? '#b23cfd' : instance.preferences.secondary_dark
                },
                {
                    id: 'success_dark',
                    label: 'Success',
                    value: !instance.preferences ? '#00b74a' : instance.preferences.success_dark
                },
                {
                    id: 'info_dark',
                    label: 'Info',
                    value: !instance.preferences ? '#39c0ed' : instance.preferences.info_dark
                },
                {
                    id: 'warning_dark',
                    label: 'Warning',
                    value: !instance.preferences ? '#ffa900' : instance.preferences.warning_dark
                },
                {
                    id: 'danger_dark',
                    label: 'Danger',
                    value: !instance.preferences ? '#f93154' : instance.preferences.danger_dark
                },
                {
                    id: 'light_dark',
                    label: 'Light',
                    value: !instance.preferences ? '#f9f9f9' : instance.preferences.light_dark
                },
                {
                    id: 'dark_dark',
                    label: 'Dark',
                    value: !instance.preferences ? '#262626' : instance.preferences.dark_dark
                }
            ],
            files: {
                icon: !instance.preferences ? `${process.env.REACT_APP_BUCKET_HOST}/images/default-jizzer.png` : `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/icons/favicon-96x96.png`,
                iconName: 'Click to Change',
                iconFile: '',
                defaultAvatar: !instance.preferences ? `${process.env.REACT_APP_BUCKET_HOST}/images/blank-avatar.png` : `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/blank-avatar.png`,
                defaultAvatarName: 'Click to Change',
                defaultAvatarFile: '',
                defaultBackground: !instance.preferences ? `${process.env.REACT_APP_BUCKET_HOST}/images/default-background.webp` : `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/default-background.webp`,
                defaultBackgroundName: 'Click to Change',
                defaultBackgroundFile: '',
                gigachadAvatar: !instance.preferences ? `${process.env.REACT_APP_BUCKET_HOST}/images/blank-avatar.png` : `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/${instance.preferences.gigachad.avatar.main}`,
                gigachadAvatarName: 'Click to Change',
                gigachadAvatarFile: '',
                gigachadBackground: !instance.preferences ? `${process.env.REACT_APP_BUCKET_HOST}/images/default-background.webp` : `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/${instance.preferences.gigachad.background.main}`,
                gigachadBackgroundName: 'Click to Change',
                gigachadBackgroundFile: '',
                chadminBadge: !instance.preferences ? `${process.env.REACT_APP_BUCKET_HOST}/images/default-admin.png` : `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/meltrans.png`,
                chadminBadgeName: 'Click to Change',
                chadminBadgeFile: '',
                jannyBadge: !instance.preferences ? `${process.env.REACT_APP_BUCKET_HOST}/images/default-janny.png` : `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/thomastrans.png`,
                jannyBadgeName: 'Click to Change',
                jannyBadgeFile: '',
                verifiedBadge: !instance.preferences ? `${process.env.REACT_APP_BUCKET_HOST}/images/default-verified.png` : `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/images/verifiedlogotrans.png`,
                verifiedBadgeName: 'Click to Change',
                verifiedBadgeFile: '',
                newJizzIcon: !instance.preferences ? `${process.env.REACT_APP_BUCKET_HOST}/images/newjizz.png` : `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/icons/newjizz.png`,
                newJizzIconName: 'Click to Change',
                newJizzIconFile: ''
            },
            accountApprovalRequired: !instance.preferences ? false : instance.preferences.accountApprovalRequired,
            emailVerificationRequired: !instance.preferences ? false : instance.preferences.emailVerificationRequired,
            imagesAllowed: !instance.preferences ? true : instance.preferences.imagesAllowed,
            audioAllowed: !instance.preferences ? true : instance.preferences.audioAllowed,
            videoAllowed: !instance.preferences ? true : instance.preferences.videoAllowed,
            liveStreamingAllowed: !instance.preferences ? true : instance.preferences.liveStreamingAllowed,
            submitting: false,
            bio: !instance.preferences ? props.userInfo.bio : instance.preferences.gigachad.bio,
            rules: !instance.preferences ? [] : instance.preferences.rules,
            ruleSelected: false
        }
    }

    // Run empty change handler
    componentDidMount(){
        this.changeHandler({
            target: {
                value: ''
            }
        });
    }

    /**
     * Triggered when the user clicks New Rule on the Rules tab
     * Adds a new empty rule
     */
    addRule = () => this.setState({
        ...this.state,
        rules: [
            ...this.state.rules,
            {
                id: uuid(),
                title: '',
                html: '<div><br/></div>',
                error: '',
                invalid: true
            }
        ]
    });

    /**
     * 
     * @param {String} id - Rule id selected
     * 
     * Triggered when the user clicks on a rule
     * Selects the rule
     */
    selectRule = id => this.setState({
        ...this.state,
        ruleSelected: id
    });

    /**
     * Triggered when the user clicks the Remove Rule button on the selected rule
     * Removes that rule 
     */
    removeRule = () => this.setState({
        ...this.state,
        ruleSelected: false,
        rules: this.state.rules.filter(rule => rule.id !== this.state.ruleSelected)
    });

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     * 
     * Sets the updated values into state
     */
    changeHandler = e => {
        this.setState({
            ...this.state,
            inputs: this.state.inputs.map(input => {
                if (input.id === e.target.name) return {
                    ...input,
                    value: String(e.target.value)
                }
                else return input
            })
        }, this.setValidity);
    }

    /**
     * 
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     *
     * Triggered when the user types into the Title field of the selected custom rule
     *  
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     *
     */
    ruleChangeHandler = e => this.setState({
        ...this.state,
        rules: this.state.rules.map(rule => {
            if (rule.id === e.target.name) return {
                ...rule,
                title: e.target.value,
                invalid: e.target.value.length > 250 || !e.target.value,
                error: (e.target.value.length > 250) ? 'Title is too long (Max: 200 chars)' : (!e.target.value ? 'Please enter a title' : '')
            }
            else return rule;
        })
    }, () => {
        this.state.rules.map(rule => {
            const element = document.getElementById(rule.id);
            if (element){
                if (rule.invalid) element.setCustomValidity(rule.error);
                else element.setCustomValidity('');
            }
        });
    });

    /**
     * 
     * @param {Event} e 
     * 
     * Triggered when the user changes the color in any of the Colors fields
     * Updates that color
     */
    colorChange = e => this.setState({
        ...this.state,
        colors: this.state.colors.map(color => {
            if (color.id === e.target.name) return {
                ...color,
                value: e.target.value
            }
            return color;
        })
    });

    /**
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    setValidity = () => {
        const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value.trim()]));
        try {
            preference_schema.validateSync(data, {
                abortEarly: false
            });
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    const element = document.getElementById(input.id)
                    if (element) element.setCustomValidity('');
                    return {
                        ...input,
                        invalid: false,
                        error: ''
                    }
                })
            });
        } catch(err){
            let errorsAdded = [];
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                        errorsAdded.push(input.id);
                        return {
                            ...input,
                            invalid: true,
                            error: err.inner.find(error => error.path === input.id).message
                        }
                    } 
                    else return {
                        ...input,
                        invalid: false,
                        error: ''
                    };
                })
            }, () => this.state.inputs.forEach(input => {
                const element = document.getElementById(input.id);
                if (element){
                    if (input.invalid) element.setCustomValidity('invalid');
                    else element.setCustomValidity('');
                }
            }));
        }
    }

    /**
     * Triggered when the user clicks the Save Rule button
     * Saves the current rule 
     */
    saveRule = () => {
        this.forceParse();
        this.setState({
            ...this.state,
            rules: this.state.rules.map(rule => {
                if (document.getElementById(rule.id)) return {
                    ...rule,
                    html: document.getElementById('input-bio').innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, '')
                } 
                else return rule;
            }),
            ruleSelected: false
        });
    }

    /**
     * Triggered when the user clicks the Submit button on the very last (CSS) tab
     * This submits the preferences
     * 
     * Validate all of the regular text inputs
     * Validate the custom rules
     * If there are any invalid inputs, navigate to the form where the first invalid input is found
     * Append files to form data
     * Submits preferences to server
     * Request will take a while - User will be notified of updates via socket
     * Updates server status to "updating" 
     */
    submit = () => this.setState({
        ...this.state,
        submitted: true
    }, () => {
        this.props.set_preference_status('Submitting');
        try {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value.trim()]));
            preference_schema.validateSync(data, {
                abortEarly: false
            });
            let badRule = false;
            this.state.rules.forEach(rule => {
                const ruleLength = parseHTML(rule.html).textContent.length
                if (!rule.title || rule.title.length > 250 || !ruleLength || ruleLength > 5000) badRule = rule.id;
            });
            if (badRule) this.setState({
                ...this.state,
                ruleSelected: badRule,
            }, () => this.selectForm(false, 'rules'));
            else if (!this.state.submitting) this.setState({
                ...this.state,
                submitting: true
            }, async () => {
                this.forceParse();
                const length = String(parseHTML(this.state.bio).textContent).split('').filter(c => {
                    const checkWhiteSpace = c.match(/[\s]/);
                    if (!checkWhiteSpace) return true;
                    else {
                        return [' ', '\n'].indexOf(c) > -1;
                    }
                }).length; 
                if (length > 1000) this.setState({
                    ...this.state,
                    submitting: false
                }, () => alert('Your bio is too long (Max: 1000 chars)'));
                else {
                    const fd = new FormData();
                    if (this.state.files.iconFile) fd.append('icon', this.state.files.iconFile, this.state.files.iconName);
                    if (this.state.files.defaultAvatarFile) fd.append('defaultAvatar', this.state.files.defaultAvatarFile, this.state.files.defaultAvatarName);
                    if (this.state.files.defaultBackgroundFile) fd.append('defaultBackground', this.state.files.defaultBackgroundFile, this.state.files.defaultBackgroundName);
                    if (this.state.files.gigachadAvatarFile) fd.append('gigachadAvatar', this.state.files.gigachadAvatarFile, this.state.files.gigachadAvatarName);
                    if (this.state.files.gigachadBackgroundFile) fd.append('gigachadBackground', this.state.files.gigachadBackgroundFile, this.state.files.gigachadBackgroundName);
                    if (this.state.files.chadminBadgeFile) fd.append('chadminBadge', this.state.files.chadminBadgeFile, this.state.files.chadminBadgeName);
                    if (this.state.files.jannyBadgeFile) fd.append('jannyBadge', this.state.files.jannyBadgeFile, this.state.files.jannyBadgeName);
                    if (this.state.files.verifiedBadgeFile) fd.append('verifiedBadge', this.state.files.verifiedBadgeFile, this.state.files.verifiedBadgeName);
                    if (this.state.files.newJizzIconFile) fd.append('newJizzIcon', this.state.files.newJizzIconFile, this.state.files.newJizzIconName);
                    for ( const key in data ) {
                        fd.append(key, data[key]);
                    }
                    this.state.rules.forEach(rule => fd.append('rules', JSON.stringify(rule)));
                    this.state.colors.forEach(color => fd.append(color.id, color.value));
                    fd.append('bio', parseHTML(this.state.bio).innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, ''));
                    fd.append('accountApprovalRequired', this.state.accountApprovalRequired);
                    fd.append('emailVerificationRequired', this.state.emailVerificationRequired);
                    fd.append('imagesAllowed', this.state.imagesAllowed);
                    fd.append('audioAllowed', this.state.audioAllowed);
                    fd.append('videoAllowed', this.state.videoAllowed);
                    fd.append('liveStreamingAllowed', this.state.liveStreamingAllowed);
                    const captchaKey = await this.getRecaptcha();
                    fd.append('captchaKey', captchaKey);
                    axios.post('/jizzer/jizzer-beta-preferences', fd).then(res => {
                        this.props.change_user_details(res.data.userInfo);
                        this.setState({
                            ...this.state,
                            submitting: false
                        }, () => {
                            if (this.props.serverStatus.status === 'awaiting-preferences') this.props.set_server_status({
                                ...this.props.serverStatus,
                                status: 'building-app'
                            });
                            this.props.notify(<i className="fas fa-check-circle text-success me-2" />, "Preferences Applied Successfully")
                        });
                    }).catch(err => this.setState({
                        ...this.state,
                        submitting: false
                    }, () => {
                        console.log(err);
                        alert('An error occurred. Please try again later.');
                    }));
                }
            });
        } catch(err){
            if (err.inner){
                const firstError = this.state.inputs.find(i => i.id === err.inner[0].path);
                if (firstError.tab) this.selectForm(false, firstError.tab);
                else {
                    console.log(err.inner[0].path);
                    alert('Errors detected. Please make sure all inputs are valid.');
                }
                console.log(firstError);
            } else {
                console.log(err);
                alert('An error occurred. Please try again later.');
            }
        }
    });

    /**
     * Executes a captcha challenge and generates a key a key
     * Will hang until connected to captcha servers
     */
    getRecaptcha = () => new Promise(async (resolve, reject) => {
        if (this.props.captchaReady) window.grecaptcha.enterprise.execute(process.env.REACT_APP_CAPTCHA_KEY, {action: 'login'}).then(resolve).catch(err => {
            console.log(err);
            alert('Human verification failed. Refresh the page and try again.');
            reject();
        });
        else setTimeout(async () => {
            const captchaKey = await this.getRecaptcha();
            resolve(captchaKey);
        }, 500);
    });

    /**
     * 
     * @param {String} option - A tab
     * 
     * Triggered when the user selects a new tab
     * Sets the appropriate exit and entrance animations
     * Sets rule data into state if there is a rule selected and the user is currently on the rules tab
     * Changes the form
     */
    selectForm = (e, option) => {
        if (this.state.formSelected === 'content-security') this.forceParse();
        this.setState({
            ...this.state,
            tabExit: (tabs.indexOf(option) > tabs.indexOf(this.state.formSelected)) ? t.fade_out_left : t.fade_out_right,
            tabEnter: (tabs.indexOf(option) > tabs.indexOf(this.state.formSelected)) ? t.fade_out_right : t.fade_out_left,
            tempSubmitted: false,
            bio: this.state.formSelected === 'content-security' ? document.getElementById('input-bio').innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, '') : this.state.bio,
            rules: this.state.formSelected === 'rules' ? this.state.rules.map(rule => {
                if (document.getElementById(rule.id)) return {
                    ...rule,
                    html: document.getElementById('input-bio').innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, '')
                } 
                else return rule;
            }) : this.state.rules
        }, () => this.setState({
            ...this.state,
            formSelected: option
        }));
    }

    switchChange = (e, val) => this.setState({
        ...this.state,
        [e.target.name]: val
    });

    /**
     * Fired when the user clicks their avatar or background
     * 
     * Creates a virtual file input
     * Adds a change event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     * Removes the input after the file is selected
     */
    selectFile = type => {
        let input = document.createElement('input');
        input.type = 'file';
        input.style.visibility = "hidden";
        input.style.position = "fixed";
        document.body.appendChild(input);
        input.onchange = e => {
            let file = e.target.files[0];
            if (allowedExtensions.indexOf(file.type) !== -1){
                if (file.size > Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE)) alert(`Max individual file size exceeded. (Max: ${Math.round((Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE)) / (1024 * 1024))}MB)`);
                else this.setState({
                    ...this.state,
                    files: {
                        ...this.state.files,
                        [`${type}Name`]: e.target.files[0].name,
                        [`${type}File`]: e.target.files[0],
                        [type]: URL.createObjectURL(e.target.files[0])
                    }
                }, () => document.body.removeChild(input));
            } else {
                document.body.removeChild(input);
                alert('Please select a valid image file (png, jpg, gif, bmp, webp)');
            }
        }
        input.click();
    }

    /**
     * Triggered when the user clicks the Next button at the bottom of any of the tabs
     * Navigates to the next tab if no errors are present in the current one
     */
    next = () => this.setState({
        ...this.state,
        tempSubmitted: true
    }, () => {
        if (!this.state.inputs.find(i => i.tab === this.state.formSelected && i.invalid)) this.selectForm(false, tabs[tabs.indexOf(this.state.formSelected) + 1]);
    });

    /**
     * 
     * @param {Event} e - Keypress event
     * 
     * Triggered when the user presses the Tab key
     * Moves cursor to next input (MDB is bugged)
     * Removed when MDB fixes
     */
    pressTab = e => {
        if (e.key === 'Tab'){
            e.preventDefault();
            const input = this.state.inputs.find(f => f.id === e.target.id);
            if (input){
                const nextField = this.state.inputs[this.state.inputs.indexOf(input) + 1];
                if (nextField){
                    const element = document.getElementById(nextField.id);
                    if (element){
                        setTimeout(() => {
                            element.focus();
                            element.select();
                        }, 100);
                    }
                }
            }
        }
    }

    render(){
        return (
            <>
                <PreferenceNav
                    formSelected={this.state.formSelected}
                    selectForm={this.selectForm}
                />
                <StaticRouter location={this.state.formSelected}>
                    <AnimatePresence exitBeforeEnter>
                        <Switch key={this.state.formSelected}>
                            <Route exact path=":form">
                                <PreferenceForm 
                                    formSelected={this.state.formSelected}
                                    selectForm={this.selectForm}
                                    loaded={this.state.loaded}
                                    tabExit={this.state.tabExit}
                                    tabEnter={this.state.tabEnter}
                                    fields={fields}
                                    inputs={this.state.inputs}
                                    files={this.state.files}
                                    colors={this.state.colors}
                                    changeHandler={this.changeHandler}
                                    submit={this.submit}
                                    submitted={this.state.submitted}
                                    tempSubmitted={this.state.tempSubmitted}
                                    selectFile={this.selectFile}
                                    next={this.next}
                                    pressTab={this.pressTab}
                                    colorChange={this.colorChange}
                                    accountApprovalRequired={this.state.accountApprovalRequired}
                                    emailVerificationRequired={this.state.emailVerificationRequired}
                                    switchChange={this.switchChange}
                                    submitting={this.state.submitting}
                                    setForceParse={f => this.forceParse = f}
                                    liveStreamingAllowed={this.state.liveStreamingAllowed}
                                    imagesAllowed={this.state.imagesAllowed}
                                    audioAllowed={this.state.audioAllowed}
                                    videoAllowed={this.state.videoAllowed}
                                    bio={this.state.bio}
                                    rules={this.state.rules}
                                    addRule={this.addRule}
                                    ruleChangeHandler={this.ruleChangeHandler}
                                    selectRule={this.selectRule}
                                    saveRule={this.saveRule}
                                    ruleSelected={this.state.ruleSelected}
                                    removeRule={this.removeRule}
                                />
                            </Route>
                        </Switch>
                    </AnimatePresence>
                </StaticRouter>
            </>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { set_preference_status, change_user_details, set_server_status })(PreferenceForms);