import React from 'react';
import { connect } from 'react-redux';
import {
    MDBCard,
    MDBCardBody,
    MDBContainer
} from 'mdb-react-ui-kit';
import h from '../utilities/helpers';
import { route } from '../redux/actions';
import { Link } from 'react-router-dom';

class ProfileInfo extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * userActionModalShown: Boolean - Whether the User Action (edit/ban) modal is shown
             * messageModalShown: Boolean - Whether the Direct Message modal is shown
             * liveStreamModalShown: Boolean - Whether the Live Stream modal is shown
             * reportModalShown: Boolean - Whether the Report modal is shown
             * working: Boolean - Whether the user is in the process of following or unfollowing the profile
             * showUnfollowText: Boolean - Whether the Unfollow prompt should be shown 
             * blocking: Boolean - Whether the user is in the process of blocking the profile
             * unblocking: Boolean - Whether the user is in the process of unblocking the profile
             */
            userActionModalShown: false,
            messageModalShown: false,
            liveStreamModalShown: false,
            reportModalShown: false,
            working: false,
            showUnfollowText: false,
            blocking: false,
            unblocking: false
        }
    }

    /**
     * userActionModalShown: Boolean - Whether the User Action (edit/ban) modal is shown
     * messageModalShown: Boolean - Whether the Direct Message modal is shown
     * liveStreamModalShown: Boolean - Whether the Live Stream modal is shown
     * reportModalShown: Boolean - Whether the Report modal is shown
     * working: Boolean - Whether the user is in the process of following or unfollowing the profile
     * showUnfollowText: Boolean - Whether the Unfollow prompt should be shown 
     * blocking: Boolean - Whether the user is in the process of blocking the profile
     * unblocking: Boolean - Whether the user is in the process of unblocking the profile
     */
    checkSpecialProfile = () => {
        if (this.props.profile.user_id === 1) return true;
        const split = String(this.props.profile.user_id).split('');
        if (split.length === 1) return false;
        if (split.every(c => c === split[0])) return true;
        if (split.length < 3) return false;
        let special = true;
        split.forEach((char, s) => {
            if (s && Number(char)) special = false;
        });
        return special;
    }

    /**
     * 
     * @param {Click Event} e 
     * @param {String} path - href/URL
     * 
     * Triggered when the user clicks a link
     * Override default behavior and use redux props.route method
     */
    route = (e, destination) => {
        e.preventDefault();
        this.props.route(destination);
    }

    /**
     * 
     * @param {Click Event} e 
     * 
     * Triggered when the user clicks inside the profile's bio
     * If the user clicked a link, route to the href
     */
    clickBio = e => {
        e.stopPropagation();
        e.preventDefault();
        let element = e.target;
        if (e.target.tagName === 'SPAN') element = e.target.parentElement;
        if (element.tagName === 'A'){
            const href = element.getAttribute('href');
            if (href.split('//').length > 1) window.location = element.href;
            else if (href.split('')[0] === '/') this.props.route(href);
            else window.location = element.href;
        }
    }

    render(){
        return (
            <>
                <MDBCard>
                    <MDBCardBody>
                        <div className="position-relative">
                            <div className="w-100" style={{backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/images/${this.props.profile.background.main}")`}} id="background-image-profile">
                            </div>
                            <div id="avatar-border-profile">
                                <div id="avatar-image-profile" style={{backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/images/${this.props.profile.avatar.main}")`}}></div>
                            </div>
                        </div>
                        <MDBContainer className="sample-profile-info px-0" fluid>
                            <div className="row mx-0 align-items-start">
                                <div className="col-12 col-xl-10 offset-0 offset-xl-2 px-0 d-flex justify-content-center">
                                    {this.props.profile.location ?
                                    <p className="text-blusteel text-break sample-profile-fact"><i className="fas fa-map-marker-alt me-2"></i>{this.props.profile.location}</p> : <></>}
                                    {this.props.profile.website ?
                                    <a className="ms-4 text-break sample-profile-fact" href={this.props.profile.website.startsWith('http://') || this.props.profile.website.startsWith('https://') ? this.props.profile.website : `//${this.props.profile.website}`}>
                                        <p><i className="fas fa-link me-2"></i>{this.props.profile.website}</p>
                                    </a>
                                    : <></>}
                                    <p className="text-blusteel ms-4 sample-profile-fact"><i className="far fa-calendar-alt me-2"></i>Joined {h.getNiceDate(this.props.profile.creationDate)}</p>
                                </div>
                            </div>
                        </MDBContainer>
                        <div className="d-flex justify-content-between sample-profile-profile-container">
                            <div className="d-flex flex-column align-self-stretch min-w-20">
                                <Link to={`/${this.props.profile.username}`} onClick={e => this.route(e, `/${this.props.profile.username}`)}>
                                    <h5 className="mb-0 text-default">{this.props.profile.displayName}</h5>
                                </Link>
                                <Link to={`/${this.props.profile.username}`} onClick={e => this.route(e, `/${this.props.profile.username}`)}>
                                    <p className="text-blusteel mb-1">@{this.props.profile.username}</p>
                                </Link>
                                <p>{this.props.profile.email}</p>
                                {/* <div className="d-flex flex-column justify-content-end flex-grow-1">
                                    <div className="d-flex">
                                        <p className="me-2 mb-0"><span className="fw-bold">{h.numberWithCommas(this.props.profile.followers)}</span> <span className="text-blusteel">Follower{this.props.profile.followers === 1 ? '' : 's'}</span></p>
                                        <p className="mb-0"><span className="fw-bold">{h.numberWithCommas(this.props.profile.following)}</span> <span className="text-blusteel">Following</span></p>
                                    </div>
                                </div> */}
                            </div>
                            {this.props.profile.bio && h.checkHTMLLength(this.props.profile.bio) ? 
                            <div className="flex-grow-1 align-self-stretch bg-emphasis mt-4 mx-2 p-2 rounded-6 text-break min-w-50">
                                <div
                                    dangerouslySetInnerHTML={{__html: this.props.profile.bio}}
                                    onClick={this.clickBio}
                                ></div>
                            </div> : <></>}
                            <div>
                                <Link to={`/${this.props.profile.username}`} onClick={e => this.route(e, `/${this.props.profile.username}`)}>
                                    <h5 className={`mb-0 text-pkmn text-end mt-3 text-${this.checkSpecialProfile() ? 'gold' : 'default'}`}>#{this.props.profile.user_id}</h5>
                                </Link>
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route })(ProfileInfo);