import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { route, select_instance } from '../redux/actions';

/**
 * User hits this page if coming from an external link (/instance/:instance)
 * If user is logged in, select the instance and route to the dashboard
 * Else, route to login page
 */

class InstanceRedirect extends React.Component{
    constructor(){
        super();
    }

    componentDidMount(){
        if (this.props.socket){
            if (!this.props.userInfo._id) this.props.route('/login');
            else this.props.select_instance(this.props.match.params.instance);
        }
    }

    componentDidUpdate(prevProps){
        if (!prevProps.socket && this.props.socket){
            if (!this.props.userInfo._id) this.props.route('/login');
            else this.props.select_instance(this.props.match.params.instance);
        }
        if (prevProps.dashboardItemSelected !== 'instances' && this.props.dashboardItemSelected === 'instances') this.props.route('/dashboard')
    }

    render(){
        return <></>
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { route, select_instance })(withRouter(InstanceRedirect));