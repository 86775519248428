import React from 'react';
import {
    MDBNavbarItem,
    MDBNavbarLink,
    MDBRipple
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

/**
 * 
 * @param {Function} nav - The navigation function
 * @param {Object} userInfo - Users document
 * @param {Object} history - react-router history object
 * @returns Menu items only present on mobile screens
 */
const MobileOnlyNavItems = ({ nav, userInfo, history }) => (
    <>
        <span className="nav-mobile mx-auto">
            <MDBNavbarItem className="nav-item nav-items-collapse py-0 cursor-default">
                <div className="d-flex">
                    <img className="nav-avatar" src={`${process.env.REACT_APP_BUCKET_HOST}/thumbnails/${userInfo.avatar.thumbnail}`}></img> 
                    <p className="text-default text-center mb-0 ms-1">{userInfo.username}</p>
                </div>
                <hr className="mb-0"></hr>
            </MDBNavbarItem>
        </span>
        <span className="nav-mobile mx-auto">
            <MDBRipple rippleColor="dark">
                <Link onClick={e => nav(e, '/dashboard')} to="/dashboard">
                    <MDBNavbarItem className="nav-item nav-items-collapse rounded-4">
                        <MDBNavbarLink id="nav-dashboard" className={`nav-link text-default  nav-main-items ${'dashboard' === history.location.pathname.split('/')[1] ? 'active' : ''}`}>
                            Dashboard
                        </MDBNavbarLink>
                    </MDBNavbarItem>
                </Link>
                
            </MDBRipple>
        </span>
    </>
);

export default MobileOnlyNavItems;