import React from 'react';
import { connect } from 'react-redux';
import {
    MDBNavbarItem,
    MDBNavbarLink,
    MDBRipple
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

/**
 * 
 * @param {Function} nav - The navigation function
 * @param {Object} history - The history object
 * @returns The login button on mobile screens
 */
const MobileLogin = ({ nav, history }) => (
    <MDBRipple tag="span" rippleColor="dark" className="nav-mobile">
        <Link to="/login" onClick={e => nav(e, '/login')}>
            <MDBNavbarItem className="nav-item nav-items-collapse">
                <MDBNavbarLink id="nav-login" className={`nav-link text-primary nav-main-items ${'login' === history.location.pathname.split('/')[1] ? 'active' : ''}`}>Login<i className="fas fa-sign-in-alt ms-2"></i></MDBNavbarLink>
            </MDBNavbarItem>
        </Link>
    </MDBRipple>
);


const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, {})(MobileLogin);