import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { connect } from 'react-redux';

class LiveNav extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        const instance = this.props.userInfo.instances.find(i => i.id === 'jizzer-beta');
        const messages = this.props.contactFormMessages.filter(m => m.instanceID === instance.uuid && !m.read);
        return (
            <Tabs className="w-max-content" orientation="vertical" value={this.props.view} onChange={this.props.setView}>
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i className="fas fa-history text-indigo"></i>} 
                    iconPosition="start" 
                    label="History" 
                    value="history"
                    id="tab-history"
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i className={`fa${messages.length ? 's' : 'r'} fa-envelope`} style={{color: "rgb(255, 171, 0)"}}></i>} 
                    iconPosition="start" 
                    label={`Messages (${messages.length})`} 
                    value="messages" 
                    id="tab-messages"
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i className="fas fa-list text-success"></i>} 
                    iconPosition="start" 
                    label="Preferences" 
                    value="preferences" 
                    id="tab-preferences"
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i className="fas fa-exclamation-triangle text-danger"></i>} 
                    iconPosition="start" 
                    label="Actions" 
                    value="actions" 
                    id="tab-actions"
                />
            </Tabs>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(LiveNav);