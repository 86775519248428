import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

/**
 * name-icons
 * verbiage
 * colors
 * content-security
 */

class PreferenceNav extends React.Component{
    constructor(){
        super();
    }

    render(){
        return (
            <Tabs value={this.props.formSelected} onChange={this.props.selectForm}>
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i style={{color: 'var(--mdb-success)'}} className="fas fa-globe-americas"></i>} 
                    iconPosition="start" 
                    label="Name and Icons"
                    value="name-icons"
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i style={{color: '#FFAB00'}} className="fas fa-quote-right"></i>} 
                    iconPosition="start" 
                    label="Verbiage" 
                    value="verbiage" 
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i style={{color: 'var(--mdb-indigo)'}} className="fas fa-palette"></i>} 
                    iconPosition="start" 
                    label="Colors" 
                    value="colors" 
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i style={{color: 'var(--mdb-danger)'}} className="fas fa-lock"></i>} 
                    iconPosition="start" 
                    label="Content and Security" 
                    value="content-security" 
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i style={{color: '#795548'}} className="fas fa-clipboard-list"></i>} 
                    iconPosition="start" 
                    label="Rules" 
                    value="rules" 
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    icon={<i style={{color: 'var(--mdb-info)'}} className="fas fa-code"></i>} 
                    iconPosition="start" 
                    label="Custom CSS" 
                    value="custom-css" 
                />
            </Tabs>
        );
    }
}

export default PreferenceNav;