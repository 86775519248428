import React from 'react';
import { connect } from 'react-redux';
import t from '../../utilities/transitions';
import { motion } from 'framer-motion';
import {
    MDBBtn,
    MDBContainer,
    MDBValidation,
    MDBValidationItem,
    MDBTextArea
} from 'mdb-react-ui-kit';
import { request_access_jizzer_schema } from '../../utilities/validations';
import JizzerBetaModal from './requestAccessJizzerBeta/JizzerBetaModal';

class RequestAccessJizzerBeta extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * modalShown: Boolean - Whether the Request Access modal is shown
             * inputs: Array - The input data (values, errors, etc) 
             */
            modalShown: false,
            inputs: [
                {
                    id: 'about',
                    invalid: true,
                    error: '',
                    value: ''
                },
                {
                    id: 'plan',
                    invalid: true,
                    error: '',
                    value: ''
                }
            ]
        }
    }

    // Run blank change handler
    componentDidMount(){
        this.changeHandler({
            target: {
                name: ''
            }
        });
    }

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => this.setState({
        ...this.state,
        inputs: this.state.inputs.map(input => {
            if (input.id === e.target.name) return {
                ...input,
                value: e.target.value
            }
            else return input
        })
    }, () => {
        const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value.trim()]));
        try {
            request_access_jizzer_schema.validateSync(data, {
                abortEarly: false
            });
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    document.getElementById(input.id).setCustomValidity('');
                    return {
                        ...input,
                        invalid: false,
                        error: ''
                    }
                })
            });
        } catch(err){
            let errorsAdded = [];
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                        errorsAdded.push(input.id);
                        return {
                            ...input,
                            invalid: true,
                            error: err.inner.find(error => error.path === input.id).message
                        }
                    } 
                    else return {
                        ...input,
                        invalid: false,
                        error: ''
                    };
                })
            }, () => this.state.inputs.forEach(input => {
                if (input.invalid) document.getElementById(input.id).setCustomValidity('hello');
                else document.getElementById(input.id).setCustomValidity('');
            }));
        }
    });

    /**
     * Submit only if there isn't already a submission being sent
     * Set working
     * Validate inputs
     * Make request to server
     * Set user to newly created account
     */
    submit = () => {
        document.getElementById('request-access-jizzer').classList.add('was-validated');
        let invalidInputs = this.state.inputs.filter(input => input.invalid);
        invalidInputs.forEach(input => document.getElementById(input.id).setCustomValidity('hello'));
        if (!this.state.working && !invalidInputs.length){
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value.trim()]));
            try {
                request_access_jizzer_schema.validateSync(data, {
                    abortEarly: false
                });
                this.toggleShowModal();                
            } catch(err){
                console.log(err);
                alert('An error occurred. Please try again later');
            }
        } 
    }

    setShowModal = option => this.setState({
        ...this.state,
        modalShown: option
    });

    toggleShowModal = () => this.setState({
        ...this.state,
        modalShown: !this.state.modalShown
    });

    render(){
        return (
            <motion.div className="pt-4" transition={t.transition} initial={t.fade_out_right} animate={t.normalize} exit={t.fade_out_right}>
                <MDBContainer>
                    <JizzerBetaModal
                        modalShown={this.state.modalShown}
                        setShowModal={this.setShowModal}
                        toggleShowModal={this.toggleShowModal}
                        inputs={this.state.inputs}
                    />
                    <MDBBtn onClick={() => this.props.changePage('list')} color="link" rippleColor="primary">
                        <i className="fas fa-chevron-left me-2"></i>
                        Products
                    </MDBBtn>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="square-6 me-2">
                            <div className="fit-images" style={{backgroundImage: 'url("/assets/images/jizzer-logo.png")'}}></div>
                        </div>
                        <h5 className="display-6 ms-2 mb-0">Pigger - Beta</h5>
                    </div>
                    <hr/>
                    <MDBValidation name="request-access-jizzer" method="dialog" id="request-access-jizzer" onSubmit={this.submit}>
                        <div className="row">
                            <MDBValidationItem className="col-12 col-lg-6 mt-2" invalid={true} feedback={this.state.inputs.find(input => input.id === 'about').error}>
                                <MDBTextArea
                                    name='about'
                                    onChange={this.changeHandler}
                                    id='about'
                                    label='Briefly Tell us About Yourself'
                                    size="lg"
                                    className={!this.state.inputs.find(input => input.id === 'about').invalid ? 'mb-0' : 0}
                                    onKeyDown={this.pressTab}
                                />
                            </MDBValidationItem>
                            <MDBValidationItem className="col-12 col-lg-6 mt-2" invalid={true} feedback={this.state.inputs.find(input => input.id === 'plan').error}>
                                <MDBTextArea
                                    name='plan'
                                    onChange={this.changeHandler}
                                    id='plan'
                                    label="What's Your Plan for your Pigger Instance?"
                                    size="lg"
                                    className={!this.state.inputs.find(input => input.id === 'plan').invalid ? 'mb-0' : 0}
                                    onKeyDown={this.pressTab}
                                />
                            </MDBValidationItem>
                        </div>
                    </MDBValidation>
                    <MDBBtn onClick={this.submit} size="lg" color="primary" className="mt-4 ms-auto d-block">
                        <i className="fas fa-clipboard-list me-2"></i>
                        Read and Agree to Terms
                    </MDBBtn>
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {  })(RequestAccessJizzerBeta);