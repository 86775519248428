import React from 'react';
import { motion } from 'framer-motion';
import t from '../../../../../utilities/transitions';
import { connect } from 'react-redux';
import {
    MDBValidation,
    MDBValidationItem,
    MDBTextArea,
    MDBBtn
} from 'mdb-react-ui-kit';
import Spinner from '../../../../../components/Spinner';

class CustomCSS extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    // Run empty change handler
    componentDidMount(){
        this.props.changeHandler({
            target: {
                value: ''
            }
        });
    }

    render(){
        return (
            <motion.div transition={t.transition} initial={this.props.tabEnter} animate={t.normalize} exit={this.props.tabExit}>
                <MDBValidation id="form" className={`mt-4 mx-0 row ${this.props.submitted || this.props.tempSubmitted ? 'was-validated' : ''}`}>
                    <MDBValidationItem className="pb-4 col-12 col-xl-4" feedback={this.props.inputs.find(input => input.id === 'custom_css').error} invalid={true} >
                        <MDBTextArea
                            name="custom_css"
                            onChange={this.props.changeHandler}
                            id="custom_css"
                            label="All Themes"
                            size="lg"
                            className={!this.props.inputs.find(input => input.id === "custom_css").invalid ? 'mb-0' : 0}
                            onKeyDown={this.props.pressTab}
                            value={this.props.inputs.find(input => input.id === "custom_css").value}
                            rows={12}
                        />
                    </MDBValidationItem>
                    <MDBValidationItem className="pb-4 col-12 col-xl-4" feedback={this.props.inputs.find(input => input.id === 'custom_css').error} invalid={true} >
                        <MDBTextArea
                            name="custom_css_default"
                            onChange={this.props.changeHandler}
                            id="custom_css_default"
                            label="Default Theme"
                            size="lg"
                            className={!this.props.inputs.find(input => input.id === "custom_css_default").invalid ? 'mb-0' : 0}
                            onKeyDown={this.props.pressTab}
                            value={this.props.inputs.find(input => input.id === "custom_css_default").value}
                            rows={12}
                        />
                    </MDBValidationItem>
                    <MDBValidationItem className="pb-4 col-12 col-xl-4" feedback={this.props.inputs.find(input => input.id === 'custom_css').error} invalid={true} >
                        <MDBTextArea
                            name="custom_css_dark"
                            onChange={this.props.changeHandler}
                            id="custom_css_dark"
                            label="Dark Theme"
                            size="lg"
                            className={!this.props.inputs.find(input => input.id === "custom_css_dark").invalid ? 'mb-0' : 0}
                            onKeyDown={this.props.pressTab}
                            value={this.props.inputs.find(input => input.id === "custom_css_dark").value}
                            rows={12}
                        />
                    </MDBValidationItem>
                </MDBValidation>
                <MDBBtn 
                    className="d-block ms-auto mt-4"
                    disabled={this.props.submitting}
                    color="success"
                    onClick={this.props.submit}
                >
                    {this.props.submitting ?
                    <>
                        <Spinner className="me-2" size="sm" />
                        {this.props.preferenceStatus}
                    </> :
                    <>
                        <i className="fas fa-paper-plane me-2"></i>
                        {this.props.update ? 'Update Preferences' : 'Submit'}
                    </>}
                </MDBBtn>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(CustomCSS);