import React from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../../../../../utilities/transitions';

class LiveStatus extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        return (
            <MDBCard className="h-100 d-flex flex-column">
                <MDBCardHeader>
                    <h5 className="text-center m-0">Status</h5>
                </MDBCardHeader>
                <MDBCardBody className="fg-1 dashboard-containers">
                    <MDBListGroup light>
                    {(() => {
                        switch(this.props.serverStatus.status){
                            case 'validating-domain':
                                return (
                                    <MDBListGroupItem>
                                        <motion.p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0">Validating domain...</motion.p>
                                    </MDBListGroupItem>
                                );
                            case 'generating-ssl':
                                return (
                                    <>
                                        <MDBListGroupItem>
                                            <p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-success">Domain Validated</p>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <motion.p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0">Generating SSL Certificates and Server Blocks...</motion.p>
                                        </MDBListGroupItem>
                                    </>
                                );
                            case 'creating-database':
                                return (
                                    <>
                                        <MDBListGroupItem>
                                            <p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-success">Domain Validated</p>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-success">SSL Certificates and Server Blocks Generated</p>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <motion.p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0">Creating Database and Search Indexes...</motion.p>
                                        </MDBListGroupItem>
                                    </>
                                );
                            case 'awaiting-preferences':
                                return (
                                    <>
                                        <MDBListGroupItem>
                                            <p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-success">Domain Validated</p>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-success">SSL Certificates and Server Blocks Generated</p>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-success">Database and Search Indexes Created</p>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <motion.p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0">Awaiting Preferences...</motion.p>
                                        </MDBListGroupItem>
                                    </>
                                );
                            case 'building-app':
                                return (
                                    <>
                                        <MDBListGroupItem>
                                            <p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-success">Domain Validated</p>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-success">SSL Certificates and Server Blocks Generated</p>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-success">Database and Search Indexes Created</p>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-success">Preferences Received</p>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <motion.p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0">Building App...</motion.p>
                                        </MDBListGroupItem>
                                    </>
                                );
                            default:
                                console.log('oob server status', this.props.serverStatus.status);
                                return <></>
                        }
                    })()}
                    </MDBListGroup>
                </MDBCardBody>
            </MDBCard>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(LiveStatus);