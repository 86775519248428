import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { connect } from 'react-redux';
import t from '../../../../../utilities/transitions';
import { StaticRouter, Switch, Route } from 'react-router-dom';
import {
    MDBContainer
} from 'mdb-react-ui-kit';
import LiveView from './live/LiveView';
import LiveNav from './live/LiveNav';
import Spinner from '../../../../../components/Spinner';

class Live extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * view: String - The tab that the user is currently on
             */
            view: 'history'
        }
    }

    /**
     * 
     * @param {String} view - The Live view
     * 
     * Sets the view to the one that the user selects
     */
    setView = (e, view) => this.setState({
        ...this.state,
        view: view
    });

    /**
     * 
     * @returns JSX - Indicator of the current instance status
     */
    getServerStatus = () => {
        switch(this.props.serverStatus.status){
            case 'live':
                return <motion.h5 transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-center text-success">App is Live</motion.h5>
            case 'disabled':
                return <motion.h5 transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-center text-danger">Instance Disabled</motion.h5>
            case 'update-queued':
                return <motion.p transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="m-0 text-center text-primary h5"><Spinner className="me-2" color="primary" size="sm"/>Applying Updates</motion.p>
            default:
                console.log('oob status', this.props.serverStatus.status);
                return <></>
        }
    }

    render(){
        return (
            <motion.div className="h-100" transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out}>
                <MDBContainer className="h-100" fluid>
                    <div className="row column-reverse-small h-100">
                        <div className="col-12 col-md-9 h-100">
                            <StaticRouter location={this.state.view}>
                                <AnimatePresence exitBeforeEnter>
                                    <Switch key={this.state.view}>
                                        <Route exact path=":view">
                                            <LiveView 
                                                view={this.state.view}
                                                notify={this.props.notify}
                                            />
                                        </Route>
                                    </Switch>
                                </AnimatePresence>
                            </StaticRouter>
                        </div>
                        <hr className="hr-live" />
                        <div className="col-12 col-sm-3">
                            {this.getServerStatus()}
                            <hr />
                            <div className="d-flex justify-content-center">
                                <LiveNav
                                    view={this.state.view}
                                    setView={this.setView}
                                />
                            </div>
                        </div>
                    </div>
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {  })(Live);