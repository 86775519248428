import React from 'react';
import { motion } from 'framer-motion';
import { connect} from 'react-redux';
import t from '../../utilities/transitions';
import { route, set_temp_action, select_instance } from '../../redux/actions';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardFooter,
    MDBContainer,
    MDBBtn
} from 'mdb-react-ui-kit';

class List extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * exit: Object - framer-motion initial/exit animation
             */
            exit: this.getExit(props.productHistoryStack)
        }
    }

    /**
     * If user is coming from the Request Access page, fade in from the left
     * Otherwise, fade in
     */
    getExit = historyStack => {
        
        const h = historyStack;
        if (h[h.length - 1] === 'request-access-jizzer-beta') return t.fade_out_left;
        else return t.fade_out;
    }

    /**
     * If user is logged in, route to the "Request Access" screen
     * Otherwise, set temp action and display login modal
     */
    requestAccess = () => {
        if (this.props.userInfo._id) this.setState({
            ...this.state,
            exit: t.fade_out_left
        }, () => this.props.changePage('request-access-jizzer-beta'));
        else {
            this.props.set_temp_action({
                action: 'request-access',
                product: 'jizzer-beta',
                label: 'Request Access'
            });
            this.props.toggleLoginModal();
        }
    }

    render(){
        return (
            <motion.div transition={t.transition} initial={this.state.exit} animate={t.normalize} exit={this.state.exit}>
                <MDBContainer>
                    <div className="col-12 col-md-6 col-xl-4 mt-4">
                        <MDBCard>
                            <MDBCardHeader>
                                <h5 className="m-0">Pigger - Beta</h5>
                            </MDBCardHeader>
                            <MDBCardBody className="d-flex align-items-start">
                                <div className="h-max-content">
                                    <div className="square-6">
                                        <div className="fit-images" style={{backgroundImage: 'url("/assets/images/jizzer-logo.png")'}}></div>
                                    </div>
                                </div>
                                <div>
                                    <p className="m-0">Fully featured Twitter alternative that includes live streaming, searching, image/audio/video uploads, instantly updating likes/poll results/replies/feed, and much more. This is the beta version.</p>
                                </div>
                            </MDBCardBody>
                            <MDBCardFooter className="d-flex justify-content-between">
                                <MDBBtn onClick={() => this.props.route('/info', '#jizzer')} className="text-darkblu" color="link" rippleColor="#1976d2">
                                    <i className="fas fa-info-circle me-2"></i>
                                    Info
                                </MDBBtn>
                                {this.props.userInfo.instances && this.props.userInfo.instances.find(i => i.id === 'jizzer-beta') ?
                                <MDBBtn onClick={() => this.props.select_instance('jizzer-beta')} color="link" rippleColor="primary">
                                    View Details
                                    <i className="fas fa-chevron-right ms-2"></i>
                                </MDBBtn> :
                                <MDBBtn onClick={this.requestAccess} color="link" rippleColor="primary">
                                    Request Access
                                    <i className="fas fa-chevron-right ms-2"></i>
                                </MDBBtn>}
                            </MDBCardFooter>
                        </MDBCard>
                    </div>
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { route, set_temp_action, select_instance })(List);