import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBListGroup,
    MDBListGroupItem,
    MDBRipple
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import h from '../../../../../utilities/helpers';
import { parse } from 'node-html-parser';

class RuleSelectModal extends React.Component{
    constructor(){
        super();
        this.state = {
        }
    }

    // Hide modal if new rule is selected
    componentDidUpdate(prevProps){
        if (prevProps.ruleSelected !== this.props.ruleSelected && this.props.modalShown) this.props.toggleShowModal();
    }

    render(){
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal animationDirection='top' show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="fullscreen">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Custom Rules</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBListGroup>
                                    {this.props.rules.map(rule => (
                                        <MDBRipple className="cursor-pointer" onClick={() => this.props.selectRule(rule.id)} key={rule.id}>
                                            <MDBListGroupItem action active={this.props.ruleSelected === rule.id}>
                                                <h6>{rule.title ? rule.title : '(No title)'}</h6>
                                                <p className="m-0">{h.shortString(parse(rule.html).textContent)}</p>
                                            </MDBListGroupItem>
                                        </MDBRipple>
                                    ))}
                                </MDBListGroup>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {  })(RuleSelectModal);