import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import h from '../../../../../../utilities/helpers';
import t from '../../../../../../utilities/transitions';
import {
    MDBListGroup,
    MDBListGroupItem,
    MDBRipple
} from 'mdb-react-ui-kit';
import MessageModal from './MessageModal';

class Messages extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * messageSelected: false | Object - Messages object - The message selected
             * modalShown: Boolean - Whether the message modal is shown
             */
            messageSelected: false,
            modalShown: false
        }
    }

    setShowModal = option => this.setState({
        ...this.state,
        modalShown: option
    });

    toggleShowModal = () => this.setState({
        ...this.state,
        modalShown: !this.state.modalShown
    });

    /**
     * 
     * @param {Object} message - Messages object
     * 
     * Triggered when the user clicks on a message
     * Selects that message and displays the Message modal
     */
    selectMessage = message => this.setState({
        ...this.state,
        messageSelected: message
    }, this.toggleShowModal);

    render(){
        const instance = this.props.userInfo.instances.find(i => i.id === 'jizzer-beta');
        const messages = this.props.contactFormMessages.filter(m => m.instanceID === instance.uuid).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        return (
            <motion.div transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out}>
                <MessageModal 
                    modalShown={this.state.modalShown} 
                    setShowModal={this.setShowModal} 
                    toggleShowModal={this.toggleShowModal} 
                    message={this.state.messageSelected} 
                />
                <MDBListGroup>
                    {messages.length ? messages.map(message => (
                        <MDBRipple 
                            className="cursor-pointer" 
                            onClick={() => this.selectMessage(message)} 
                            key={message._id}
                            rippleColor="primary"
                        >
                            <MDBListGroupItem action>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h5 className="m-0">{!message.read ? <i className="fas fa-circle me-2 text-success" /> : <></>}{message.name}</h5>
                                        <p>{message.email}</p>
                                        <p className="m-0"><span className="fw-bold">Subject: </span>{message.subject}</p>
                                    </div>
                                    <div>
                                        <p className="text-end m-0 text-blusteel">{h.makeDateHR(new Date(message.timestamp))}</p>
                                        <p className="text-end m-0 text-blusteel">{h.getTimeHR(new Date(message.timestamp))}</p>
                                    </div>
                                </div>
                                <p className="m-0">{h.shortString(message.message)}</p>
                            </MDBListGroupItem>
                        </MDBRipple>
                    )) : <h5 className="text-center display-6">You have no messages</h5>}
                </MDBListGroup>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(Messages);