import React from 'react';
import { motion } from 'framer-motion';
import t from '../../../../../utilities/transitions';
import { connect } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {
    MDBContainer,
    MDBValidation,
    MDBValidationItem,
    MDBInput,
    MDBBtn,
    MDBRipple
} from 'mdb-react-ui-kit';
import BioField from '../../settings/BioField';

class ContentSecurity extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    // Run empty change handler
    componentDidMount(){
        this.props.setForceParse(this.forceParse);
        this.props.changeHandler({
            target: {
                value: ''
            }
        });
    }

    render(){
        return (
            <motion.div transition={t.transition} initial={this.props.tabEnter} animate={t.normalize} exit={this.props.tabExit}>
                <MDBContainer fluid className="px-0">
                    <MDBValidation id="form" className={`py-4 mx-0 row ${this.props.submitted || this.props.tempSubmitted ? 'was-validated' : ''}`}>
                        {this.props.inputs.filter(i => i.tab === 'content-security' && !i.id.includes('gigachad')).map(i => (
                            <MDBValidationItem className={`pb-4 col-12 col-lg-3`} feedback={i.error} invalid={true} >
                                <MDBInput
                                    name={i.id}
                                    onChange={this.props.changeHandler}
                                    id={i.id}
                                    label={i.id === 'max_emission_chars' ? `Max ${this.props.inputs.find(i => i.id === 'emission_name').value} Characters` : i.label}
                                    size="lg"
                                    className={!i.invalid ? 'mb-0' : 0}
                                    onKeyDown={this.props.pressTab}
                                    value={i.value}
                                    type="number"
                                    key={i.id}
                                    min="1"
                                />
                            </MDBValidationItem>
                        ))}
                        <div className="col-12 col-xl-4">
                            <FormGroup>
                                <FormControlLabel className="w-max-content" control={<Switch onChange={this.props.switchChange} name="accountApprovalRequired" id="accountApprovalRequired" checked={this.props.accountApprovalRequired} />} label="Account Approval Required" />
                                <FormControlLabel className="w-max-content" control={<Switch onChange={this.props.switchChange} checked={this.props.emailVerificationRequired} name="emailVerificationRequired" id="emailVerificationRequired" />} label="Email Verification Required" />
                            </FormGroup>
                        </div>
                        <div className="col-12 col-xl-4">
                            <FormGroup>
                                <FormControlLabel className="w-max-content" control={<Switch onChange={this.props.switchChange} checked={this.props.liveStreamingAllowed} name="liveStreamingAllowed" id="liveStreamingAllowed" />} label="Live Streaming Enabled" />
                                <FormControlLabel className="w-max-content" control={<Switch onChange={this.props.switchChange} checked={this.props.imagesAllowed} name="imagesAllowed" id="imagesAllowed" />} label="Image Posts Enabled" />
                            </FormGroup>
                        </div>
                        <div className="col-12 col-xl-4">
                            <FormGroup>
                                <FormControlLabel className="w-max-content" control={<Switch onChange={this.props.switchChange} checked={this.props.audioAllowed} name="audioAllowed" id="audioAllowed" />} label="Audio Posts Enabled" />
                                <FormControlLabel className="w-max-content" control={<Switch onChange={this.props.switchChange} checked={this.props.videoAllowed} name="videoAllowed" id="videoAllowed" />} label="Video Posts Enabled" />
                            </FormGroup>
                        </div>
                        <hr />
                        {this.props.update ? <></> : 
                        <>
                            <h5 className="text-center mb-4">Create the Gigachad User</h5>
                            {this.props.inputs.filter(i => i.tab === 'content-security' && i.id.split('gigachad').length > 1).map(i => (
                                <MDBValidationItem className="pb-4 col-12 col-lg-4" feedback={i.error} invalid={true} >
                                    <MDBInput
                                        name={i.id}
                                        onChange={this.props.changeHandler}
                                        id={i.id}
                                        label={i.label}
                                        size="lg"
                                        className={!i.invalid ? 'mb-0' : 0}
                                        onKeyDown={this.props.pressTab}
                                        value={i.value}
                                        key={i.id}
                                        type={i.id.split('password').length > 1 ? 'password' : 'text'}
                                    />
                                </MDBValidationItem>
                            ))}
                            <div className="col-lg-8 col-12">
                                {this.props.dashboardItemSelected === 'instances' ?
                                <BioField  
                                    setForceParse={f => this.forceParse = f} 
                                    working={this.props.submitting} 
                                    userInfo={{
                                        bio: this.props.bio 
                                    }}
                                /> : <></>}
                            </div>
                            <div className="col-12 col-lg-3 d-flex flex-column mb-4">
                                <p style={{fontSize: '1.5rem'}} className="text-center display-6 mb-1">Avatar</p>
                                <div className="d-flex justify-content-center align-items-center fg-1 dashboard-containers">
                                    <div className="d-flex justify-content-center align-items-center square-10 mx-auto">
                                        <MDBRipple onClick={() => this.props.selectFile('gigachadAvatar')} tag="div" rippleColor="primary" className="fit-images" style={{backgroundImage: `url("${this.props.files.gigachadAvatar}")`, cursor: 'pointer'}}></MDBRipple>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mb-4">
                                <p style={{fontSize: '1.5rem'}} className="text-center display-6 mb-1">Background</p>
                                <div style={{height: '170px'}} className="d-flex justify-content-center align-items-center w-100 mx-auto">
                                    <MDBRipple onClick={() => this.props.selectFile('gigachadBackground')} tag="div" rippleColor="light" className="fit-background cursor-pointer" style={{backgroundImage: `url("${this.props.files.gigachadBackground}")`}}></MDBRipple>
                                </div>
                            </div>
                        </>}
                        <div className={`col-12 col-lg-3 d-flex justify-content-end align-items-center ${this.props.update ? 'ms-auto' : ''}`}>
                            <MDBBtn 
                                color="primary" 
                                onClick={this.props.next}
                                type="button"
                            >Rules<i className="fas fa-chevron-right ms-2"></i></MDBBtn>
                        </div>
                    </MDBValidation>
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(ContentSecurity);