import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../../../../../../utilities/transitions';
import h from '../../../../../../utilities/helpers';
import {
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';

class History extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        const listItems = [
            ...this.props.userInfo.instances.find(i => i.id === 'jizzer-beta').actions.filter(a => ['set-preferences', 'launched', 'updated'].indexOf(a.action) === -1),
            ...this.props.serverStatus.actions.filter(a => ['domain-submitted', 'preferences-submitted', 'launched', 'version-update', 'disable', 'restore'].indexOf(a.action) > -1)
        ].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        return (
            <motion.div transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out}>
                <MDBListGroup light>
                    {listItems.map(item => (
                        <MDBListGroupItem className="d-flex justify-content-between" key={item.timestamp}>
                            <h5 className="m-0">{h.getActionName(item.action, 'jizzer-beta')}</h5>
                            <div>
                                <p className="m-0 text-blusteel text-end">{h.makeDateHR(item.timestamp)}</p>
                                <p className="m-0 text-blusteel text-end">{h.getTimeHR(item.timestamp)}</p>
                            </div>
                        </MDBListGroupItem>
                    ))}
                </MDBListGroup>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(History);