import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

class MessageNav extends React.Component{
    constructor(){
        super();
    }
    
    render(){
        return (
            <Tabs value={this.props.tab} onChange={this.props.selectTab}>
                <Tab 
                    style={{minHeight: '48px'}} 
                    label="General Feedback"
                    value="general"
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    label="Bug Reports" 
                    value="bug" 
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    label="Requests" 
                    value="request" 
                />
                <Tab 
                    style={{minHeight: '48px'}} 
                    label="Abuse Reports" 
                    value="report" 
                />
            </Tabs>
        );
    }
}

export default MessageNav;