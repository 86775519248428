import React from 'react';
import Login from './Login';
import CreateAccount from './CreateAccount';
import { motion } from 'framer-motion';
import t from '../../utilities/transitions';

class Form extends React.Component{
    constructor(props){
        super();
        this.state = {
            submitSet: false
        }
        this.form = props.form;
    }

    componentDidMount(){
        this.submit = this.submit.bind(this);
        this.props.setSubmit(this.submit);
    }

    render(){
        switch(this.form){
            case 'login':
                return (
                    <motion.div className="pt-4" transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                        <Login 
                            googleReCaptchaProps={this.props.googleReCaptchaProps} 
                            setSubmit={click => this.submit = click} 
                            modalShown={this.props.modalShown}
                            setShowModal={this.props.setShowModal}
                            toggleShowModal={this.props.toggleShowModal}
                            setWorking={this.props.setWorking}
                            notify={this.props.notify}
                        />
                    </motion.div>
                    
                );
            case 'create':
                return (
                    <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                        <CreateAccount 
                            googleReCaptchaProps={this.props.googleReCaptchaProps} 
                            setSubmit={click => this.submit = click} 
                            modalShown={this.props.modalShown}
                            setShowModal={this.props.setShowModal}
                            toggleShowModal={this.props.toggleShowModal}
                            setWorking={this.props.setWorking}
                        />
                    </motion.div>
                );
            default:
                console.log('oob form', this.form);
                return <></>
        }
    }
}

export default Form;