import React from 'react';
import { connect } from 'react-redux';
import h from '../../../../../utilities/helpers';
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardFooter,
    MDBBtn
} from 'mdb-react-ui-kit';
import JizzerBetaRequestModal from './JizzerBetaRequestModal';
import JizzerBetaDisableModal from './JizzerBetaDisableModal';

class JizzerBetaCard extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * requestModalShown: Boolean - Whether the Request modal is shown
             * disableModalShown: Boolean - Whether the Disable modal is shown
             */
            requestModalShown: false,
            disableModalShown: false
        }
    }

    toggleDisableModal = () => this.setState({
        ...this.state,
        disableModalShown: !this.state.disableModalShown
    });

    setDisableModal = option => this.setState({
        ...this.state,
        disableModalShown: option
    });

    toggleModal = () => this.setState({
        ...this.state,
        requestModalShown: !this.state.requestModalShown
    });

    setModal = option => this.setState({
        ...this.state,
        requestModalShown: option
    });

    render(){
        const instance = this.props.userInfo.instances.find(i => i.id === 'jizzer-beta');
        return (
            <MDBCard>
                <MDBCardHeader>
                    <h5 className="m-0">Pigger - Beta</h5>
                </MDBCardHeader>
                <MDBCardBody className="d-flex align-items-start">
                    <JizzerBetaRequestModal
                        modalShown={this.state.requestModalShown}
                        toggleShowModal={this.toggleModal}
                        setShowModal={this.setModal}
                    />
                    <JizzerBetaDisableModal
                        modalShown={this.state.disableModalShown}
                        toggleShowModal={this.toggleDisableModal}
                        setShowModal={this.setDisableModal}
                    />
                    <div className="h-max-content">
                        <div className="square-6">
                            <div className="fit-images" style={{backgroundImage: `url(${instance.preferences ? `${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/icons/favicon-96x96.png` :"/assets/images/jizzer-logo.png"})`}}></div>
                        </div>
                    </div>
                    <div className="flex-grow-1 w-0">
                        {(() => {
                            const statistics = this.props.statistics.find(s => s.id === 'jizzer-beta');
                            const messages = this.props.contactFormMessages.filter(m => m.instanceID === instance.uuid && !m.read);
                            switch(instance.status){
                                case 'requested': 
                                    return (
                                        <>
                                            <h5 className="m-0 text-end">Awaiting Approval</h5>
                                            <p className="m-0 text-blusteel text-end">Requested on {h.makeDateHR(new Date(instance.actions.find(a => a.action === 'request').timestamp))}</p>
                                        </>
                                    );
                                case 'denied':
                                    return (
                                        <>
                                            <h5 className="m-0 text-danger text-end">Request Denied</h5>
                                            <p className="m-0 text-blusteel text-end">Requested on {h.makeDateHR(new Date(instance.actions.find(a => a.action === 'request').timestamp))}</p>
                                        </>
                                    );
                                case 'disabled':
                                    return (
                                        <>
                                            <h5 className="m-0 text-danger text-end">Instance Disabled</h5>
                                            <p className="m-0 text-blusteel text-end">Disabled on {h.makeDateHR(new Date(instance.actions.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).find(a => a.action === 'disable').timestamp))}</p>
                                        </>
                                    );
                                case 'accepted':
                                    const acceptDate = new Date(instance.actions.find(a => a.action === 'accept').timestamp);
                                    const acceptBy = new Date(acceptDate).setDate(new Date(acceptDate).getDate() + 3);
                                    return (
                                        <>
                                            <h5 className="m-0 text-primary text-end">Request Approved</h5>
                                            <p className="m-0 text-blusteel text-end">Activate by {h.makeDateHR(acceptBy)} {h.getTimeHR(acceptBy)}</p>
                                        </>
                                    );
                                case 'live':
                                    const liveDate = new Date(instance.actions.find(a => a.action === 'launched').timestamp);
                                    return (
                                        <>
                                            <h5 className="m-0 text-success text-end">Live Now</h5>
                                            <p className="m-0 text-blusteel text-end">Launched {h.makeDateHR(liveDate)} {h.getTimeHR(liveDate)}</p>
                                            <p className="m-0 text-end"><span className="fw-bold">{statistics.users}</span> <span className="text-blusteel">User{statistics.users === 1 ? '' : 's'}</span></p>
                                            <p className="m-0 text-end"><span className="fw-bold">{statistics.emissions}</span> <span className="text-blusteel">{statistics.emissions === 1 ? instance.preferences.emission_name : instance.preferences.emission_plural}</span></p>
                                            <p className="m-0 text-end"><span className="fw-bold">{messages.length}</span> <span className="text-blusteel">Unread Message{messages.length === 1 ? '' : 's'}</span></p>
                                        </>
                                    );
                                default:
                                    console.log('oob instance status', instance.status);
                            }
                        })()}
                    </div>
                </MDBCardBody>
                <MDBCardFooter>
                    {(() => {
                        switch(instance.status){
                            case 'requested':
                                return (
                                    <div className="d-flex justify-content-end">
                                        <MDBBtn onClick={this.toggleModal} className="text-darkblu" color="link" rippleColor="#1976d2">
                                            <i className="fas fa-eye me-2"></i>
                                            View Request
                                        </MDBBtn>
                                    </div>
                                )
                            case 'denied':
                                return (
                                    <div className="d-flex justify-content-end">
                                        <MDBBtn onClick={this.toggleModal} className="text-darkblu" color="link" rippleColor="#1976d2">
                                            <i className="fas fa-eye me-2"></i>
                                            View Request
                                        </MDBBtn>
                                    </div>
                                )
                            case 'disabled':
                                return (
                                    <div className="d-flex justify-content-end">
                                        <MDBBtn onClick={this.toggleDisableModal} className="text-darkblu" color="link" rippleColor="#1976d2">
                                            <i className="fas fa-eye me-2"></i>
                                            View Details
                                        </MDBBtn>
                                    </div>
                                )
                            case 'accepted':
                                return (
                                    <div className="d-flex justify-content-end">
                                        <MDBBtn onClick={() => this.props.setView('jizzer-beta')} color="link" rippleColor="primary">
                                            Set Up
                                            <i className="fas fa-chevron-right ms-2"></i>
                                        </MDBBtn>
                                    </div>
                                );
                            case 'live':
                                return (
                                    <div className="d-flex justify-content-end">
                                        <MDBBtn onClick={() => this.props.setView('jizzer-beta')} color="link" rippleColor="primary">
                                            View Details
                                            <i className="fas fa-chevron-right ms-2"></i>
                                        </MDBBtn>
                                    </div>
                                );
                            default:
                                console.log('oob instance status', instance.status);
                        }
                    })()}
                </MDBCardFooter>
            </MDBCard>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(JizzerBetaCard);