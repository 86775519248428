import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';
import Spinner from '../../../components/Spinner';
import { change_user_details, route } from '../../../redux/actions';
import axios from 'axios';
import { connect } from 'react-redux';

const rules = [
    {
        title: '<h5>Eligibility</h5>',
        html: `<p>To operate a Pigger instance, you must be at least 13 years of age or older, and there must be no US law, or law of any jurisdiction that applies to you, which prevents you from operating a Pigger instance.</p>`
    },
    {
        title: `<h5>You agree to and will enforce <a class="links-generic text-primary text-decoration-none" href="https://pigger.net/info">Pigger's TOS</a> on your instance.</h5>`,
        html: `<p>Your instance must be well-moderated, and content and users that violate the rules should be dealt with promptly. Unmoderated instances or instances where illegal behavior is tolerated can be shut down.</p>`
    },
    {
        title: '<h5>You will not hold Carbon Valley, or any of its owners or associates liable for anything that happens to your instance by anyone.</h5>',
        html: `<p>As this is the beta version, it is possible that there will be vulnerabilities and glitches that may have a negative impact on its users. By using the beta version, you acknowledge these possibilities and agree to refrain from taking action against Carbon Valley or any of its owners or associates because of these.</p>`
    },
    {
        title: `<h5>No rules or policies you apply to your instance may contradict <a class="links-generic text-primary text-decoration-none" href="https://pigger.net/info">Pigger's TOS</a>.</h5>`,
        html: `<p>Your users must abide by Pigger's TOS. If there are any conflicts, Pigger's TOS will take priority</p>`
    },
    {
        title: `<h5>You agree that this is a temporary, free beta release that comes with no guarantees.</h5>`,
        html: `<p>At the expiration of this beta, your instance will be allowed to continue for a short time thereafter, or you may upgrade to a paid version, or you may have your instance shut down, and your data preserved.</p>`
    }
]

class JizzerBetaModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean - Whether a request is in the process of being submitted
             */
            working: false
        }
    }

    // Once the user's request has been received, route to the "Request Received" page
    componentDidUpdate(prevProps){
        if (!prevProps.userInfo.instances.find(i => i.id === 'jizzer-beta') && this.props.userInfo.instances.find(i => i.id === 'jizzer-beta')) this.props.route('/received');
    }

    /**
     * Executes a captcha challenge and generates a key a key
     * Will hang until connected to captcha servers
     */
    getRecaptcha = () => new Promise(async (resolve, reject) => {
        if (this.props.captchaReady) window.grecaptcha.enterprise.execute(process.env.REACT_APP_CAPTCHA_KEY, {action: 'login'}).then(resolve).catch(err => {
            console.log(err);
            alert('Human verification failed. Refresh the page and try again.');
            reject();
        });
        else setTimeout(async () => {
            const captchaKey = await this.getRecaptcha();
            resolve(captchaKey);
        }, 500);
    });

    /**
     * Submit only if there isn't already a submission being sent
     * Set working
     * Validate inputs
     * Get Captcha key
     * Make request to server
     * Update user
     */
    submit = () => {
        if (!this.state.working) this.setState({
            ...this.state,
            working: true
        }, async () => {
            const data = Object.fromEntries(this.props.inputs.map(input => [input.id, input.value.trim()]));
            const captchaKey = await this.getRecaptcha();
            data.captchaKey = captchaKey;
            axios.post('/jizzer/request-beta', data).then(res => {
                this.props.change_user_details(res.data);
                
            }).catch(err => this.setState({
                ...this.state,
                working: false
            }, () => {
                console.log(err);
                alert('An error occurred. Please try again later.');
            }));
        });
        
    }


    render(){
        return (
            <>
              
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} >
                    <MDBModalDialog size="xl">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle><h5 className="m-0">Pigger Beta Terms</h5></MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <h5>Thank you for showing an interest in the Pigger beta. These are the Terms of Use. By clicking "Agree and Submit", you agree to the following:</h5>
                                <hr />
                                <MDBListGroup light>
                                {rules.map((rule, r) => (
                                    <MDBListGroupItem key={rule.html} className={r ? 'mt-3' : ''}>
                                        <div 
                                            dangerouslySetInnerHTML={{__html: rule.title}}
                                        ></div>
                                        <div 
                                            dangerouslySetInnerHTML={{__html: rule.html}}
                                        ></div>
                                    </MDBListGroupItem>
                                ))}
                                </MDBListGroup>
                            </MDBModalBody>
                            <MDBModalFooter >
                                {this.state.working ?
                                <MDBBtn disabled color="success"><Spinner className="me-2" size="sm" />Submitting</MDBBtn> :
                                <MDBBtn onClick={this.submit} color="success"><i className="fas fa-paper-plane me-2"></i>Agree and Submit</MDBBtn>}
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { change_user_details, route })(JizzerBetaModal);