import React from 'react';
import { motion } from 'framer-motion';
import t from '../../../../../utilities/transitions';
import { connect } from 'react-redux';
import {
    MDBValidation,
    MDBValidationItem,
    MDBInput,
    MDBBtn
} from 'mdb-react-ui-kit';

class Verbiage extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    // Run blank change handler
    componentDidMount(){
        this.props.changeHandler({
            target: {
                value: ''
            }
        });
    }

    render(){
        return (
            <motion.div transition={t.transition} initial={this.props.tabEnter} animate={t.normalize} exit={this.props.tabExit}>
                <MDBValidation id="form" className={`mt-4 row ${this.props.submitted || this.props.tempSubmitted ? 'was-validated' : ''}`}>
                    {this.props.inputs.filter(i => i.tab === 'verbiage').map(i => (
                        <MDBValidationItem className="pb-4 col-12 col-lg-4" feedback={i.error} invalid={true} >
                            <MDBInput
                                name={i.id}
                                onChange={this.props.changeHandler}
                                id={i.id}
                                label={i.label}
                                size="lg"
                                className={!i.invalid ? 'mb-0' : 0}
                                onKeyDown={this.props.pressTab}
                                value={i.value}
                                key={i.id}
                            />
                        </MDBValidationItem>
                    ))}
                </MDBValidation>
                <MDBBtn className="d-block ms-auto mt-2" color="primary" onClick={this.props.next}>Colors<i className="fas fa-chevron-right ms-2"></i></MDBBtn>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(Verbiage);