import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import Spinner from '../../../../../components/Spinner';
import axios from 'axios';
import { update_user_list } from '../../../../../redux/actions';


class ConfirmModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean - Whether a decision is currently being processed
             */
            working: false
        }
    }

    /**
     * Triggered when the user click the Confirm button
     * 
     * Sends the current decision to the server
     * Updates the user list
     */
    confirm = () => {
        if (!this.state.working) this.setState({
            ...this.state,
            working: true
        }, () => axios.post('/jizzer/decision-beta', {
            decision: this.props.action.toLowerCase(),
            userID: this.props.user._id
        }).then(res => {
            this.props.update_user_list(res.data.userInfo);
            this.setState({
                ...this.state,
                working: false
            }, this.props.toggleShowModal);
        }).catch(err => this.setState({
            ...this.state,
            working: false
        }, () => {
            console.log(err);
            alert('An error occurred. Please try again later.');
        })));
    }

    render(){
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="xl">
                        {this.props.user ?
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Are you sure?</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <h5 className="display-6 text-center">You are about to {this.props.action.toLowerCase()} {this.props.user.username}</h5>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn
                                    disabled={this.state.working}
                                    color={this.props.action === "Approve" ? "success" : "danger"}
                                    onClick={this.confirm}
                                >
                                    {this.state.working ?
                                    <>
                                        <Spinner size="sm" className="me-2" />
                                        {this.props.action === "Approve" ? "Approving" : "Denying"}
                                    </> :
                                    <>
                                        <i className={`me-2 fas fa-${this.props.action === "Approve" ? "check-circle" : "times-circle"}`}></i>
                                        {this.props.action}
                                    </>}
                                </MDBBtn>
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent> : <></>}
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { update_user_list })(ConfirmModal);