import React from 'react';
import List from './List';
import JizzerBeta from './JizzerBeta';

class InstanceView extends React.Component{
    constructor(props){
        super();
        this.view = props.view;
    }

    render(){
        switch(this.view){
            case 'list':
                return (
                    <List 
                        setView={this.props.setView}
                        loaded={this.props.loaded}
                    />
                );
            case 'jizzer-beta':
                return (
                    <JizzerBeta
                        setView={this.props.setView}
                        notify={this.props.notify}
                    />
                );
            default:
                console.log('oob view', this.view);
        }
    }
}

export default InstanceView;