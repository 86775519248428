import React from 'react';
import { withRouter } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import { set_user, route } from '../redux/actions';
import { connect } from 'react-redux';
import { change_password_uuid_schema } from '../utilities/validations';
import t from '../utilities/transitions';
import h from '../utilities/helpers';
import {
    MDBContainer,
    MDBValidation,
    MDBValidationItem,
    MDBInput,
    MDBBtn
} from 'mdb-react-ui-kit';
import Spinner from '../components/Spinner';
import LinearProgress from '@mui/material/LinearProgress';

/**
 * This is the page that the user hits when they click the password reset link that was emailed to them
 */

const fields = [
    {
        id: 'password1',
        text: 'New Password'
    },
    {
        id: 'password2',
        text: 'Re-enter password'
    }
]

class Resets extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean indicating whether the form is in the process of being submitted
             * inputs: Array - The input data (values, errors, etc)
             * loaded: Boolean - Whether the password reset data has been loaded
             * expired: Boolean - Whether the password reset has expired
             * notFound: Boolean - Whether the password reset request was not found
             */
            working: false,
            inputs: fields.map(field => ({
                id: field.id,
                error: '',
                invalid: true,
                value: ''
            })),
            loaded: false,
            expired: false,
            notFound: false
        }
    }

    componentDidMount(){
        this.load();
    }

    componentDidUpdate(){
        h.floatLabels();
    }

    /**
     * Load password reset data
     * Run blank change handler
     */
    load = () => axios.get('/auth/reset-request/' + this.props.match.params.id).then(res => this.setState({
        ...this.state,
        loaded: true,
        ...res.data
    }, () => {
        if (!this.state.expired && !this.state.notFound) this.changeHandler({
            target: {
                name: ''
            }
        });
    })).catch(err => {
        console.log('load error', err);
        setTimeout(this.load, 1000);
    });

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => this.setState({
        ...this.state,
        inputs: this.state.inputs.map(input => {
            if (input.id === e.target.name) return {
                ...input,
                value: e.target.value
            }
            else return input
        })
    }, () => {
        const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
        try {
            change_password_uuid_schema.validateSync(data, {
                abortEarly: false
            });
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    document.getElementById(input.id).setCustomValidity('');
                    return {
                        ...input,
                        invalid: false,
                        error: ''
                    }
                })
            });
        } catch(err){
            let errorsAdded = [];
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                        errorsAdded.push(input.id);
                        return {
                            ...input,
                            invalid: true,
                            error: err.inner.find(error => error.path === input.id).message
                        }
                    } 
                    else return {
                        ...input,
                        invalid: false,
                        error: ''
                    };
                })
            }, () => this.state.inputs.forEach(input => {
                if (input.invalid) document.getElementById(input.id).setCustomValidity('hello');
                else document.getElementById(input.id).setCustomValidity('');
            }));
        }
    });

    /**
     * Submit only if there isn't already a submission being sent
     * Set working
     * Validate inputs
     * Make request to server
     * Set user in application state
     * Route to user's profile page
     */
    submit = () => {
        document.getElementById('form').classList.add('was-validated');
         let invalidInputs = this.state.inputs.filter(input => input.invalid);
         invalidInputs.forEach(input => document.getElementById(input.id).setCustomValidity('hello'));
         if (!this.state.working && !invalidInputs.length) this.setState({
             ...this.state,
             working: true
         }, () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
            try {
                change_password_uuid_schema.validateSync(data, {
                    abortEarly: false
                });
                const fd = new FormData();
                for ( const key in data ) {
                    fd.append(key, data[key]);
                }
                fd.append('uuid', this.props.match.params.id);

                axios.post('/auth/change-password', fd).then(res => {
                    if (res.data.error) this.setState({
                        ...this.state,
                        working: false
                    }, () => alert(res.data.error));
                    else {
                        this.props.set_user(res.data);
                        this.props.route('/' + res.data.username);
                    }
                }).catch(err => this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err.response);
                    alert('An error occurred. Please try again later');
                }));
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                });
            }
        });
    }

    pressEnter = e => {
        /**
         * Submit the form if the user presses the enter key while in one of the inputs
         */
        if (e.key === 'Enter') document.getElementById('form').requestSubmit();
    }

    render(){
        return (
            <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                <MDBContainer className="mt-5">
                    {!this.state.loaded ?
                    <>
                        <h5 className="mb-4 text-center display-6">Loading</h5>
                        <LinearProgress />
                    </> : 
                    <>
                        {this.state.expired ?
                        <motion.h5 className="display-6 text-center" transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>Reset link has expired</motion.h5> :
                        <>
                            {this.state.notFound ?
                            <motion.h5 className="display-6 text-center" transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>Reset link not found</motion.h5> : 
                            <motion.div transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                                <h1 className="display-6 text-center">Set Password</h1>
                                <hr></hr>
                                <div className="mx-auto mt-2 form-containers">
                                    <MDBValidation method="dialog"  id="form" onSubmit={this.submit}>
                                    {fields.map(i => (
                                        <MDBValidationItem key={i.id} className="pb-4" feedback={this.state.inputs.find(input => input.id === i.id).error} invalid={true} >
                                            <MDBInput
                                                name={i.id}
                                                onChange={this.changeHandler}
                                                id={i.id}
                                                label={i.text}
                                                size="lg"
                                                className={!this.state.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}
                                                type="password"
                                                onKeyPress={this.pressEnter}
                                            />
                                        </MDBValidationItem>
                                    ))}
                                    </MDBValidation>
                                    <div className="d-grid gap-2 mb-4">
                                        {this.state.working ?
                                        <MDBBtn disabled color="primary" size="lg"><Spinner size='sm' className='me-2'/>Saving</MDBBtn> :
                                        <MDBBtn onClick={this.submit} color="primary" size="lg"><i className="fas fa-save me-2"></i>Save Changes</MDBBtn>}
                                    </div>
                                </div>
                            </motion.div>
                            }
                        </>}
                    </>}
                </MDBContainer>
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default withRouter(connect(mapStateToProps, { set_user, route })(Resets));