import React from 'react';
import { motion } from 'framer-motion';
import t from '../../../../../utilities/transitions';
import { connect } from 'react-redux';
import {
    MDBContainer,
    MDBListGroup,
    MDBListGroupItem,
    MDBBtn
} from 'mdb-react-ui-kit';

class Colors extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    // Run empty change handler
    componentDidMount(){
        this.props.changeHandler({
            target: {
                value: ''
            }
        });
    }

    render(){
        return (
            <motion.div transition={t.transition} initial={this.props.tabEnter} animate={t.normalize} exit={this.props.tabExit}>
                <MDBContainer fluid>
                    <div className="row">
                        <div className="col-12 col-lg-6 mt-4">
                            <h5 className="text-center display-6 m-0 fs-3">Default Theme</h5>
                            <hr/>
                            <MDBListGroup>
                            {this.props.colors.filter(color => color.default).map(color => (
                                <MDBListGroupItem key={color.id} className="d-flex justify-content-between align-items-center">
                                    <h6 className="m-0">{color.label}</h6>
                                    <input 
                                        type="color" 
                                        id={color.id} 
                                        name={color.id} 
                                        value={color.value}
                                        onChange={this.props.colorChange} 
                                        className="cursor-pointer"
                                    />
                                </MDBListGroupItem>
                            ))}
                            </MDBListGroup>
                        </div>
                        <div className="col-12 col-lg-6 mt-4">
                            <h5 className="text-center display-6 m-0 fs-3">Dark Theme</h5>
                            <hr/>
                            <MDBListGroup>
                            {this.props.colors.filter(color => !color.default).map(color => (
                                <MDBListGroupItem key={color.id} className="d-flex justify-content-between align-items-center">
                                    <h6 className="m-0">{color.label}</h6>
                                    <input 
                                        type="color" 
                                        id={color.id} 
                                        name={color.id} 
                                        value={color.value}
                                        onChange={this.props.colorChange} 
                                        className="cursor-pointer"
                                    />
                                </MDBListGroupItem>
                            ))}
                            </MDBListGroup>
                        </div>
                    </div>
                </MDBContainer>
                <MDBBtn className="d-block ms-auto mt-4" color="primary" onClick={this.props.next}>Content and Security<i className="fas fa-chevron-right ms-2"></i></MDBBtn>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(Colors);