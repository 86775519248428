import React from 'react';
import { motion } from 'framer-motion';
import t from '../../utilities/transitions';
import { connect } from 'react-redux';
import {
    MDBValidation,
    MDBValidationItem,
    MDBSelect,
    MDBInput,
    MDBTextArea,
    MDBBtn
} from 'mdb-react-ui-kit';
import Spinner from '../../components/Spinner';

class Request extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    pressEnter = e => {
        
        if (e.key === 'Enter') this.props.submit();
    }

    /**
     * 
     * @param {Event} e - Keypress event
     * 
     * Triggered when the user presses the Tab key
     * Moves cursor to next input (MDB is bugged)
     * Removed when MDB fixes
     */
    pressTab = e => {
        if (e.key === 'Tab'){
            e.preventDefault();
            const input = this.props.inputs.find(f => f.id === e.target.id);
            if (input){
                const nextField = this.props.inputs[this.props.inputs.indexOf(input) + 1];
                if (nextField){
                    const element = document.getElementById(nextField.id);
                    if (element){
                        setTimeout(() => {
                            element.focus();
                            element.select();
                        }, 100);
                    }
                }
            }
        }
    }

    render(){
        return (
            <motion.div className="mt-4" transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out}>
                <MDBValidation method="dialog" id="feedback-form" name="feedback-form" className="row mt-4" onSubmit={this.props.submit}>
                    {this.props.fields.filter(field => ['app', 'feedback'].indexOf(field.id) === -1).map(i => (
                        <MDBValidationItem key={i.id} className="pb-4 col-12 col-lg-4" feedback={this.props.inputs.find(input => input.id === i.id).error} invalid={true} >
                            <MDBInput
                                name={i.id}
                                onChange={this.props.changeHandler}
                                id={i.id}
                                label={i.text}
                                size="lg"
                                className={!this.props.inputs.find(input => input.id === i.id).invalid ? 'mb-0' : 0}
                                onKeyPress={this.pressEnter}
                                onKeyDown={this.pressTab}
                            />
                        </MDBValidationItem>
                    ))}
                    <MDBSelect
                        data={this.props.fields.find(f => f.id === 'app').options.map(option => ({
                            text: option.label,
                            value: option.id,
                            defaultSelected: option.id === this.props.inputs.find(input => input.id === 'app').value,
                            target: {
                                value: option.id,
                                name: 'app'
                            }
                        }))}
                        onValueChange={this.props.changeHandler}
                        label="App"
                        style={{
                            width: '400px',
                            maxWidth: '95%'
                        }}
                        onKeyDown={this.pressTab}
                        id="app"
                        name="app"
                        validation
                        validFeedback=" "
                        className="pb-4 col-12 col-lg-6"
                    />
                    <MDBValidationItem className="pb-4 col-12" feedback={this.props.inputs.find(input => input.id === "feedback").error} invalid={true} >
                        <MDBTextArea
                            name={"feedback"}
                            onChange={this.props.changeHandler}
                            id={"feedback"}
                            label="Describe Request in Detail"
                            size="lg"
                            className={!this.props.inputs.find(input => input.id === "feedback").invalid ? 'mb-0' : 0}
                            onKeyDown={this.pressTab}
                        />
                    </MDBValidationItem>
                </MDBValidation>
                {this.props.working ?
                <MDBBtn 
                    color="primary"
                    disabled
                >
                    <Spinner className="me-2" size="sm" />
                    Sending
                </MDBBtn> :
                <MDBBtn
                    color="primary"
                    onClick={this.props.submit}
                >
                    <i className="fas fa-paper-plane me-2" />
                    Send
                </MDBBtn>}
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(Request);