import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { route } from '../redux/actions';
import { connect } from 'react-redux';
import t from '../utilities/transitions';
import {
    MDBContainer,
    MDBSelect
} from 'mdb-react-ui-kit';
import { StaticRouter, Switch, Route } from 'react-router-dom';
import FeedbackForm from './feedback/FeedbackForm';
import { general_feedback_schema, bug_schema, request_schema, report_abuse_schema } from '../utilities/validations';
import axios from 'axios';

const options = [
    {
        label: 'General Feedback',
        id: 'general',
        fields: [
            {
                text: 'Category',
                id: 'app',
                options: [
                    {
                        label: 'Pigger - Beta',
                        id: 'jizzer-beta'
                    },
                    {
                        label: 'Carbon Valley',
                        id: 'carbon-valley'
                    }
                ],
                skipValidity: true
            },
            {
                text: 'Your Name',
                id: 'name'
            },
            {
                text: 'Email',
                id: 'email'
            },
            {
                text: 'Subject',
                id: 'subject'
            },
            {
                text: 'Feedback',
                id: 'feedback'
            }
        ]
    },
    {
        label: 'Bug Report',
        id: 'bug',
        fields: [
            {
                text: 'App',
                id: 'app',
                options: [
                    {
                        label: 'Pigger - Beta',
                        id: 'jizzer-beta'
                    },
                    {
                        label: 'Carbon Valley',
                        id: 'carbon-valley'
                    }
                ],
                skipValidity: true
            },
            {
                text: 'Your Name',
                id: 'name'
            },
            {
                text: 'Email',
                id: 'email'
            },
            {
                text: 'Subject',
                id: 'subject'
            },
            {
                text: 'Describe Bug in Detail',
                id: 'feedback'
            }
        ]
    },
    {
        label: 'Request',
        id: 'request',
        fields: [
            {
                text: 'App',
                id: 'app',
                options: [
                    {
                        label: 'Pigger - Beta',
                        id: 'jizzer-beta'
                    },
                    {
                        label: 'Carbon Valley',
                        id: 'carbon-valley'
                    }
                ],
                skipValidity: true
            },
            {
                text: 'Your Name',
                id: 'name'
            },
            {
                text: 'Email',
                id: 'email'
            },
            {
                text: 'Subject',
                id: 'subject'
            },
            {
                text: 'Request',
                id: 'feedback'
            }
        ]
    },
    {
        label: 'Report Abuse',
        id: 'report',
        fields: [
            {
                text: 'Your Email',
                id: 'email'
            },
            {
                text: 'Link to Abuse',
                id: 'link'
            },
            {
                text: 'Nature of Abuse',
                id: 'nature',
                options: [
                    {
                        label: 'Unmoderated Instance',
                        id: 'unmoderated'
                    },
                    {
                        label: 'Illegal Activity',
                        id: 'illegal'
                    },
                    {
                        label: 'Rampant Copyright Infringement',
                        id: 'copyright'
                    },
                    {
                        label: 'Other',
                        id: 'other'
                    }
                ],
                skipValidity: true
            },
            {
                text: 'Please Explain',
                id: 'feedback'
            }
        ]
    }
];

class Feedback extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean - Whether a contact form is in the process of being submitted
             * form: String - Type of feedback that the user has selected
             * inputs: Array - The input data (values, errors, etc)
             */
            working: false,
            form: 'general',
            inputs: options.find(o => o.id === 'general').fields.map(field => ({
                id: field.id,
                error: '',
                invalid: true,
                value: field.options ? field.options[0].id : '',
                skipValidity: field.skipValidity
            }))
        }
    }

    /**
     * 
     * @param {Select Event} e 
     * 
     * Triggered when the user selects a different form from the select dropdown
     * Changes the form and resets the inputs
     */
    changeForm = e => this.setState({
        ...this.state,
        form: e.value,
        inputs: options.find(o => o.id === e.value).fields.map(field => ({
            id: field.id,
            error: '',
            invalid: true,
            value: field.options ? field.options[0].id : '',
            skipValidity: field.skipValidity
        }))
    });

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => {
        this.setState({
            ...this.state,
            inputs: this.state.inputs.map(input => {
                if (input.id === e.target.name) return {
                    ...input,
                    value: e.target.value
                }
                else return input;
            })
        }, () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value.trim()]));
            try {
                switch(this.state.form){
                    case 'general':
                        general_feedback_schema.validateSync(data, {
                            abortEarly: false
                        });
                        break;
                    case 'bug':
                        bug_schema.validateSync(data, {
                            abortEarly: false
                        });
                        break;
                    case 'request':
                        request_schema.validateSync(data, {
                            abortEarly: false
                        });
                        break;
                    case 'report':
                        report_abuse_schema.validateSync(data, {
                            abortEarly: false
                        });
                    default:
                        console.log('oob form', this.state.form);
                }
                
                this.setState({
                    ...this.state,
                    inputs: this.state.inputs.map(input => {
                        if (!input.skipValidity) document.getElementById(input.id).setCustomValidity('');
                        return {
                            ...input,
                            invalid: false,
                            error: ''
                        }
                    })
                });
            } catch(err){
                let errorsAdded = [];
                this.setState({
                    ...this.state,
                    inputs: this.state.inputs.map(input => {
                        if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                            errorsAdded.push(input.id);
                            return {
                                ...input,
                                invalid: true,
                                error: err.inner.find(error => error.path === input.id).message
                            }
                        } 
                        else return {
                            ...input,
                            invalid: false,
                            error: ''
                        };
                    })
                }, () => this.state.inputs.forEach(input => {
                    if (!input.skipValidity){
                        if (input.invalid) document.getElementById(input.id).setCustomValidity('hello');
                        else document.getElementById(input.id).setCustomValidity('');
                    }
                }));
            }
        });
    }

    /**
     * Executes a captcha challenge and generates a key a key
     * Will hang until connected to captcha servers
     */
    getRecaptcha = () => new Promise(async (resolve, reject) => {
        if (this.props.captchaReady) window.grecaptcha.enterprise.execute(process.env.REACT_APP_CAPTCHA_KEY, {action: 'login'}).then(resolve).catch(err => {
            console.log(err);
            alert('Human verification failed. Refresh the page and try again.');
            reject();
        });
        else setTimeout(async () => {
            const captchaKey = await this.getRecaptcha();
            resolve(captchaKey);
        }, 500);
    });

    /**
     * Submit only if there isn't already a submission being sent
     * Set working
     * Validate inputs
     * Get Captcha key
     * Make request to server
     * Route to the "Feedback Received" page
     */
    submit = () => {
        document.getElementById('feedback-form').classList.add('was-validated');
        let invalidInputs = this.state.inputs.filter(input => input.invalid && !input.skipValidity);
        invalidInputs.forEach(input => document.getElementById(input.id).setCustomValidity('hello'));
        if (!this.state.working && !invalidInputs.length) this.setState({
            ...this.state,
            working: true
        }, async () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value.trim()]));
            try {
                switch(this.state.form){
                    case 'general':
                        general_feedback_schema.validateSync(data, {
                            abortEarly: false
                        });
                        break;
                    case 'bug':
                        bug_schema.validateSync(data, {
                            abortEarly: false
                        });
                        break;
                    case 'request':
                        request_schema.validateSync(data, {
                            abortEarly: false
                        });
                        break;
                    case 'report':
                        report_abuse_schema.validateSync(data, {
                            abortEarly: false
                        });
                    default:
                        console.log('oob form', this.state.form);
                }
                const captchaKey = await this.getRecaptcha();
                const fd = new FormData();
                for ( const key in data ) {
                    fd.append(key, data[key]);
                }
                fd.append('captchaKey', captchaKey);
                fd.append('category', this.state.form);
                axios.post('/contact/feedback', fd).then(() => this.props.route('/feedback-received')).catch(err => this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later.');
                }));
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                });
            }
        });
    }

    render(){
        return (
            <motion.div transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="pt-5">
                <MDBContainer>
                    <MDBSelect
                        data={options.map(option => ({
                            text: option.label,
                            value: option.id,
                            defaultSelected: option.id === this.state.form
                        }))}
                        onValueChange={this.changeForm}
                        label="Type"
                        style={{
                            width: '400px',
                            maxWidth: '95%'
                        }}
                    />
                    <StaticRouter location={this.state.form}>
                        <AnimatePresence exitBeforeEnter>
                            <Switch key={this.state.form}>
                                <Route exact path=":form">
                                    <FeedbackForm
                                        form={this.state.form}
                                        working={this.state.working}
                                        options={options}
                                        fields={options.find(o => o.id === this.state.form).fields}
                                        inputs={this.state.inputs}
                                        changeHandler={this.changeHandler}
                                        submit={this.submit}
                                    />
                                </Route>
                            </Switch>
                        </AnimatePresence>
                    </StaticRouter>
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { route })(Feedback);