import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import 'quill/dist/quill.snow.css';
import Spinner from '../../../../../components/Spinner';
import axios from 'axios';
import { parse } from 'node-html-parser';
import { update_dashboard_data } from '../../../../../redux/actions';

class NewNoteModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * editor: false | Quill editor
             * working: Boolean - Whether a new note is in the process of being submitted
             * resetting: Boolean - Whether the editor is in the process of being reset
             */
            editor: false,
            working: false,
            resetting: false
        }
    }

    /**
     * Initialize the Quill editor
     */
    componentDidMount(){
        this.setState({
            ...this.state,
            editor: new window.Quill('#editor', {
                theme: 'snow'
            })
        });
    }

    /**
     * Submit only if there isn't already a submission being sent
     * Validate inputs
     * Make request to server
     * Hide modal
     * Reset editor
     * Update dashboard data
     */
    submit = () => {
        try {
            if (!this.state.working) this.setState({
                ...this.state,
                working: true
            }, () => {
                const newRule = parse(document.getElementById('editor').innerHTML).querySelector('.ql-editor').innerHTML;
                if (!parse(newRule).textContent.length) throw "Please enter a rule";
                if (parse(newRule).textContent.length > 10000) throw "Max length exceeded (Max: 10,000 chars";
                axios.post('/jizzer/beta-release-notes', {
                    html: newRule
                })
                .then(res => {
                    this.props.toggleShowModal();
                    this.state.editor.setContents([{ insert: '\n' }]);
                    this.props.update_dashboard_data(res.data);
                })
                .catch(err => {
                    console.log(err);
                    alert('An error occurred. Please try again later.');
                })
                .finally(() => this.setState({
                    ...this.state,
                    working: false
                }));
            });
        } catch(err){
            this.setState({
                ...this.state,
                working: false
            }, () => alert(err));
        }
    }

    render(){
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="xl">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>New Release Notes</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody style={{height: '20rem'}} className="d-flex flex-column quill-modal-body">
                                {this.state.resetting ? <></> :
                                <div id="editor"></div>}
                            </MDBModalBody>
                            <MDBModalFooter>
                                {this.state.working ?
                                <MDBBtn
                                    color="success"
                                    disabled
                                >
                                    <Spinner className="me-2" size="sm" />
                                    Submitting
                                </MDBBtn> :
                                <MDBBtn
                                    color="success"
                                    onClick={this.submit}
                                >
                                    <i className="fas fa-paper-plane me-2" />
                                    Submit
                                </MDBBtn>}
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { update_dashboard_data })(NewNoteModal);