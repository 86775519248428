import React from 'react';
import General from './General';
import Bugs from './Bugs';
import Requests from './Requests';
import Reports from './Reports';

class MessageTab extends React.Component{
    constructor(props){
        super();
        this.tab = props.tab;
    }

    render(){
        switch(this.tab){
            case 'general':
                return <General 
                            tab={this.props.tab}
                            enter={this.props.enter}
                            exit={this.props.exit}
                        />
            case 'bug':
                return <Bugs 
                            tab={this.props.tab}
                            enter={this.props.enter}
                            exit={this.props.exit}
                        />
            case 'request':
                return <Requests 
                            tab={this.props.tab}
                            enter={this.props.enter}
                            exit={this.props.exit}
                        />
            case 'report':
                return <Reports 
                            tab={this.props.tab}
                            enter={this.props.enter}
                            exit={this.props.exit}
                        />
            default:
                console.log('oob tab', this.tab);
                return <></>
        }
    }
}

export default MessageTab;