import { Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from "react";
import { set_history, set_captcha_ready } from './redux/actions';
import { connect } from 'react-redux'
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Resets from './pages/Resets';
import Cancel from './pages/Cancel';
import Message from './pages/Message';
import CreateAccount from './pages/CreateAccount';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import ValidateEmail from './pages/ValidateEmail';
import Validate from './pages/Validate';
import Dashboard from './pages/Dashboard';
import Products from './pages/Products';
import Feedback from './pages/Feedback';
import Info from './pages/Info';
import InstanceRedirect from './pages/InstanceRedirect';
import Instances from './pages/Instances';

const App = props => {


  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    document.addEventListener('onautocomplete', e => {
      if (e.target.classList.contains('form-control')) e.target.classList.add('active');
    });
    props.set_history(location, history); // Set history into redux store as soon as app is rendered on the client
    window.grecaptcha.enterprise.ready(props.set_captcha_ready);
  }, []);

  /**
   * Currently using React Router 5
   * React Router 6 recently released; stupidly not made backwards-compatible and removes numerous features with no replacement
   */

  return (
    /**
     * AnimatePresence tells the child components to use framer motion page transitions
     * exitBeforeEnter flag makes sure the exit transition completes before the entrance on the next page initiates
     * The "key" in the Switch is necessary for animations to work properly. Needs to be unique on every page change, hence setting it to location.pathname
     */
    <>
      <NavBar history={history} location={location} />
      <div className="flex-grow-1 position-relative">
        <div className="h-100 d-flex flex-column">
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/">
                <Home history={history} location={location} />
              </Route>
              <Route exact path="/login">
                <Login history={history} location={location} />
              </Route>
              <Route exact path="/info">
                <Info history={history} location={location} />
              </Route>
              <Route exact path="/instance/:instance">
                <InstanceRedirect history={history} location={location} />
              </Route>
              <Route exact path="/tag/:tag">
                <Message message="The link you visited is invalid from carbonvalley.net, but will redirect the user to a tag on your instance." history={history} location={location} />
              </Route>
              <Route exact path="/feedback">
                <Feedback history={history} location={location} />
              </Route>
              <Route exact path="/instances">
                <Instances history={history} location={location} />
              </Route>
              <Route exact path="/products">
                <Products history={history} location={location} />
              </Route>
              <Route exact path="/dashboard">
                <Dashboard history={history} location={location} />
              </Route>
              <Route exact path="/set-password/:id">
                <Resets history={history} location={location}/>
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword history={history} location={location} /> 
              </Route>
              <Route exact path="/cancel/:id">
                <Cancel history={history} location={location}/>
              </Route>
              <Route exact path="/check-email">
                <Message message="An email has been sent with a link to reset your password." history={history} location={location} />
              </Route>
              <Route exact path="/feedback-received">
                <Message message="Thank you for your feedback. If we need more details, we will reach out to you via email." history={history} location={location} />
              </Route>
              <Route exact path="/received">
                <Message message="Your request has been received. Please keep an eye on your email inbox or Carbon Valley dashboard for our response." history={history} location={location} />
              </Route>
              <Route exact path="/create-account">
                <CreateAccount history={history} location={location} />
              </Route>
              <Route exact path="/validate-email">
                <ValidateEmail history={history} location={location} />
              </Route>
              <Route exact path="/verify/:id">
                <Validate />
              </Route>
              <Route exact path="/not-found">
                <Message message="Not Found" history={history} location={location} />
              </Route>
              <Route exact path="/null">
                <Redirect to='/' />
              </Route>
              <Route path="*">
                <Message message="Not Found" history={history} location={location} />
              </Route>
            </Switch>
          </AnimatePresence>
        </div>
      </div>
    </>
    
  );
}

const mapStateToProps = (state) => {
  return {
      ...state
  }
}

export default connect(mapStateToProps, { set_history, set_captcha_ready })(App);