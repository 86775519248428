import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { motion } from 'framer-motion';
import h from '../utilities/helpers';
import t from '../utilities/transitions';
import Menu from './dashboard/Menu';
import MenuMobile from './dashboard/MenuMobile';
import options from './dashboard/options';
import { route, select_dashboard_item, set_dashboard_data, terminate_dashboard } from '../redux/actions';
import { 
    MDBTabsContent,
    MDBTabsPane,
    MDBContainer
} from 'mdb-react-ui-kit';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios';

/**
 * This is the user dashboard
 */

class Dashboard extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * loaded: Boolean indicating whether user information has been loaded
             * exit: framer-motion exit transition
             */
            loaded: false,
            exit: this.getExit(props)
        }
    }

    componentDidMount(){
        /**
         * Set exit transition to default value, then check whether user information has been loaded. If so, load the component
         */
        this.setState({
            ...this.state,
            exit: t.fade_out
        }, () => {
            this.load();
            if (!this.state.loaded && this.props.userInfo.role) this.setLoaded();
        });
    }

    componentDidUpdate(){
        /**
         * Check whether user information has been loaded. If so, and component is not loaded, load the component
         */
        if (!this.state.loaded && this.props.userInfo.role) this.setLoaded();
    }

    componentWillUnmount(){
        /**
         * If user is exiting and not going to a contract or listing, reset to default dashboard preferences
         */
        this.props.terminate_dashboard(); // Reset selection to first menu option when the user leaves the dashboard
    }

    getExit = props => {
        /**
         * If the user is coming from a listing or contract, fade in from the left
         * Otherwise, fade in
         */
        if (props.dashboardItemSelected === 'instances') return t.fade_out_right;
        return t.fade_out;
    }

    setLoaded = () => this.setState({
        ...this.state,
        loaded: true
    });

    /**
     * Load the appropriate dashboard based on role
     * Set into state
     */
    load = () => {
        if (h.checkChadmin(this.props.userInfo)) axios.get('/api/dashboard/chadmin').then(res => this.props.set_dashboard_data({
            userList: res.data.userList,
            contactFormMessages: res.data.contactFormMessages,
            statistics: res.data.statistics,
            releaseNotes: res.data.releaseNotes,
            servers: res.data.servers,
            messageCounts: res.data.messageCounts
        })).catch(err => {
            console.log('instances load error', err);
            setTimeout(this.load, 1000);
        });
        else if (h.checkJanny(this.props.userInfo)) axios.get('/api/dashboard/janny').then(res => this.props.set_dashboard_data({
            contactFormMessages: res.data.contactFormMessages,
            statistics: res.data.statistics,
            releaseNotes: res.data.releaseNotes
        })).catch(err => {
            console.log('instances load error', err);
            setTimeout(this.load, 1000);
        });
        else axios.get('/api/dashboard/child').then(res => this.props.set_dashboard_data({
            contactFormMessages: res.data.contactFormMessages,
            statistics: res.data.statistics,
            releaseNotes: res.data.releaseNotes
        })).catch(err => {
            console.log('instances load error', err);
            setTimeout(this.load, 1000);
        });
    }

    render(){
        return (
            <>
                <motion.div className="h-100" transition={t.transition} exit={this.state.exit} animate={t.normalize} initial={this.state.exit}>
                    <MDBContainer fluid className="h-100 px-0">
                        <div className="row mx-0 h-100">
                            {this.state.loaded ?
                            <div className="h-lg-100 col-12 col-lg-3 py-3 px-2">
                                <div className="dashboard-menu-desktop h-100">
                                    <Menu />
                                </div>
                                <div className="dashboard-menu-mobile">
                                    <MenuMobile />
                                </div>
                            </div> : <></>}
                            <div className="col-12 col-lg-9 h-100 px-0">
                                
                                <div className="tab-content h-100" id="pills-tabContent">
                                    {this.props.userInfo.role && this.state.loaded ?
                                    <MDBTabsContent className="h-100">
                                        {options.find(o => o.category === this.props.userInfo.role).options.map(option => (
                                            <MDBTabsPane className="h-100" show={option.id === this.props.dashboardItemSelected}>
                                                <option.component imageNav={this.imageNav}/>
                                            </MDBTabsPane>
                                        ))}
                                    </MDBTabsContent> :
                                    <div className="pt-5">
                                        <LinearProgress />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </MDBContainer>
                </motion.div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route, select_dashboard_item, set_dashboard_data, terminate_dashboard })(withRouter(Dashboard));