import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { 
    purge_user, 
    set_user, 
    route, 
    reconnect_socket, 
    set_server_status,
    set_preference_status 
} from '../redux/actions';
import './css/navBar.css';
import MobileLogo from './navBar/MobileLogo';
import MobileOnlyNavItems from './navBar/MobileOnlyNavItems';
import MobileLogin from './navBar/MobileLogin';
import DropdownMenuDesktop from './navBar/DropdownMenuDesktop';
import MenuItemsBoth from './navBar/MenuItemsBoth';
import LogoutButtonMobile from './navBar/LogoutButtonMobile';
import DesktopLogo from './navBar/DesktopLogo';
import {
    MDBNavbar,
    MDBContainer,
    MDBIcon,
    MDBNavbarToggler,
    MDBCollapse,
    MDBRipple
} from 'mdb-react-ui-kit';
import { motion } from 'framer-motion';
import t from '../utilities/transitions';


/**
 * This is the navbar
 * It currently looks decent on all screen sizes, but the code needs to be cleaned up
 */

class NavBar extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * userLoaded: Boolean indicating whether the first user session check has concluded
             */
            userLoaded: false,
            navExpanded: false,
            loggingOut: false
        }
    }

    componentDidMount(){
        /**
         * Set bootstrap (need to wait until mount or else it messes up on ssr)
         * Check user session
         * Set the mobile hamburger into state so that it can be closed upon route
         * Set tooltips
         */
        if (this.props.socket) this.props.socket.emit('login');
    }

    componentDidUpdate(prevProps){
        if (!prevProps.socket && this.props.socket) this.initializeSocket();
        if (prevProps.userInfo._id && !this.props.userInfo._id && ['dashboard'].indexOf(this.props.history.location.pathname.split('/')[1]) > -1) this.props.route('/login');
    }

    socketDisconnect = () => setTimeout(() => {
        if (this.props.socket.connected) this.initializeSocket(); 
        else this.socketDisconnect();
    }, 1500);

    initializeSocket = () => {
        this.props.socket.off('disconnect');
        this.props.socket.off('server-info');
        this.props.socket.off('preference-status');

        this.props.reconnect_socket();
        this.props.socket.emit('login'); 

        this.props.socket.on('preference-status', this.props.set_preference_status);;
        this.props.socket.on('server-info', this.props.set_server_status);
        this.props.socket.on('disconnect', this.socketDisconnect);
    }

    logout = retry => {
        if (!this.state.loggingOut || retry) this.setState({
            ...this.state,
            loggingOut: true,
            navExpanded: false
        }, () => axios.get('/auth/logout').then(() => {
            if (this.props.socket && this.props.socket.disconnect) this.props.socket.disconnect();
            this.props.purge_user();
            this.setState({
                ...this.state,
                loggingOut: false
            });
        }).catch(err => {
            console.log('logout error', err);
            setTimeout(() => this.logout(true), 1000);
        }));
    }

    nav = (e, path) => {
        e.preventDefault();
        this.props.route(path);
        this.setState({
            ...this.state,
            navExpanded: false
        })
    }

    collapseNav = () => this.setState({
        ...this.state,
        navExpanded: false
    });

    toggleNav = () => this.setState({
        ...this.state,
        navExpanded: !this.state.navExpanded
    });

    render(){
        return (
            <MDBNavbar light expand='lg' id="div-nav-container" className="container-fluid px-0 navbar navbar-bg w-100">
                <div className="row mx-auto w-100">
                    <MobileLogo nav={this.nav} />
                    <div id="nav-column" className="col-lg-12 col-6 d-flex flex-column justify-content-center">
                        <MDBNavbar style={{boxShadow: 'none'}} expand='lg' id="nav-container" className="py-2 w-100">
                            <MDBRipple className="ms-auto w-max-content" rippleColor="light">
                                <MDBNavbarToggler
                                    type='button'
                                    data-target='#navbarText'
                                    aria-controls='navbarText'
                                    aria-expanded='false'
                                    aria-label='Toggle navigation'
                                    onClick={this.toggleNav}
                                    className="ms-auto btn-lg"
                                    style={{
                                        background: 'rgba(96, 125, 139, 0.90)',
                                        color: '#eee'
                                    }}
                                    size="sm"
                                >
                                    <MDBIcon icon='bars' size="lg" fas />
                                </MDBNavbarToggler>
                            </MDBRipple>
                            <MDBCollapse show={this.state.navExpanded} navbar id='navbarText'>
                                <MDBContainer fluid>
                                    <div className="row mx-0">
                                        <div className="col-12 col-lg-8 d-flex align-items-center">
                                            <DesktopLogo nav={this.nav} />
                                            <ul className="navbar-nav mb-lg-0, ul-main-nav">
                                                {!this.props.userInfo.username ?
                                                <>
                                                    {typeof document !== 'undefined' && document.getElementById('user-info-server') ?
                                                    <MobileOnlyNavItems history={this.props.history} userInfo={JSON.parse(document.getElementById('user-info-server').textContent)} nav={this.nav} />
                                                        : 
                                                    <>
                                                        {typeof document !== 'undefined' ?
                                                        <MobileLogin history={this.props.history} nav={this.nav} /> : <></>}
                                                    </>
                                                    
                                                    }
                                                </>
                                                : <MobileOnlyNavItems history={this.props.history} userInfo={this.props.userInfo} nav={this.nav} />}
        
                                                <MenuItemsBoth nav={this.nav} history={this.props.history} />

                                                {(this.props.userInfo.username || (typeof document !== 'undefined' && document.getElementById('user-info-server'))) ?
                                                <LogoutButtonMobile loggingOut={this.state.loggingOut} logout={this.logout} /> : <></>}

                                            </ul>
                                        </div>
                                        <div className="col-12 col-lg-4 d-flex justify-content-end align-items-center">
                                        {!this.props.userInfo.username ?
                                            <>
                                                {typeof document !== 'undefined' && document.getElementById('user-info-server') ?
                                                <DropdownMenuDesktop userInfo={JSON.parse(document.getElementById('user-info-server').textContent)} nav={this.nav} loggingOut={this.state.loggingOut} logout={this.logout} />
                                                    : 
                                                <>
                                                    {typeof document !== 'undefined' ? 
                                                    <motion.a transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out} onClick={e => this.nav(e, '/login')} style={{cursor: 'pointer'}} className={`text-end links-generic h6 me-3 mb-0 fw-bold navbar-user-desktop text-nowrap`} >Login / Create Account</motion.a> : <></>}
                                                </>
                                                }
                                            </>
                                            : <DropdownMenuDesktop loggingOut={this.state.loggingOut} userInfo={this.props.userInfo} nav={this.nav} logout={this.logout} />    
                                        }
                                        </div>
                                    </div>
                                </MDBContainer>
                            </MDBCollapse>
                        </MDBNavbar>
                    </div>
                </div>                    
            </MDBNavbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(
    mapStateToProps, 
    { 
        purge_user, 
        set_user, 
        route, 
        reconnect_socket, 
        set_server_status,
        set_preference_status 
    }
)(NavBar);