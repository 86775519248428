import React from 'react';
import json from "./emoji/emojis.json";
import './css/emoji.css';
import { StaticRouter, Switch, Route } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import EmojiBody from './emoji/EmojiBody';
import t from '../utilities/transitions';
import {
    MDBPopover,
    MDBPopoverBody
} from 'mdb-react-ui-kit';

class EmojiPicker extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * emojiIndex: Number - The page of emojis that the user is currently on
             * popoverOpen: Boolean - Whether the emoji picker is open and displayed
             * exit: Object - framer-motion exit animation
             * entrance: Object - framer-motion entrance animation
             */
            emojiIndex: 0,
            popoverOpen: false,
            exit: t.fade_out,
            entrance: t.fade_out
        }
    }

    /**
     * Prevents forms that have emoji pickers in them from being submitted when the user tries to open the emoji picker
     */
    componentDidMount(){
        document.getElementById(`emoji-button-${this.props.emojiID}`).setAttribute('type', 'button');
    }

    /**
     * 
     * @param {MouseEvent} e - Event object from the mousedown event added to document.body when the emoji picker was opened
     * 
     * If the user taps or clicks outside of the emoji picker, close it
     * Remove event listener when finished
     */
    closePopover = e => {
        if (!(e.target && e.target.classList.contains(`emoji-triggers-${this.props.emojiID}`))) this.setState({
            ...this.state,
            popoverOpen: false
        }, () => document.body.removeEventListener('mousedown', this.closePopover));
    } 

    /**
     * Displays the emoji picker and listens for a mousedown event to close the picker
     */
    showPopover = () => {
        if (this.props.pickerOpenFunction) this.props.pickerOpenFunction();
        this.setState({
            ...this.state,
            popoverOpen: true
        }, () => setTimeout(() => document.body.addEventListener('mousedown', this.closePopover), 500));
    } 

    /**
     * 
     * @param {Number} index - The emoji page. 
     * 
     * Set entrance and exit animations as appropriate
     * Navigate to the selected emoji page
     */
    setEmojiIndex = index => {
        this.setState({
            ...this.state,
            exit: (index > this.state.emojiIndex) ? t.fade_out_left_minor : t.fade_out_right_minor,
            entrance: (index > this.state.emojiIndex) ? t.fade_out_right_minor : t.fade_out_left_minor
        }, () => this.setState({
            ...this.state,
            emojiIndex: index
        }));
    }

    /**
     * Creates the emoji nav at the top
     * 
     */
    createSvgHeading = () => json.map((item, index) =>
    <button className={`emoji-panel__btn emoji-triggers-${this.props.emojiID} ${this.state.emojiIndex === index ? 'emoji-active' : ''}`} key={index} onClick={() => this.setEmojiIndex(index)}> {this.createSvg(item.unicode, item.name, true)}</button>);

    /**
     * 
     * @param {String} unicode - Unicode character of the emoji
     * @param {String} title - The emoji itself
     * @param {Boolean} heading - Whether this function is being called by the createSvgHeading function
     * @returns The device's native emoji replaced by the corresponding sprite found on /assets/emoji/emoji-sprite.svg 
     */
    createSvg = (unicode, title, heading) => {
        return (
            <svg className={heading ? `emoji-triggers-${this.props.emojiID}` : ''} width="25" height="25">
                <use className={heading ? `emoji-triggers-${this.props.emojiID}` : ''} href={`/assets/emoji/emoji-sprite.svg#${unicode}`}>
                    <title className={heading ? `emoji-triggers-${this.props.emojiID}` : ''}>{title}</title>
                </use>
            </svg>
        );
    }

    render(){
        return (
            <MDBPopover 
                color="link" 
                placement="top"
                btnChildren={this.props.trigger}
                btnClassName={`text-unset w-max-content h-max-content emoji-triggers-${this.props.emojiID} ${this.props.className}`}
                rippleColor="primary"
                className={`mb-2 w-max-content max-w-max-content emoji-triggers-${this.props.emojiID}`}
                onShow={this.showPopover}
                isOpen={this.state.popoverOpen}
                id={`emoji-button-${this.props.emojiID}`}
                disabled={this.props.disabled}
                type="button"
            >
                <MDBPopoverBody>
                    <motion.div style={{overflowX: 'hidden', maxWidth: '100vw'}} className="emoji-panel" transition={t.transition} exit={t.fade_out_minimize} animate={t.normalize} initial={t.fade_out_minimize}>
                        <div className={`emoji-panel__heading emoji-triggers-${this.props.emojiID}`}>
                            {this.createSvgHeading()}
                        </div>
                        <StaticRouter location={`/${this.state.emojiIndex}`}>
                            <AnimatePresence exitBeforeEnter>
                                <Switch key={this.state.emojiIndex}>
                                    <Route exact path="/:id">
                                        <EmojiBody 
                                            emojiIndex={this.state.emojiIndex} 
                                            key={this.state.emojiIndex} 
                                            json={json} 
                                            onEmojiSelect={this.props.onEmojiSelect} 
                                            createSvg={this.createSvg} 
                                            exit={this.state.exit}
                                            entrance={this.state.entrance}
                                        />
                                    </Route>
                                </Switch>          
                            </AnimatePresence>
                        </StaticRouter>
                    </motion.div>
                </MDBPopoverBody>
            </MDBPopover>
        );
    }
}

export default EmojiPicker;