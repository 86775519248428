import React from 'react';
import { connect } from 'react-redux';
import t from '../../../../../utilities/transitions';
import { motion } from 'framer-motion';
import {
    MDBContainer
} from 'mdb-react-ui-kit';
import LiveStatus from './LiveStatus';
import PreferenceForms from './PreferenceForms';
import { change_user_details } from '../../../../../redux/actions';

class Preferences extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    /**
     * If server goes live, update userInfo accordingly
     */
    componentDidUpdate(prevProps){
        if (prevProps.serverStatus.status !== 'live' && this.props.serverStatus.status === 'live'){
            const userInstance = this.props.userInfo.instances.find(i => i.id === 'jizzer-beta');
            this.props.change_user_details({
                instances: [
                    ...this.props.userInfo.instances.filter(i => i.id !== 'jizzer-beta'),
                    {
                        ...userInstance,
                        status: 'live',
                        actions: [
                            ...userInstance.actions,
                            {
                                action: 'launched',
                                timestamp: new Date()
                            }
                        ]
                    }
                ]
            });
        }
    }

    render(){
        return (
            <motion.div className="fg-1 dashboard-containers" transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out}>
                <MDBContainer fluid className="h-100">
                    <div className="row h-100">
                        <div className="col-9 h-100 d-flex flex-column">
                            <PreferenceForms
                                notify={this.props.notify}
                            />
                        </div>
                        <div className="col-3 h-100 pb-4">
                            <LiveStatus
                                notify={this.props.notify} 
                            />
                        </div>
                    </div>
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { change_user_details })(Preferences);