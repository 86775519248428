import React from 'react';
import Settings from './Settings';
import Messages from './Messages';
import Instances_Chadmin from './Instances_Chadmin';
import Instances_Child from './Instances_Child';
// import Reports from './Reports';
// import ModLogs from './ModLogs';
// import Users from './Users';

export default [
    {
        category: 'Chadmin',
        options: [
            {
                text: 'User Info',
                icon: <i style={{color: 'var(--mdb-primary)'}} className="fas fa-user me-2"></i>,
                id: 'settings',
                component: Settings
            },
            {
                text: 'Your Instances',
                icon: <i style={{color: 'var(--mdb-success)'}} className="fas fa-server me-2"></i>,
                id: 'instances',
                component: Instances_Child
            },
            {
                text: 'All Instances',
                icon: <i style={{color: 'var(--mdb-indigo)'}} className="fas fa-server me-2"></i>,
                id: 'instances-all',
                component: Instances_Chadmin
            },
            {
                text: 'Messages',
                icon: <i className="fas fa-envelope me-2 text-pink"></i>,
                id: 'messages',
                component: Messages
            },
            // {
            //     text: 'Users',
            //     icon: <i style={{color: 'var(--mdb-cyan)'}} className="fas fa-users me-2"></i>,
            //     id: 'users',
            //     component: Users
            // },
            // {
            //     text: 'Reports',
            //     icon: <i style={{color: 'var(--mdb-warning)'}} className="fas fa-flag me-2"></i>,
            //     id: 'reports',
            //     component: Reports
            // },
            // {
            //     text: 'Mod Logs',
            //     icon: <i style={{color: 'var(--mdb-danger)'}} className="fas fa-gavel me-2"></i>,
            //     id: 'modlogs',
            //     component: ModLogs
            // }
        ]
    },
    {
        category: 'Child',
        options: [
            {
                text: 'User Info',
                icon: <i style={{color: 'var(--mdb-primary)'}} className="fas fa-user me-2"></i>,
                id: 'settings',
                component: Settings
            },
            {
                text: 'Instances',
                icon: <i style={{color: 'var(--mdb-success)'}} className="fas fa-server me-2"></i>,
                id: 'instances',
                component: Instances_Child
            }
        ]
    }
]