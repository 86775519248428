import React from 'react';
import {
    MDBValidation,
    MDBValidationItem,
    MDBInput,
    MDBBtn
} from 'mdb-react-ui-kit';
import { link_schema } from '../../../../utilities/validations';

class LinkInsertForm extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * inputs: Array - The input data (values, errors, etc)
             */
            inputs: [
                {
                    id: 'link',
                    error: '',
                    invalid: true,
                    value: '',
                    text: "Link"
                },
                {
                    id: 'text',
                    error: '',
                    invalid: true,
                    value: '',
                    text: 'Text'
                }
            ],
            working: false
        }
    }

    /**
     * Run blank change handler
     */
    componentDidMount(){
        this.changeHandler({
            target: {
                name: ''
            }
        });
    }

    /**
     * If inputs are valid, run insertLink method from parent
     * Reset inputs
     */
    submit = () => {
        document.getElementById('link_form_signalboost').classList.add('was-validated');
        let invalidInputs = this.state.inputs.filter(input => input.invalid);
        invalidInputs.forEach(input => document.getElementById(input.id + '-signalboost').setCustomValidity('hello'));
        if (!this.state.working && !invalidInputs.length) this.setState({
            ...this.state,
            working: true
        }, async () => {
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
            try {
                link_schema.validateSync(data, {
                    abortEarly: false
                });
                this.props.insertLink(data.link.startsWith('http://') || data.link.startsWith('https://') ? data.link : `//${data.link}`, data.text);
                document.getElementById('link_form_signalboost').classList.remove('was-validated');
                this.setState({
                    ...this.state,
                    working: false,
                    inputs: [
                        {
                            id: 'link',
                            error: '',
                            invalid: true,
                            value: '',
                            text: "Link"
                        },
                        {
                            id: 'text',
                            error: '',
                            invalid: true,
                            value: '',
                            text: 'Text'
                        }
                    ]
                }, () => this.changeHandler({
                    target: {
                        name: ''
                    }
                }));
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log(err);
                    alert('An error occurred. Please try again later');
                });
            }
        });
    }

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => this.setState({
        ...this.state,
        inputs: this.state.inputs.map(input => {
            if (input.id === e.target.name) return {
                ...input,
                value: e.target.value
            }
            else return input
        })
    }, () => {
        const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value]));
        try {
            link_schema.validateSync(data, {
                abortEarly: false
            });
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    document.getElementById(input.id + '-signalboost').setCustomValidity('');
                    return {
                        ...input,
                        invalid: false,
                        error: ''
                    }
                })
            });
        } catch(err){
            let errorsAdded = [];
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                        errorsAdded.push(input.id);
                        return {
                            ...input,
                            invalid: true,
                            error: err.inner.find(error => error.path === input.id).message
                        }
                    } 
                    else return {
                        ...input,
                        invalid: false,
                        error: ''
                    };
                })
            }, () => this.state.inputs.forEach(input => {
                if (input.invalid) document.getElementById(input.id + '-signalboost').setCustomValidity('hello');
                else document.getElementById(input.id + '-signalboost').setCustomValidity('');
            }));
        }
    });


    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    pressEnter = e => {
        if (e.key === 'Enter') this.submit();
    }

    /**
     * 
     * @param {Event} e - Keypress event
     * 
     * Triggered when the user presses the Tab key
     * Moves cursor to next input (MDB is bugged)
     * Removed when MDB fixes
     */
    pressTab = e => {
        if (e.key === 'Tab'){
            e.preventDefault();
            const input = this.state.inputs.find(f => f.id === e.target.id.split('-signalboost')[0]);
            if (input){
                const nextField = this.state.inputs[this.state.inputs.indexOf(input) + 1];
                if (nextField){
                    const element = document.getElementById(nextField.id + '-signalboost');
                    if (element){
                        setTimeout(() => {
                            element.focus();
                            element.select();
                        }, 100);
                    }
                }
            }
        }
    }

    render(){
        return (
            <>
                <MDBValidation className="page-popover-triggers" id="link_form_signalboost" onSubmit={this.submit}>
                    {this.state.inputs.map(input => (
                        <MDBValidationItem className="pb-4 page-popover-triggers" feedback={input.error} invalid={true} >
                            <MDBInput
                                name={input.id}
                                onChange={this.changeHandler}
                                id={input.id + '-signalboost'}
                                label={input.text}
                                size="lg"
                                value={input.value}
                                className={`page-popover-triggers ${!input.invalid ? 'mb-0' : 0}`}
                                onKeyPress={this.pressEnter}
                                onKeyDown={this.pressTab}
                            />
                        </MDBValidationItem>
                    ))}
                </MDBValidation>
                <MDBBtn 
                    color="primary" 
                    onClick={this.submit}
                    className="page-popover-triggers"
                    type="button"
                >
                    <i className="fas fa-link me-2 page-popover-triggers"></i>
                    Insert
                </MDBBtn>
            </>
        );
    }
}

export default LinkInsertForm;