import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';


class JizzerBetaDisableModal extends React.Component{
    constructor(){
        super();
        this.state = {
        }
    }

    render(){
        const request = this.props.userInfo.instances.find(i => i.id === 'jizzer-beta');
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="xl">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Instance Disabled</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <h5 className="text-center">Your Instance has been Disabled</h5>
                                <p className="text-center m-0">For more information, please check your email</p>
                                <hr></hr>
                                <p>{request.disableText}</p>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {  })(JizzerBetaDisableModal);