import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import h from '../../../../../../utilities/helpers';


class MessageModal extends React.Component{
    constructor(){
        super();
        this.state = {
        }
    }

    render(){
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="xl">
                    <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Release Notes</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBListGroup light>
                                    {this.props.releaseNotes
                                        .filter(r => new Date(r.timestamp) > new Date(this.props.serverStatus.latest))
                                        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                        .map(note => (
                                            <MDBListGroupItem>
                                                <p className="text-blusteel m-0">{h.makeDateHR(new Date(note.timestamp))}</p>
                                                <p className="text-blusteel">{h.getTimeHR(new Date(note.timestamp))}</p>
                                                <div 
                                                    dangerouslySetInnerHTML={{__html: note.html}}
                                                ></div>
                                            </MDBListGroupItem>
                                        ))
                                    }
                                </MDBListGroup>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {  })(MessageModal);