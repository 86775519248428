import React from 'react';
import { connect } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import t from '../../../../utilities/transitions';
import {
    MDBBtn,
    MDBContainer
} from 'mdb-react-ui-kit';
import { StaticRouter, Switch, Route } from 'react-router-dom';
import Status from './jizzerBeta/Status';
import LinearProgress from '@mui/material/LinearProgress';
import { set_server_status } from '../../../../redux/actions';
import axios from 'axios';

class JizzerBeta extends React.Component{
    constructor(){
        super();
    }

    componentDidMount(){
        this.load();
    }

    // Load the user's Pigger instance status
    load = () => axios.get('/jizzer/status').then(res => this.props.set_server_status(res.data.serverStatus)).catch(err => {
        console.log('status error', err);
        setTimeout(this.load, 1000);
    });

    changeStatus = status => this.setState({
        ...this.state,
        status: status
    });

    componentWillUnmount(){
        this.props.set_server_status(false);
    }

    render(){
        const route = `${this.props.serverStatus.status === 'preregister'}-${['validating-domain', 'generating-ssl', 'creating-database', 'awaiting-preferences', 'building-app'].indexOf(this.props.serverStatus.status) > -1}-${['live', 'update-queued'].indexOf(this.props.serverStatus.status) > -1}`;
        return (
            <motion.div className="pt-4 h-100 d-flex flex-column" transition={t.transition} initial={t.fade_out_right} animate={t.normalize} exit={t.fade_out_right}>
                <MDBContainer fluid>
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-4 col-lg-3">
                            <MDBBtn color="link" className="text-primary" onClick={() => this.props.setView('list')} size="lg">
                                <i className="fas fa-chevron-left me-2"></i>
                                Products
                            </MDBBtn>
                        </div>
                        <div className="col-12 col-sm-4 col-lg-6">
                            <h5 className="text-center display-6 fs-3 mb-0">Pigger Beta</h5>
                        </div>
                    </div>
                </MDBContainer>
                {(() => {
                    const userStatus = this.props.userInfo.instances.find(i => i.id === 'jizzer-beta').status;
                    switch(userStatus){
                        case 'requested':
                            return <h5 className="text-center display-6 mt-4">Your request is being processed. Please check back later.</h5>
                        case 'denied':
                            return <h5 className="text-center display-6 mt-4">Sorry, your request has been denied. Please wait for the full version.</h5>
                        case 'accepted': case 'live':
                            return (
                                <>
                                    <hr />
                                    {this.props.serverStatus ?
                                    <StaticRouter location={route}>
                                        <AnimatePresence exitBeforeEnter>
                                            <Switch key={route}>
                                                <Route exact path=":status">
                                                    <Status
                                                        status={this.props.serverStatus.status}
                                                        setView={this.props.setView}
                                                        notify={this.props.notify}
                                                    />
                                                </Route>
                                            </Switch>
                                        </AnimatePresence>
                                    </StaticRouter> :
                                    <MDBContainer>
                                        <h5 className="text-center display-6 my-4">Loading Server Information</h5>
                                        <LinearProgress />
                                    </MDBContainer>}
                                </>
                            );
                        default:
                            console.log('oob user status', userStatus);
                        }
                })()}
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { set_server_status })(JizzerBeta);