import React from 'react';
import { motion } from 'framer-motion';
import h from '../../../../../utilities/helpers';
import t from '../../../../../utilities/transitions';
import { connect } from 'react-redux';
import {
    MDBValidation,
    MDBValidationItem,
    MDBInput,
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBListGroup,
    MDBListGroupItem,
    MDBRipple
} from 'mdb-react-ui-kit';
import { parse } from 'node-html-parser';
import BioField from '../../settings/BioField';
import RuleSelectModal from './RuleSelectModal';

class Rules extends React.Component{
    constructor(){
        super();
        this.state = {
            ruleModalShown: false
        }
    }

    /**
     * If user is returning to this tab due to a failed submission, alert user to the error
     */
    componentDidMount(){
        if (this.props.ruleSelected){
            const rule = this.props.rules.find(rule => rule.id === this.props.ruleSelected);
            const ruleLength = parse(rule.html).textContent.length;
            if (!ruleLength) alert('Please enter a rule');
            else if (ruleLength > 5000) alert('Please enter a shorter rule (Max: 5000 chars)');
        }
    }

    /**
     * Force parse if rule selected changes
     * If new rule created, select new rule
     */
    componentDidUpdate(prevProps){
        if (prevProps.ruleSelected !== this.props.ruleSelected) this.props.setForceParse(this.forceParse);
        if (prevProps.rules.length < this.props.rules.length) this.props.selectRule(this.props.rules[this.props.rules.length - 1].id);
    }

    toggleRuleModal = () => this.setState({
        ...this.state,
        ruleModalShown: !this.state.ruleModalShown
    });

    setRuleModal = option => this.setState({
        ...this.state,
        ruleModalShown: option
    });

    render(){
        const ruleSelected = this.props.rules.find(rule => rule.id === this.props.ruleSelected);
        return (
            <motion.div className="fg-1 dashboard-containers pb-4 pt-2" transition={t.transition} initial={this.props.tabEnter} animate={t.normalize} exit={this.props.tabExit}>
                <RuleSelectModal
                    rules={this.props.rules}
                    selectRule={this.props.selectRule}
                    modalShown={this.state.ruleModalShown}
                    setShowModal={this.setRuleModal}
                    ruleSelected={this.props.ruleSelected}
                    toggleShowModal={this.toggleRuleModal}
                />
                <MDBContainer fluid className="px-0 h-100 d-flex flex-column">
                    <p className="m-0 text-center"><em>Your users will follow these rules in addition to those outlined in Pigger's <a href="https://pigger.net/info" className="text-primary links-generic text-decoration-none">Terms of Service</a></em></p>
                    <div className="row mx-0 fg-1 dashboard-containers">
                        <div className="col-4 h-100 rule-select-columns">
                            {this.props.rules.length ? 
                            <motion.div className="h-100" transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out}>
                                <MDBCard className="d-flex flex-column h-100">
                                    <MDBCardHeader><h5 className="mb-0">Added Rules</h5></MDBCardHeader>
                                    <MDBCardBody className="fg-1 overflow-y-auto p-0 dashboard-containers">
                                        <MDBListGroup>
                                            {this.props.rules.map(rule => (
                                                <MDBRipple className="cursor-pointer" onClick={() => this.props.selectRule(rule.id)} key={rule.id}>
                                                    <MDBListGroupItem action active={this.props.ruleSelected === rule.id}>
                                                        <h6>{rule.title ? rule.title : '(No title)'}</h6>
                                                        <p className="m-0">{h.shortString(parse(rule.html).textContent)}</p>
                                                    </MDBListGroupItem>
                                                </MDBRipple>
                                            ))}
                                        </MDBListGroup>
                                    </MDBCardBody>
                                </MDBCard>
                            </motion.div>
                            : <></>}
                        </div>
                        <div className="col-12 col-lg-8 h-100 d-flex flex-column">
                            {this.props.ruleSelected ?
                            <motion.div key={this.props.ruleSelected} transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out}>
                                <div className="d-flex justify-content-end align-items-start rule-select-button-containers my-3">
                                    <MDBBtn 
                                        color="link"
                                        className="text-danger"
                                        onClick={this.props.removeRule}
                                    >
                                        <i className="far fa-trash-alt me-2" />
                                        Remove Rule
                                    </MDBBtn>
                                </div>
                                
                                <MDBValidation className={`h-100 ${this.props.submitted || this.props.tempSubmitted ? 'was-validated' : ''}`}>
                                    <MDBValidationItem className="pb-4" feedback={ruleSelected.error} invalid={true} >
                                        <MDBInput
                                            name={this.props.ruleSelected}
                                            onChange={this.props.ruleChangeHandler}
                                            id={this.props.ruleSelected}
                                            label="Title"
                                            value={ruleSelected.title}
                                            size="lg"
                                            className={!ruleSelected.invalid ? 'mb-0' : 0}
                                        />
                                    </MDBValidationItem>
                                    {this.props.dashboardItemSelected === 'instances' ?
                                    <BioField  
                                        setForceParse={f => this.forceParse = f} 
                                        working={this.props.submitting} 
                                        userInfo={{
                                            bio: ruleSelected.html
                                        }}
                                        label="Rule"
                                    /> : <></>}
                                </MDBValidation>
                            </motion.div> : <></>}
                            <div className="mt-4 d-flex justify-content-end align-items-start rule-select-button-containers">
                                <MDBBtn 
                                    className="bg-indigo rule-select-buttons d-none me-2"
                                    onClick={this.toggleRuleModal}
                                >
                                    Select Rule
                                </MDBBtn>
                                <MDBBtn 
                                    className="me-2"
                                    color={this.props.ruleSelected ? 'success' : 'primary'}
                                    onClick={this.props.ruleSelected ? this.props.saveRule : this.props.addRule}
                                >
                                    <i className={this.props.ruleSelected ? 'fas fa-save me-2' : 'fas fa-plus me-2'} />
                                    {this.props.ruleSelected ? 'Save' : 'Add'} Rule
                                </MDBBtn>
                                <MDBBtn onClick={this.props.next} color="info">Custom CSS<i className="fas fa-chevron-right ms-2" /></MDBBtn>
                            </div>
                        </div>
                    </div>
                </MDBContainer>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(Rules);