import React from 'react';
import {
    MDBValidation,
    MDBValidationItem,
    MDBInput
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import axios from 'axios';
import { login_schema } from '../../utilities/validations';
import { set_user } from '../../redux/actions';
import h from '../../utilities/helpers';

class Login extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean - Whether a new account is in the process of being submitted
             * inputs: Array - The input data (values, errors, etc)
             * reset: Boolean - When flipped, fixes an mdb ui bug
             */
            working: false,
            inputs: [
                {
                    id: 'username',
                    error: '',
                    invalid: true,
                    value: ''
                },
                {
                    id: 'password',
                    error: '',
                    invalid: true,
                    value: ''
                }
            ],
            reset: false
        }
        this.submit = this.submit.bind(this); // Allows the submit method to be called from the parent
    }

    /**
     * Fix mdb inputs
     * Set the submit() method in the parent
     * Run blank changeHandler
     */
    componentDidMount(){
        setTimeout(this.fixMDBInputs, 250);
        this.props.setSubmit(this.submit);
        this.changeHandler({
            target: {
                name: ''
            }
        });
    }

    /**
     * When logged in, hide modal
     * When modal spawns, fix MDB inputs
     */
    componentDidUpdate(prevProps){
        if (prevProps.userInfo.username !== this.props.userInfo.username && this.props.modalShown) this.props.toggleShowModal();
        if (prevProps.modalShown !== this.props.modalShown) this.fixMDBInputs();
    }
    
    componentWillUnmount(){
        this.props.setWorking(false);
    }

    /**
     * Executes a captcha challenge and generates a key a key
     * Will hang until connected to captcha servers
     */
    getRecaptcha = () => new Promise(async (resolve, reject) => {
        if (this.props.captchaReady) window.grecaptcha.enterprise.execute(process.env.REACT_APP_CAPTCHA_KEY, {action: 'login'}).then(resolve).catch(err => {
            console.log(err);
            alert('Human verification failed. Refresh the page and try again.');
            reject();
        });
        else setTimeout(async () => {
            const captchaKey = await this.getRecaptcha();
            resolve(captchaKey);
        }, 500);
    });

    /**
     * Fixes MDB ui bug in which labels are not properly floating or are crossed out
     */
    fixMDBInputs = () => {
        [].slice.call(document.getElementsByClassName('comment-inputs')).forEach(e => {
            if (!e.value && e.classList.contains('active')) e.classList.remove('active');
            else if (e.value && !e.classList.contains('active')) e.classList.add('active');
        });
        this.setState({
            ...this.state,
            reset: !this.state.reset
        }, () => h.floatLabels());
    }

    /**
     * Submit only if there isn't already a submission being sent
     * Set working
     * Validate inputs
     * Make request to server
     * Set user to user account that the server responds with
     * If unsuccessful and error 401, alert invalid username or password
     * If unsuccessful and error 403, alert user is locked out
     * If unsuccessful and not error 401 or 403, general error alert
     */
    submit = () => {
        document.getElementById('login').classList.add('was-validated');
        let invalidInputs = this.state.inputs.filter(input => input.invalid);
        invalidInputs.forEach(input => document.getElementById(input.id).setCustomValidity('hello'));
        if (!this.state.working && !invalidInputs.length) this.setState({
            ...this.state,
            working:true
        }, async () => {
            this.props.setWorking(true);
            const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value.trim()]));
            try {
                login_schema.validateSync(data, {
                    abortEarly: false
                });
                const captchaKey = await this.getRecaptcha();
                data.captchaKey = captchaKey;
                axios.post('/auth/login', data).then(res => this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    this.props.setWorking(false);
                    document.getElementById('login').classList.remove('was-validated');
                    document.getElementById('login').reset();
                    this.props.set_user(res.data.userInfo, res.data.profileInfo);
                    this.props.notify(<i className="fas fa-user me-2 text-success"></i>, `Logged in as ${res.data.userInfo.username}`);
                })).catch(err => this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    this.props.setWorking(false);
                    console.log(err);
                    if (err.response){
                        switch(err.response.status){
                            case 401:
                                alert('Invalid username or password');
                                break;
                            case 403:
                                alert(err.response.data.message);
                                break;
                            default:
                                alert('An error occurred. Please try again later');
                        }
                    }
                    else alert('An error occurred. Please try again later');
                }));
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    this.props.setWorking(false);
                    console.log(err);
                    alert('An error occurred. Please try again later');
                });
            }
        });
    }

    /**
     * 
     * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
     * 
     * Sets the updated values into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => this.setState({
        ...this.state,
        inputs: this.state.inputs.map(input => {
            if (input.id === e.target.name) return {
                ...input,
                value: e.target.value
            }
            else return input
        })
    }, () => {
        const data = Object.fromEntries(this.state.inputs.map(input => [input.id, input.value.trim()]));
        try {
            login_schema.validateSync(data, {
                abortEarly: false
            });
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    document.getElementById(input.id).setCustomValidity('');
                    return {
                        ...input,
                        invalid: false,
                        error: ''
                    }
                })
            });
        } catch(err){
            let errorsAdded = [];
            this.setState({
                ...this.state,
                inputs: this.state.inputs.map(input => {
                    if (err.inner.find(error => error.path === input.id) && errorsAdded.indexOf(input.id) === -1){
                        errorsAdded.push(input.id);
                        return {
                            ...input,
                            invalid: true,
                            error: err.inner.find(error => error.path === input.id).message
                        }
                    } 
                    else return {
                        ...input,
                        invalid: false,
                        error: ''
                    };
                })
            }, () => this.state.inputs.forEach(input => {
                if (input.invalid) document.getElementById(input.id).setCustomValidity('hello');
                else document.getElementById(input.id).setCustomValidity('');
            }));
        }
    });

    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    pressEnter = e => {
        if (e.key === 'Enter') this.submit();
    }

    /**
     * 
     * @param {Event} e - Keypress event
     * 
     * Triggered when the user presses the Tab key
     * Moves cursor to next input (MDB is bugged)
     * Removed when MDB fixes
     */
    pressTab = e => {
        if (e.key === 'Tab'){
            e.preventDefault();
            const input = this.state.inputs.find(f => f.id === e.target.id);
            if (input){
                const nextField = this.state.inputs[this.state.inputs.indexOf(input) + 1];
                if (nextField){
                    const element = document.getElementById(nextField.id);
                    if (element){
                        setTimeout(() => {
                            element.focus();
                            element.select();
                        }, 100);
                    }
                }
            }
        }
    }

    render(){
        return (
            <MDBValidation style={{width: '500px'}} className="max-w-95 mx-auto" name="login" method="dialog" id="login" onSubmit={this.submit}>
                <MDBValidationItem className="pb-4" feedback={this.state.inputs.find(input => input.id === 'username').error} invalid={true} >
                    <MDBInput
                        name='username'
                        onChange={this.changeHandler}
                        id='username'
                        label='Username'
                        size="lg"
                        className={!this.state.inputs.find(input => input.id === 'username').invalid ? 'mb-0' : 0}
                        onKeyPress={this.pressEnter}
                        reset={this.state.reset}
                        onKeyDown={this.pressTab}
                    />
                </MDBValidationItem>
                <MDBValidationItem className="pb-4" feedback={this.state.inputs.find(input => input.id === 'password').error} invalid={true} >
                    <MDBInput
                        name='password'
                        onChange={this.changeHandler}
                        id='password'
                        label='Password'
                        size="lg"
                        type='password'
                        className={!this.state.inputs.find(input => input.id === 'password').invalid ? 'mb-0' : 0}
                        onKeyPress={this.pressEnter}
                        reset={this.state.reset}
                        onKeyDown={this.pressTab}
                    />
                </MDBValidationItem>
            </MDBValidation>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { set_user })(Login);