import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../utilities/transitions';
import { route } from '../redux/actions';
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBCarousel,
    MDBCarouselItem
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

class Home extends React.Component{
    constructor(){
        super();
        this.state = {

        }
    }

    render(){
        return (
            <motion.div className="flex-grow-1 h-0 position-relative" transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out}>
                <MDBContainer id="home-container" fluid className="py-5 h-100 px-5">
                    <div className="row h-75 align-items-center">
                        <div className="col-12 col-lg-6 mt-4">
                            <div>
                                <h5 className="display-6">Your Social Media Empire Starts Here</h5>
                                <h5 className="mt-4">Launch a fully functioning social media app without writing a single line of code.</h5>
                                <p className="mt-2">Simply register a website, point it at our servers, and we'll handle the rest.</p>
                            </div>
                            <MDBBtn
                                size="lg"
                                color="primary"
                                className="mt-4 ms-auto"
                                onClick={() => this.props.route('/products')}
                            >
                                Get Started
                                <i className="fas fa-chevron-right ms-2" />
                            </MDBBtn>
                        </div>
                        <div className="col-12 col-lg-6 mt-4">
                            <MDBCarousel showIndicators>
                                <MDBCarouselItem
                                    className='w-100 d-block'
                                    itemId={1}
                                    src='/assets/images/step1.png'
                                    alt='Step 1'
                                    captionClassName="mask bg-mask-regular"
                                >
                                    <h5>Step 1</h5>
                                    <p>Register your domain, then point it at our servers.</p>
                                </MDBCarouselItem>

                                <MDBCarouselItem
                                    className='w-100 d-block'
                                    itemId={2}
                                    src='/assets/images/step2.png'
                                    alt='Step 2'
                                    captionClassName="mask bg-mask-regular"
                                >
                                    <h5>Step 2</h5>
                                    <p>Upload your logos, change the verbiage, choose your colors, or leave everything default.</p>
                                </MDBCarouselItem>

                                <MDBCarouselItem
                                    className='w-100 d-block'
                                    itemId={3}
                                    src='/assets/images/step3.png'
                                    alt='Step 3'
                                    captionClassName="mask bg-mask-regular"
                                >
                                    <h5>Step 3</h5>
                                    <p>Run your instance however you want.</p>
                                </MDBCarouselItem>
                            </MDBCarousel>
                        </div>
                    </div>
                </MDBContainer>
            </motion.div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route })(Home);