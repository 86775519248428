import React from 'react';
import { connect } from 'react-redux';
import {
    MDBContainer,
    MDBToast,
    MDBBtn
} from 'mdb-react-ui-kit';
import { StaticRouter, Switch, Route, Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import InstanceView from './instances_child/InstanceView';
import LinearProgress from '@mui/material/LinearProgress';
import { route } from '../../../redux/actions';
import { motion } from 'framer-motion';
import t from '../../../utilities/transitions';

class Instances_Child extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * view: String - The current view
             * notificationIcon: JSX - The notification icon
             * notificationText: String - The notification text
             */
            view: props.instanceSelected ? props.instanceSelected : 'list',
            notificationIcon: <></>,
            notificationText: ''
        }

        /**
         * this.toastRef - When this.toastRef.current is clicked, a notification is shown
         * this.containerRef - Notifications are placed inside this container
         */
        this.toastRef = React.createRef(null);
        this.containerRef = React.createRef(null);
    }

    /**
     * 
     * @param {String} view - A view or page
     * 
     * Sets the view
     */
    setView = view => this.setState({
        ...this.state,
        view: view
    });

    /**
     * 
     * @param {Click Event} e 
     * @param {String} path - href/URL
     * 
     * Triggered when the user clicks a link
     * Override default behavior and use redux props.route method
     */
    route = (e, route) => {
        e.preventDefault();
        this.props.route(route);
    }

    /**
     * 
     * @param {JSX} icon - Notification icon
     * @param {String} text - Notification text
     * 
     * Clicks the toastRef to notify the user with the text and icon supplied
     */
    notify = (icon, text) => this.setState({
        ...this.state,
        notificationIcon: icon,
        notificationText: text
    }, () => this.toastRef.current.click());

    render(){
        return (
            <div id="toast-trigger-instances-child-container" ref={this.containerRef} className="h-100 d-flex flex-column">
                <MDBBtn id="toast-trigger-instances-child" className="d-none" color="primary" ref={this.toastRef}>Toast</MDBBtn>
                <MDBToast
                    color="light"
                    autohide
                    position='bottom-left'
                    delay={2000}
                    triggerRef={this.toastRef}
                    containerRef={this.containerRef}
                    headerContent={<div className="me-auto"></div>}
                    bodyContent={
                        <h5>{this.state.notificationIcon}{this.state.notificationText}</h5>
                    }
                    className="w-max-content max-w-100"
                    id="toast-instances-child"
                />
                {this.props.statistics ?
                <MDBContainer fluid className="fg-1 px-0 dashboard-containers">
                    {this.props.userInfo.instances.length ?
                    <motion.div transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="row h-100 overflow-y-auto overflow-x-hidden mx-0 dashboard-containers">
                        <StaticRouter location={this.state.view}>
                            <AnimatePresence exitBeforeEnter>
                                <Switch key={this.state.view}>
                                    <Route exact path=":view">
                                        <InstanceView
                                            view={this.state.view}
                                            setView={this.setView}
                                            loaded={this.props.statistics}
                                            notify={this.notify}
                                        />
                                    </Route>
                                </Switch>
                            </AnimatePresence>
                        </StaticRouter>
                    </motion.div> : <motion.h5 transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={t.fade_out} className="text-center display-6 mt-5">You have no instances. <Link onClick={e => this.route(e, '/products')} to="/products" className="links-generic">Visit the Products page to get started.</Link></motion.h5>}
                </MDBContainer> :
                <MDBContainer className="mt-5">
                    <LinearProgress />
                </MDBContainer>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, { route })(Instances_Child);