import React from 'react';
import List from './List';
import RequestAccessJizzerBeta from './RequestAccessJizzerBeta';

class ProductView extends React.Component{
    constructor(props){
        super();
        this.page = props.page
    }

    render(){
        switch(this.page){
            case 'list':
                return (
                    <List
                        changePage={this.props.changePage}
                        productHistoryStack={this.props.productHistoryStack}
                        toggleLoginModal={this.props.toggleLoginModal}
                    />
                );
            case 'request-access-jizzer-beta':
                return (
                    <RequestAccessJizzerBeta
                        changePage={this.props.changePage}
                        productHistoryStack={this.props.productHistoryStack}
                    />
                );
            default:
                console.log('oob product page', this.page);
                return <></>
        }
    }
}

export default ProductView;