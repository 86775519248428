import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import t from '../utilities/transitions';
import { StaticRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { route } from '../redux/actions';
import ProductView from './products/ProductView';
import LoginModal from '../components/LoginModal';
import ToastMisc from '../components/notifications/ToastMisc';
import {
    MDBBtn
} from 'mdb-react-ui-kit';

class Products extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * page: String - Page/view that the user is currently on
             * productHistoryStack: Array - Product page view history
             * notificationText: String - Notification text
             * notificationIcon: JSX - Notification icon
             * loginModalShown: Boolean - Whether the login modal is shown
             * exit: Object - framer-motion initial/exit animation
             */
            page: 'list',
            productHistoryStack: [],
            notificationText: '',
            notificationIcon: <></>,
            loginModalShown: false,
            exit: t.fade_out
        }
        /**
         * When this.toastRef.current is clicked, a notification will be shown
         */
        this.toastRef = React.createRef(null);
    }

    /**
     * When user logs in, if there is a temp action pending, execute the temp action
     * If user logs out, return to the product list
     * Set proper exit transition and route to the dashboard page when a dashboard item is selected
     */
    componentDidUpdate(prevProps){
        if (!prevProps.userInfo._id && this.props.userInfo._id && this.props.tempAction && ['request-access'].indexOf(this.props.tempAction.action) > -1) this.triggerTempAction();
        if (prevProps.userInfo._id && !this.props.userInfo._id) this.changePage('list');
        if (prevProps.dashboardItemSelected !== 'instances' && this.props.dashboardItemSelected === 'instances') this.setState({
            ...this.state,
            exit: t.fade_out_left
        }, () => this.props.route('/dashboard'));
    }

    /**
     * Triggered when the user logs in with a pending temp action
     * Executes the temp action
     */
    triggerTempAction = () => {
        switch(this.props.tempAction.action){
            case 'request-access':
                switch(this.props.tempAction.product){
                    case 'jizzer-beta':
                        this.changePage('request-access-jizzer-beta');
                        break;
                    default:
                        console.log('oob temp action product', this.props.tempAction.product);
                }
            default:
                console.log('oob temp action action', this.props.tempAction.label);
        }
    }

    /**
     * 
     * @param {String} page - Page to be navigated to
     * 
     * Navigates to a new page
     */
    changePage = page => this.setState({
        ...this.state,
        page: page,
        productHistoryStack: [
            ...this.state.productHistoryStack,          
            this.state.page
        ]
    });

    /**
     * 
     * @param {JSX} icon - Notification icon
     * @param {String} text - Notification text
     * 
     * Clicks the toastRef to notify the user with the text and icon supplied
     */
    notify = (icon, text) => this.setState({
        ...this.state,
        notificationText: text,
        notificationIcon: icon
    }, () => this.toastRef.current.click());

    toggleLoginModal = () => this.setState({
        ...this.state,
        loginModalShown: !this.state.loginModalShown
    });

    setLoginModal = option => this.setState({
        ...this.state,
        loginModalShown: option
    });

    render(){
        return (
            <motion.div transition={t.transition} initial={t.fade_out} animate={t.normalize} exit={this.state.exit}>
                <LoginModal 
                    notify={this.notify}
                    modalShown={this.state.loginModalShown}
                    setShowModal={this.setLoginModal}
                    toggleShowModal={this.toggleLoginModal}
                />
                <MDBBtn id="toast-trigger-login" className="d-none" color="primary" ref={this.toastRef}>Toast</MDBBtn>
                <ToastMisc triggerRef={this.toastRef} id={"login-toast"} icon={this.state.notificationIcon} text={this.state.notificationText} />
                <StaticRouter location={this.state.page}>
                    <AnimatePresence exitBeforeEnter>
                        <Switch key={this.state.page}>
                            <Route exact path=":page">
                                <ProductView
                                    page={this.state.page} 
                                    changePage={this.changePage}
                                    productHistoryStack={this.state.productHistoryStack}
                                    toggleLoginModal={this.toggleLoginModal}
                                />
                            </Route>
                        </Switch>
                    </AnimatePresence>
                </StaticRouter>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, { route })(Products);