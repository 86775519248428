import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
} from 'mdb-react-ui-kit';
import axios from 'axios';
import h from '../../../../utilities/helpers';

class MessageModal extends React.Component{
    constructor(){
        super();
        this.state = {
        }
    }

    render(){
        const message = this.props.message;
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="xl">
                        {message ?
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>{this.props.category}</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="mb-1"><span className="fw-bold">Name:</span> {message.name}</p>
                                        <p className="mb-1"><span className="fw-bold">Email:</span> {message.email}</p>
                                        <p className="mb-1"><span className="fw-bold">Subject:</span> {message.subject}</p>
                                    </div>
                                    <div>
                                        <p className="m-0 text-blusteel text-end">{h.makeDateHR(message.timestamp)}</p>
                                        <p className="m-0 text-blusteel text-end">{h.getTimeHR(message.timestamp)}</p>
                                    </div>
                                </div>
                                <hr />
                                <h5>Message:</h5>
                                <p>{message.feedback}</p>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent> : <></>}
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

  
export default MessageModal;