import React from 'react';
import { motion } from 'framer-motion';
import h from '../../../../../utilities/helpers';
import t from '../../../../../utilities/transitions';
import { connect } from 'react-redux';
import {
    MDBValidation,
    MDBValidationItem,
    MDBBtn,
    MDBInput,
    MDBTextArea,
    MDBContainer,
    MDBInputGroup,
    MDBRipple
} from 'mdb-react-ui-kit';
import Spinner from '../../../../../components/Spinner';
import DNSModal from './DNSModal';

class NameIcons extends React.Component{
    constructor(props){
        super();
        this.state = {
            /**
             * domain: Object - Details of the Domain input 
             * updating: Boolean - Whether the domain is in the process of being updating
             * dnsModalShown: Boolean - Whether the DNS modal is displayed
             */
            domain: {
                error: '',
                invalid: false,
                value: props.serverStatus.domain
            },
            updating: false,
            dnsModalShown: false
        }
    }

    // Run blank change handler
    componentDidMount(){
        this.props.changeHandler({
            target: {
                value: ''
            }
        });
    }

    // Once domain is updated, remove loading spinners
    componentDidUpdate(prevProps){
        if (prevProps.serverStatus.domain !== this.props.serverStatus.domain) this.setState({
            ...this.state,
            updating: false
        });
    }

    toggleDNSModal = () => this.setState({
        ...this.state,
        dnsModalShown: !this.state.dnsModalShown
    });

    setDNSModal = option => this.setState({
        ...this.state,
        dnsModalShown: option
    });

    /**
     * Sets domain input value into state
     * Validates the inputs
     * Updates the inputs with errors
     * Adds/removes custom validity as appropriate
     */
    changeHandler = e => {
        if (!this.state.updating) this.setState({
            ...this.state,
            domain: {
                ...this.state.domain,
                value: e.target.value
            }
        }, () => {
            if (h.checkURL(e.target.value)) this.setState({
                ...this.state,
                domain: {
                    ...this.state.domain,
                    invalid: false,
                    error: ''
                }
            }, () => document.getElementById('input-domain').setCustomValidity(''));
            else this.setState({
                ...this.state,
                domain: {
                    ...this.state.domain,
                    invalid: true,
                    error: 'Please enter a valid domain'
                }
            }, () => document.getElementById('input-domain').setCustomValidity('Please enter a valid domain'));
        });
    }

    /**
     * Triggered when the user clicks the Update button next to the domain field
     * Updates the domain
     */
    updateDomain = () => {
        document.getElementById('form_domain').classList.add('was-validated');
        if (!this.state.updating && !this.state.domain.invalid) this.setState({
            ...this.state,
            updating: true
        }, () => this.props.socket.emit('jizzer-beta-domain', this.state.domain.value));
    }

    render(){
        return (
            <motion.div className="mt-4" transition={t.transition} initial={this.props.tabEnter} animate={t.normalize} exit={this.props.tabExit}>
                <DNSModal modalShown={this.state.dnsModalShown} setShowModal={this.setDNSModal} toggleShowModal={this.toggleDNSModal} />
                <div className="d-flex justify-content-between align-items-center">
                    {this.props.serverStatus.status === 'validating-domain' ?
                    <div>
                        <h6 className="mb-1">Your domain</h6>
                        <div className="d-flex align-items-center">
                            <MDBValidation onSubmit={this.updateDomain} className="mx-0" id="form_domain" name="form_domain" method="dialog">
                                <MDBValidationItem feedback={this.state.domain.error} invalid={true}>
                                    <MDBInputGroup>
                                        <input
                                            type="text"
                                            placeholder="example.com"
                                            onChange={this.changeHandler}
                                            id="input-domain"
                                            className="form-control"
                                            value={this.state.domain.value}
                                        />
                                    </MDBInputGroup>
                                </MDBValidationItem>
                            </MDBValidation>
                            <MDBBtn 
                                color="success"
                                onClick={this.updateDomain}
                                disabled={this.state.updating}
                                className="ms-2"
                            >
                                {this.state.updating ?
                                <>
                                    <Spinner className="me-2" size="sm" />
                                    Updating
                                </> :
                                <>
                                    <i className="fas fa-paper-plane me-2" />
                                    Update
                                </>
                                }
                            </MDBBtn>
                        </div>
                    </div> :
                    <div 
                        style={{
                            width: '300px',
                            maxWidth: '95%'
                        }}
                    >
                        <MDBInput 
                            disabled
                            label="Your Domain"
                            value={this.props.serverStatus.domain}
                            
                        />
                    </div>}
                    <MDBBtn
                        color="link"
                        onClick={this.toggleDNSModal}
                        rippleColor="primary"
                    >
                        <i className="fas fa-clipboard me-2" />
                        DNS Records
                    </MDBBtn>
                </div>
                <hr />
                <MDBContainer className="mt-4 px-0" fluid>
                    <div className="row mx-0">
                        <MDBValidation id="form" className={`col-12 col-xl-8 row mx-0 ${this.props.submitted || this.props.tempSubmitted ? 'was-validated' : ''}`}>
                            <MDBValidationItem className="col-12 col-lg-6 pb-4" feedback={this.props.inputs.find(input => input.id === 'app_name').error} invalid={true} >
                                <MDBInput
                                    name="app_name"
                                    onChange={this.props.changeHandler}
                                    id="app_name"
                                    label="App Name"
                                    size="lg"
                                    className={!this.props.inputs.find(input => input.id === "app_name").invalid ? 'mb-0' : 0}
                                    onKeyDown={this.props.pressTab}
                                    value={this.props.inputs.find(input => input.id === "app_name").value}
                                />
                            </MDBValidationItem>
                            <div className="col-12 col-lg-6 d-flex flex-column justify-content-between">
                                <MDBValidationItem className="pb-4" feedback={this.props.inputs.find(input => input.id === 'description').error} invalid={true} >
                                    <MDBTextArea
                                        name="description"
                                        onChange={this.props.changeHandler}
                                        id="description"
                                        label="App Description"
                                        size="lg"
                                        className={!this.props.inputs.find(input => input.id === "description").invalid ? 'mb-0' : 0}
                                        onKeyDown={this.props.pressTab}
                                        value={this.props.inputs.find(input => input.id === "description").value}
                                    />
                                </MDBValidationItem>
                                <div>
                                    <p style={{fontSize: '1.2rem'}} className="text-center display-6 mb-1">New {this.props.inputs.find(input => input.id === 'emission_name').value} Icon</p>
                                    <MDBBtn
                                        color="primary"
                                        size="lg"
                                        className="d-block mx-auto bg-darkblu p-2"
                                        onClick={() => this.props.selectFile('newJizzIcon')}
                                        floating
                                        style={{
                                            width: '4rem',
                                            height: '4rem'
                                        }}
                                        type="button"
                                    >
                                        <div 
                                            style={{
                                                backgroundImage: `url("${this.props.files.newJizzIcon}")`
                                            }} 
                                            className="fit-images">
                                        </div>
                                    </MDBBtn>
                                </div>
                            </div>
                            
                        </MDBValidation>
                        <div className="col-12 col-xl-4 name-icons-users-container">
                            <div className="d-flex justify-content-end align-items-center name-icons-users">
                                <h5 className="me-2">{this.props.inputs.find(i => i.id === 'admin_name').value} Icon:</h5>
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex justify-content-center align-items-center square-4 mx-auto">
                                        <MDBRipple onClick={() => this.props.selectFile('chadminBadge')} tag="div" rippleColor="primary" className="fit-images" style={{backgroundImage: `url("${this.props.files.chadminBadge}")`, cursor: 'pointer'}}></MDBRipple>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center mt-2 name-icons-users">
                                <h5 className="me-2">{this.props.inputs.find(i => i.id === 'mod_name').value} Icon:</h5>
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex justify-content-center align-items-center square-4 mx-auto">
                                        <MDBRipple onClick={() => this.props.selectFile('jannyBadge')} tag="div" rippleColor="primary" className="fit-images" style={{backgroundImage: `url("${this.props.files.jannyBadge}")`, cursor: 'pointer'}}></MDBRipple>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center mt-2 name-icons-users">
                                <h5 className="me-2">Verified Icon:</h5>
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex justify-content-center align-items-center square-4 mx-auto">
                                        <MDBRipple onClick={() => this.props.selectFile('verifiedBadge')} tag="div" rippleColor="primary" className="fit-images" style={{backgroundImage: `url("${this.props.files.verifiedBadge}")`, cursor: 'pointer'}}></MDBRipple>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12"><hr /></div>
                        <div className="col-12 col-lg-3 d-flex flex-column">
                            <p style={{fontSize: '1.5rem'}} className="text-center display-6 mb-1">App Logo</p>
                            <div className="d-flex justify-content-center align-items-center fg-1 dashboard-containers">
                                <div className="d-flex justify-content-center align-items-center square-10 mx-auto">
                                    <MDBRipple onClick={() => this.props.selectFile('icon')} tag="div" rippleColor="primary" className="fit-images" style={{backgroundImage: `url("${this.props.files.icon}")`, cursor: 'pointer'}}></MDBRipple>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 d-flex flex-column">
                            <p style={{fontSize: '1.5rem'}} className="text-center display-6 mb-1">Default Avatar</p>
                            <div className="d-flex justify-content-center align-items-center fg-1 dashboard-containers">
                                <div className="d-flex justify-content-center align-items-center square-10 mx-auto">
                                    <MDBRipple onClick={() => this.props.selectFile('defaultAvatar')} tag="div" rippleColor="primary" className="fit-images" style={{backgroundImage: `url("${this.props.files.defaultAvatar}")`, cursor: 'pointer'}}></MDBRipple>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <p style={{fontSize: '1.5rem'}} className="text-center display-6 mb-1">Default Background</p>
                            <div style={{height: '200px'}} className="d-flex justify-content-center align-items-center w-100 mx-auto">
                                <MDBRipple onClick={() => this.props.selectFile('defaultBackground')} tag="div" rippleColor="light" className="fit-background cursor-pointer" style={{backgroundImage: `url("${this.props.files.defaultBackground}")`}}></MDBRipple>
                            </div>
                        </div>
                        
                    </div>
                </MDBContainer>
                <MDBBtn className="d-block ms-auto mt-4" color="primary" onClick={this.props.next}>Verbiage<i className="fas fa-chevron-right ms-2"></i></MDBBtn>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(NameIcons);