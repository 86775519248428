import React from 'react';
import { connect } from 'react-redux';
import h from '../../../../../utilities/helpers';
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardFooter,
    MDBBtn
} from 'mdb-react-ui-kit';

class JizzerBetaCard extends React.Component{
    constructor(){
        super();
    }

    render(){
        const requests = this.props.userList.filter(u => u.instances.find(i => i.id === 'jizzer-beta' && i.status === 'requested')).length;
        const active = this.props.userList.filter(u => u.instances.find(i => i.id === 'jizzer-beta' && ['live'].indexOf(i.status) > -1)).length;
        const disabled = this.props.userList.filter(u => u.instances.find(i => i.id === 'jizzer-beta' && ['disabled'].indexOf(i.status) > -1)).length;
        return (
            <MDBCard>
                <MDBCardHeader>
                    <h5 className="m-0">Jizzer - Beta</h5>
                </MDBCardHeader>
                <MDBCardBody className="d-flex align-items-start">
                    <div className="h-max-content">
                        <div className="square-6">
                            <div className="fit-images" style={{backgroundImage: 'url("/assets/images/jizzer-logo.png")'}}></div>
                        </div>
                    </div>
                    <div className="fg-1">
                        <p className="m-0 text-end">{requests} <span className="text-blusteel">Request{requests === 1 ? '' : 's'}</span></p>
                        <p className="m-0 text-end">{active} <span className="text-blusteel">Active</span></p>
                        <p className="m-0 text-end">{disabled} <span className="text-blusteel">Disabled</span></p>
                    </div>
                </MDBCardBody>
                <MDBCardFooter className="d-flex justify-content-end">
                    <MDBBtn onClick={() => this.props.setView('jizzer-beta')} color="link" rippleColor="primary">
                        Manage
                        <i className="fas fa-chevron-right ms-2"></i>
                    </MDBBtn>
                </MDBCardFooter>
            </MDBCard>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, {})(JizzerBetaCard);