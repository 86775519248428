import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import h from '../../../../../utilities/helpers';
import SampleProfile from '../../../../../components/SampleProfile';


class DetailModal extends React.Component{
    constructor(){
        super();
        this.state = {
        }
    }

    render(){
        const instance = this.props.instance;
        return (
            <>
                {typeof window !== 'undefined' && window.navigator ?
                <MDBModal show={this.props.modalShown} setShow={this.props.setShowModal} tabIndex='-1'>
                    <MDBModalDialog size="xl">
                        {instance ?
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle className="d-flex align-items-center">
                                    <div className="square-2 me-2">
                                        <div className="fit-images" style={{backgroundImage: `url("${process.env.REACT_APP_JIZZER_BUCKET_HOST}/${instance.uuid}/icons/favicon-96x96.png")`}}></div>
                                    </div>
                                    {instance.preferences.app_name}
                                </MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                {instance.status === 'disabled' ?
                                <>
                                    <h5><a className="links-generic" href={'https://' + instance.server.domain}>{instance.server.domain}</a> Disabled {h.makeDateHR(new Date(instance.actions.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).find(a => a.action === 'disable').timestamp))} at {h.getTimeHR(new Date(instance.actions.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).find(a => a.action === 'disable').timestamp))}</h5>
                                    <p>{instance.disableText}</p>
                                </> :
                                <>
                                    <h5><a className="links-generic" href={'https://' + instance.server.domain}>{instance.server.domain}</a> Launched {h.makeDateHR(new Date(instance.actions.find(a => a.action === 'launched').timestamp))} at {h.getTimeHR(new Date(instance.actions.find(a => a.action === 'launched').timestamp))}</h5>
                                </>}
                                
                                <SampleProfile profile={instance.user} />
                                <h5 className="mt-4">Briefly Tell us About Yourself</h5>
                                <hr></hr>
                                <p>{instance.requestInfo.about}</p>
                                <h5 className="mb-0 mt-4">What's Your Plan for your Jizzer Instance?</h5>
                                <hr></hr>
                                <p>{instance.requestInfo.plan}</p>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent> : <></>}
                    </MDBModalDialog>
                </MDBModal> : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
  }
  
  export default connect(mapStateToProps, {  })(DetailModal);