import React from 'react';
import {
    MDBRipple
} from 'mdb-react-ui-kit';

import { Link } from 'react-router-dom';

/**
 * 
 * @param {Function} nav - The navigation function
 * @returns The main logo on mobile devices
 */
const MobileLogo = ({ nav }) => (
    <div className="col-6">
        <Link onClick={e => nav(e, '/')} to="/">
            <MDBRipple id="logo-mobile" className="d-block mt-2 w-max-content p-1 rounded-4" rippleColor='#1976d2'>
                <img className="cursor-pointer h-100" src="/assets/images/logo-wide.svg"/>
            </MDBRipple>
        </Link>
        <span className="nav-mobile mb-2"></span>
    </div>
)

export default MobileLogo;